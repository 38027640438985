import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../store/hooks'
import { withRouter } from 'react-router'
import * as pdfjsLib from 'pdfjs-dist/es5/build/pdf'
import pdfjsWorker from 'pdfjs-dist/es5/build/pdf.worker.entry'

import {
	ActionList,
	Button,
	ButtonType,
	Alert,
	Form,
	FormContainer,
	FormField,
	CalendarInput,
	ButtonState,
	ImageUpload,
	// DropdownItemProps,
	Spacer,
	Text,
	TextType,
	TemplateModal,
	TemplateModalSize,
	TemplateModalAlignment,
	ButtonBar
} from '@mit/hui'
import vaccineController, {
	VaccineCompanyInterface,
	VaccineProofShot,
	// VaccineReviewReasons
} from '../../api/controller/VaccineController'
import { ActiveDrawer } from '../../store/models/global'
import moment from 'moment'
import { COVID_TEXTS } from '../../common/Texts'
import Resizer from 'react-image-file-resizer'
import { ImageCrop } from '../../common/ImageCrop'

interface VaccineProofEditProps {}

interface VaccineProof {
	type: number
	company: string
	proof_guid?: string
	shots: VaccineProofShot[]
}

const VaccineProofEditComponent: FC<VaccineProofEditProps> = () => {
	const [isLoading, setLoading] = useState<boolean>(false)
	const [isUploading, setUploading] = useState<boolean>(false)
	const [isBusy, setBusy] = useState<boolean>(false)
	const [isDeleting, setDelete] = useState<boolean>(false)
	const [confirm, setConfirm] = useState<boolean>(false)
	const [showCropModal, setShowCropModal] = useState<boolean>(false)
	const [showPreview, setShowPreview] = useState<boolean>(false)
	const [fileChanged, setFileChanged] = useState<boolean>(false)
	const [hasImage, setHasImage] = useState<boolean>(false)
	const maxDate = {
		year: parseInt(moment().format('YYYY')),
		month: parseInt(moment().format('M')),
		day: parseInt(moment().format('D'))
	}
	// const [maxDate, setMaxDate] = useState({
	// 	year: parseInt(moment().format('YYYY')),
	// 	month: parseInt(moment().format('M')),
	// 	day: parseInt(moment().format('D'))
	// })
	// const [minDate, setMinDate] = useState({
	// 	year: parseInt(moment('1600').format('YYYY')),
	// 	month: parseInt(moment('01').format('M')),
	// 	day: parseInt(moment('01').format('D'))
	// })
	const [error, setError] = useState<string[]>([])
	// const [typesDropdown, setTypesDropDown] = useState<DropdownItemProps[]>([])
	// const [productDropdown, setProductDropDown] = useState<DropdownItemProps[]>([])
	// const [doseDropdown, setDoseDropDown] = useState<DropdownItemProps[]>([])
	// const [selectedProduct, setSelectedProduct] = useState<VaccineCompanyInterface>()

	const {
		setActiveDrawer,
		setCurrentVaccineProof,
		setVaccineProofList,
		setReloadVaccineData,
		setVaccineReceived
	} = useStoreActions(store => store.globalModel)
	const {
		appText,
		// vaccineTypes,
		vaccineCompanies,
		currentVaccineProof,
		// vaccineProofList,
		reason,
		manufacturer,
		vaccineReceived,
		mobile,
		// vaccineStatus,
		vaccineShots
	} = useStoreState(store => store.globalModel)

	// const [data, setData] = useState<VaccineProofShot>() //POSSIBLE DEPRECATED STATE
	const [doseOneData, setDoseOneData] = useState<VaccineProofShot>()
	const [doseTwoData, setDoseTwoData] = useState<VaccineProofShot>()
	const [doseBoosterData, setDoseBoosterData] = useState<VaccineProofShot>()
	const [displayData, setDisplayData] = useState<any>({})
	const [file, setFile] = useState<any>()
	const [oneDose, setOneDose] = useState<any>([])
	// const [vaccineShots, setVaccineShots] = useState<any[]>([])

	useEffect(() => {
		setOneDose(
			vaccineCompanies
				?.filter(
					(company: VaccineCompanyInterface) =>
						company.number_of_shots === 1 && company.company_name === manufacturer.company_name
				)
				.map((company: VaccineCompanyInterface) => {
					return company.company_name
				})
		)

		if (!currentVaccineProof) {
			setDoseOneData(undefined)
			setDoseTwoData(undefined)
			setDoseBoosterData(undefined)
			setDisplayData({
				image_url: ''
			})
			return
		}

		if (currentVaccineProof) {
			setLoading(true)
			setReloadVaccineData(false)

			let doseOne: any
			let doseTwo: any
			let doseBooster: any //booster

			if (currentVaccineProof.type === 1) { // Original Shots
				// move contents in here
				if (currentVaccineProof?.shots.length <= 1) {
					if (currentVaccineProof?.shots[0].shot_number === 1) doseOne = currentVaccineProof?.shots[0]
					if (currentVaccineProof?.shots[0].shot_number === 2) doseTwo = currentVaccineProof?.shots[0]
					// if (currentVaccineProof?.shots[0].is_booster) doseBooster = currentVaccineProof?.shots[0]
				} else if (currentVaccineProof?.shots.length <= 2) {
					doseOne = currentVaccineProof?.shots[0]
					doseTwo = currentVaccineProof?.shots[1]
				}
			} else if (currentVaccineProof.type === 2) { // Booster
				doseBooster = currentVaccineProof?.shots[0]
			}

			if (doseOne) {
				setDoseOneData(doseOne)
			}

			if (doseTwo) {
				setDoseTwoData(doseTwo)
			}

			if (doseBooster) {
				setDoseBoosterData(doseBooster)
			}
			// temp
			// console.log('doseTwo.image_url', doseTwo.image_url)
			if (vaccineReceived === 'fully' && doseTwo) {
				if (doseTwo.image_url) {
					checkImage(doseTwo.image_url).then(resp => {
						setDisplayData({
							image_url: resp
						})
						setFile([{ preview: resp }])
						setHasImage(true)
					})
				}
			} else {
				setDisplayData({
					image_url: ''
				})
				setFile([{ preview: '' }])
			}

			if (vaccineReceived === 'partially' && doseOne) {
				checkImage(doseOne.image_url).then(resp => {
					setDisplayData({
						image_url: resp
					})
					setFile([{ preview: resp }])
					setHasImage(true)
				})
			} else {
				setDisplayData({
					image_url: ''
				})
				setFile([{ preview: '' }])
			}

			if (vaccineReceived === 'booster' && doseBooster) {
				checkImage(doseBooster.image_url).then(resp => {
					setDisplayData({
						image_url: resp
					})
					setFile([{ preview: resp }])
					setHasImage(true)
				})
			} else {
				setDisplayData({
					image_url: ''
				})
				setFile([{ preview: '' }])
			}

			setTimeout(() => {
				setLoading(false)
			}, 1000)
		}
	}, [currentVaccineProof, manufacturer.company_name, setReloadVaccineData, vaccineCompanies, vaccineReceived])

	// useEffect(() => {
	// 	if (vaccineShots && vaccineShots.length > 0) {
	// 		if (vaccineShots.length === 1) {
	// 			if (vaccineShots[0].shot_number === 1) {
	// 				setDoseOneData(vaccineShots[0])
	// 			}
	// 			// if (vaccineReceived === 'partially') {
	// 			// 	checkImage(vaccineShots[0].image_url).then(resp => {
	// 			// 		setDisplayData({
	// 			// 			image_url: resp
	// 			// 		})
	// 			// 		setFile([{ preview: resp }])
	// 			// 		setHasImage(true)
	// 			// 	})
	// 			// } else {
	// 			// 	setDisplayData({
	// 			// 		image_url: ''
	// 			// 	})
	// 			// 	setFile([{ preview: '' }])
	// 			// }
	// 			// if (vaccineShots[0].shot_number === 2) {
	// 			// 	setDoseTwoData(vaccineShots[0])
	// 			// }
	// 		} else if (vaccineShots.length === 2) { // two shots
	// 			setDoseOneData(vaccineShots[0])
	// 			if (vaccineShots[1].is_booster) {
	// 				setDoseBoosterData(vaccineShots[1])
	// 			} else {
	// 				setDoseTwoData(vaccineShots[1])
	// 			}
	// 			// if (vaccineReceived === 'fully') {
	// 			// 	if (vaccineShots[1].image_url) {
	// 			// 		checkImage(vaccineShots[1].image_url).then(resp => {
	// 			// 			setDisplayData({
	// 			// 				image_url: resp
	// 			// 			})
	// 			// 			setFile([{ preview: resp }])
	// 			// 			setHasImage(true)
	// 			// 		})
	// 			// 	}
	// 			// } else {
	// 			// 	setDisplayData({
	// 			// 		image_url: ''
	// 			// 	})
	// 			// 	setFile([{ preview: '' }])
	// 			// }
	// 		} else {
	// 			setDoseOneData(vaccineShots[0])
	// 			setDoseTwoData(vaccineShots[1])
	// 			setDoseBoosterData(vaccineShots[2])
	// 		} // three shots
	// 	}
	// }, [vaccineShots])

	const changeDoseOneData = (key: any, value: any) => {
		let localDoseOneData = doseOneData

		if (!localDoseOneData) {
			localDoseOneData = { location: '', lot_number: '', received_on: '', shot_number: 1 }
		}

		let doseOneDataCopy = { ...localDoseOneData }

		//@ts-ignore
		doseOneDataCopy[key] = value
		setDoseOneData(doseOneDataCopy)
	}

	const changeDoseTwoData = (key: any, value: any) => {
		let localDoseTwoData = doseTwoData

		if (!localDoseTwoData) {
			localDoseTwoData = { location: '', lot_number: '', received_on: '', shot_number: 2 }
		}

		let doseTwoDataCopy = { ...localDoseTwoData }

		//@ts-ignore
		doseTwoDataCopy[key] = value
		setDoseTwoData(doseTwoDataCopy)
	}

	const changeDoseBoosterData = (key: any, value: any) => {
		let localDoseThreeData = doseBoosterData

		if (!localDoseThreeData) {
			localDoseThreeData = { location: '', lot_number: '', received_on: '', shot_number: 1 }
		}

		let doseBoosterDataCopy = { ...localDoseThreeData }

		//@ts-ignore
		doseBoosterDataCopy[key] = value
		setDoseBoosterData(doseBoosterDataCopy)
	}

	useEffect(() => {
		console.log('doseOneData', doseOneData)
		console.log('doseTwoData', doseTwoData)
		console.log('doseBoosterData', doseBoosterData)
	}, [doseOneData, doseTwoData, doseBoosterData])

	const checkImage = async (image_url: string | any) => {
		const hasImage = await vaccineController.checkVaccineProofImage(image_url)
		if (hasImage.success) return image_url
		else return ''
	}

	const handleReset = () => {
		setConfirm(false)
		setDelete(false)
	}

	const resizeFile = (file: any) => {
		return new Promise(resolve => {
			Resizer.imageFileResizer(
				file,
				800,
				1000,
				'JPEG',
				100,
				0,
				uri => {
					resolve(uri)
				},
				'base64'
			)
		})
	}

	const imageUpload = async (fileData: any) => {
		if (fileData.length >= 1 && fileData[0].type !== 'application/pdf') {
			setUploading(true)
			const file = fileData[0]
			const image = await resizeFile(file)
			setFileChanged(true)
			setHasImage(true)
			setDisplayData({
				image_url: image
			})
			setShowPreview(true)
			// setShowCropModal(true)
		} else {
			setUploading(true)
			const file = fileData[0]
			var fileReader = new FileReader()
			pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker

			fileReader.onload = async function(e) {
				var typedarray = new Uint8Array(e.target!.result as ArrayBuffer)

				const loadingTask = pdfjsLib.getDocument(typedarray)
				const pdfDoc = await loadingTask.promise

				const page = await pdfDoc.getPage(1)
				const viewport = page.getViewport({ scale: 1.0 })

				var canvas = document.createElement('canvas'),
					ctx = canvas.getContext('2d')
				var renderContext = {
					canvasContext: ctx as object,
					viewport: viewport
				}

				canvas.height = viewport.height
				canvas.width = viewport.width

				const renderTask = page.render(renderContext)
				await renderTask.promise

				const image = canvas.toDataURL('image/jpeg')
				setFileChanged(true)
				setHasImage(true)
				setDisplayData({
					image_url: image
				})
				setShowPreview(true)
				// setShowCropModal(true)
			}

			fileReader.readAsArrayBuffer(file)
		}
	}

	const closeImageCropped = (): void => {
		setShowCropModal(false)
		setUploading(false)
		setFileChanged(false)
		setFile('')
		setDisplayData({
			image_url: ''
		})
	}

	const saveImage = (): void => {
		if (!currentVaccineProof) {
			if (vaccineReceived === 'partially') {
				vaccineController.getUploadUrl().then(response => {
					let doseOne: VaccineProofShot = {
						location: doseOneData ? doseOneData.location : '',
						lot_number: doseOneData ? doseOneData.lot_number : '',
						received_on: doseOneData ? doseOneData.received_on : '',
						shot_number: doseOneData ? doseOneData.shot_number : 1,
						image_key: response.data.image_key,
						signed_url: response.data.signed_url,
					}
					setDoseOneData(doseOne)
				})
			}

			if (vaccineReceived === 'fully') {
				vaccineController.getUploadUrl().then(response => {
					let doseOne: VaccineProofShot = {
						location: doseOneData ? doseOneData.location : '',
						lot_number: doseOneData ? doseOneData.lot_number : '',
						received_on: doseOneData ? doseOneData.received_on : '',
						shot_number: doseOneData ? doseOneData.shot_number : 1,
						image_key: response.data.image_key,
						signed_url: response.data.signed_url,
					}
					setDoseOneData(doseOne)
					if (manufacturer.company_name !== oneDose[0]) {
						vaccineController.getUploadUrl().then(response => {
							let doseTwo: VaccineProofShot = {
								location: doseTwoData ? doseTwoData.location : '',
								lot_number: doseTwoData ? doseTwoData.lot_number : '',
								received_on: doseTwoData ? doseTwoData.received_on : '',
								shot_number: doseTwoData ? doseTwoData.shot_number : 2,
								image_key: response.data.image_key,
								signed_url: response.data.signed_url,
							}
							setDoseTwoData(doseTwo)
						})
					}
				})
			}

			if (vaccineReceived === 'booster') {
				vaccineController.getUploadUrl().then(response => {
					let doseBooster: VaccineProofShot = {
						location: doseBoosterData ? doseBoosterData.location : '',
						lot_number: doseBoosterData ? doseBoosterData.lot_number : '',
						received_on: doseBoosterData ? doseBoosterData.received_on : '',
						shot_number: doseBoosterData ? doseBoosterData.shot_number : 1,
						image_key: response.data.image_key,
						signed_url: response.data.signed_url,
					}
					setDoseBoosterData(doseBooster)
				})
			}
		} else if (currentVaccineProof.type === 1) { // currentVaccineProof
			if (vaccineReceived === 'partially') {
				vaccineController.getUploadUrl(doseOneData?.image_key).then(response => {
					let doseOne: VaccineProofShot = {
						...doseOneData,
						location: doseOneData ? doseOneData.location : '',
						lot_number: doseOneData ? doseOneData.lot_number : '',
						received_on: doseOneData ? doseOneData.received_on : '',
						shot_number: doseOneData ? doseOneData.shot_number : 1,
						image_key: response.data.image_key,
						signed_url: response.data.signed_url,
					}
					setDoseOneData(doseOne)
				})
			}
			if (vaccineReceived === 'fully') {
				if (doseTwoData?.image_key) {
					vaccineController.getUploadUrl(doseTwoData?.image_key).then(response => {
						let doseTwo: VaccineProofShot = {
							...doseTwoData,
							location: doseTwoData ? doseTwoData.location : null,
							lot_number: doseTwoData ? doseTwoData.lot_number : null,
							received_on: doseTwoData ? doseTwoData.received_on : null,
							shot_number: doseTwoData ? doseTwoData.shot_number : 2,
							image_key: response.data.image_key,
							signed_url: response.data.signed_url,
						}
						setDoseTwoData(doseTwo)
					})
				} else {
					vaccineController.getUploadUrl().then(response => {
						let doseTwo: VaccineProofShot = {
							...doseTwoData,
							location: doseTwoData ? doseTwoData.location : null,
							lot_number: doseTwoData ? doseTwoData.lot_number : null,
							received_on: doseTwoData ? doseTwoData.received_on : null,
							shot_number: doseTwoData ? doseTwoData.shot_number : 2,
							image_key: response.data.image_key,
							signed_url: response.data.signed_url,
						}
						setDoseTwoData(doseTwo)
					})
				}
			}
			if (doseOneData?.status !== 'A') {
				setTimeout(() => {
					let firstField = document.getElementById('lotNumber_one')
					firstField?.focus()
				}, 0)
			} else {
				setTimeout(() => {
					let secondField = document.getElementById('lotNumber_two')
					secondField?.focus()
				}, 0)
			}
		} else if (currentVaccineProof.type === 2) {
			if (vaccineReceived === 'booster') {
				vaccineController.getUploadUrl(doseBoosterData?.image_key).then(response => {
					let doseBooster: VaccineProofShot = {
						...doseBoosterData,
						location: doseBoosterData ? doseBoosterData.location : '',
						lot_number: doseBoosterData ? doseBoosterData.lot_number : '',
						received_on: doseBoosterData ? doseBoosterData.received_on : '',
						shot_number: doseBoosterData ? doseBoosterData.shot_number : 1,
						image_key: response.data.image_key,
						signed_url: response.data.signed_url,
					}
					setDoseBoosterData(doseBooster)
				})
			}
		}
	}

	function b64toBlob(dataURI: any) {
		const byteString = atob(dataURI.split(',')[1])
		const ab = new ArrayBuffer(byteString.length)
		const ia = new Uint8Array(ab)

		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i)
		}
		return new Blob([ab], { type: 'image/jpeg' })
	}

	const handleImageCropped = (blob: Blob, previewUrl: string): void => {
		setFile(blob)
		setDisplayData({
			image_url: previewUrl
		})
		setShowCropModal(false)
		setUploading(false)
		saveImage()
	}

	const onSaveImage = (): void => {
		const blob = b64toBlob(displayData.image_url)
		setFile(blob)
		setShowPreview(false)
		setUploading(false)
		saveImage()
	}

	const getApproverMessage: any = (status: string, approverMessage: string, approverMessageId: number) => {
		let vaccineStatus: any = {
			p: function() {
				return { type: '', text: '' }
			},
			// p: function() {
			// 	return { type: 'warning', text: 'Your proof of vaccine has been saved.' }
			// },
			a: function() {
				return { type: 'success', text: 'Your vaccine submission has been approved.' }
			},
			r: function() {
				if (approverMessageId) {
					let reject = reason.find((item: any) => item.reason_id === approverMessageId)
					return { type: 'error', text: reject.reason_text }
				} else {
					if (approverMessage === 'Unclear picture') return { type: 'error', text: reason[0].reason_text }
					if (approverMessage === 'Missing information') return { type: 'error', text: reason[1].reason_text }
					if (approverMessage === 'Invalid information') return { type: 'error', text: reason[2].reason_text }
				}
			}
		}
		if (status) return vaccineStatus[status.toLowerCase()]()
		else return ''
	}

	return (
		<>
			<div className="medical nobackground">
				{!!(error && error.length) &&
					error.map((er, index) => (
						<div key={`er-${index}`}>
							<Alert text={er} type="error" />
						</div>
					))}

				{isLoading && <ActionList items={[]} isLoading />}
				{!isLoading && (
					<>
						<Spacer />
						<Form formValidationTrigger={'onSubmit'}>
							{methods => {
								const onSave = () => {
									methods.trigger().then(valid => {
										//Validation
										setError([])
										const errorsObj: string[] = []

										if (!valid) {
											let drawerBody = document.getElementsByClassName('drawer')[0]
											// drawerBody.scroll(0,0);
											// errorsObj.push('Some fields are required')

											let formInputs = document.getElementsByTagName('input')
											for (const input in formInputs) {
												if (formInputs[input].required && !formInputs[input].value) {
													const errorType = formInputs[input].getAttribute('aria-label')
													errorsObj.push(`${errorType} is required.`)
													// drawerBody.scroll(0,0);
													setTimeout(() => {
														formInputs[input].focus()
														drawerBody.scroll(0, 0)
													}, 0)
													break
												}
											}
											setTimeout(() => {}, 0)
										} else {
											errorsObj.filter(err => err !== 'Some fields are required')
										}

										if (!hasImage && valid) {
											let drawerBody = document.getElementsByClassName('drawer')[0]
											drawerBody.scroll(0, 0)
											errorsObj.push('Please select a valid image')
										} else {
											errorsObj.filter(err => err !== 'Please select a valid image')
										}

										if (doseOneData && valid) {
											if (!doseOneData?.received_on) {
												let drawerBody = document.getElementsByClassName('drawer')[0]
												drawerBody.scroll(0, 0)
												errorsObj.push('Please select or enter a date for the First shot Received.')
											} else {
												errorsObj.filter(err => err !== 'Please select or enter a date for the First shot Received.')
											}
										}

										if (doseTwoData && valid) {
											if (!doseTwoData?.received_on) {
												let drawerBody = document.getElementsByClassName('drawer')[0]
												drawerBody.scroll(0, 0)
												errorsObj.push('Please select or enter a date for the Second shot Received.')
											} else {
												errorsObj.filter(err => err !== 'Please select or enter a date for the Second shot Received.')
											}
										}

										if (doseBoosterData && valid) {
											if (!doseBoosterData?.received_on) {
												let drawerBody = document.getElementsByClassName('drawer')[0]
												drawerBody.scroll(0, 0)
												errorsObj.push('Please select or enter a date for the booster shot Received.')
											} else {
												errorsObj.filter(err => err !== 'Please select or enter a date for the booster shot Received.')
											}
										}
										if (errorsObj.length) {
											setError(errorsObj)
											return
										}
										//End of Validation

										setBusy(true)
										if (!currentVaccineProof) { // No current vaccine proof
											// console.log('NEW PROOF')
											let vaccineShotData: VaccineProof = {
												type: vaccineReceived === 'booster'? 2: 1,
												company: manufacturer.medical_vaccine_company_guid,
												shots: []
											}

											if (doseOneData)
												vaccineShotData.shots.push({
													image_key: doseOneData.image_key,
													location: doseOneData.location,
													lot_number: doseOneData.lot_number,
													received_on: doseOneData.received_on,
													shot_number: doseOneData.shot_number,
												})

											if (doseTwoData)
												vaccineShotData.shots.push({
													image_key: doseTwoData.image_key,
													location: doseTwoData.location,
													lot_number: doseTwoData.lot_number,
													received_on: doseTwoData.received_on,
													shot_number: doseTwoData.shot_number,
												})

											if (doseBoosterData)
												vaccineShotData.shots.push({
													image_key: doseBoosterData.image_key,
													location: doseBoosterData.location,
													lot_number: doseBoosterData.lot_number,
													received_on: doseBoosterData.received_on,
													shot_number: doseBoosterData.shot_number,
												})

											// vaccineController.postVaccineProofUpload(vaccineShotData)
											if (file && fileChanged) {
												if (vaccineReceived === 'partially') {
													vaccineController.uploadVaccineProof(doseOneData?.signed_url, file, '').then(resp => {
														if (resp) {
															vaccineController.postVaccineProofUpload(vaccineShotData).then(resp => {
																setBusy(false)
																setReloadVaccineData(true)
																setVaccineReceived('fully')
																setActiveDrawer(ActiveDrawer.None)
															})
														} else {
															let drawerBody = document.getElementsByClassName('drawer')[0]
															drawerBody.scroll(0, 0)
															setBusy(false)
															setError(['Failed to upload picture. Please try to re-upload the image'])
														}
													})
												}

												if (vaccineReceived === 'fully') {
													vaccineController.uploadVaccineProof(doseOneData?.signed_url, file, '').then(resp => {
														setBusy(false)
														if (resp) {
															if (manufacturer.company_name !== oneDose[0]) {
																vaccineController.uploadVaccineProof(doseTwoData?.signed_url, file, '').then(resp => {
																	if (resp) {
																		vaccineController.postVaccineProofUpload(vaccineShotData).then(resp => {
																			setBusy(false)
																			setReloadVaccineData(true)
																			// setVaccineReceived('')
																			setActiveDrawer(ActiveDrawer.None)
																		})
																	} else {
																		let drawerBody = document.getElementsByClassName('drawer')[0]
																		drawerBody.scroll(0, 0)
																		setBusy(false)
																		setError(['Failed to upload picture. Please try to re-upload the image'])
																	}
																})
															} else { // One dose vaccine
																vaccineController.postVaccineProofUpload(vaccineShotData).then(resp => {
																	setReloadVaccineData(true)
																	// setVaccineReceived('')
																	setActiveDrawer(ActiveDrawer.None)
																})
															}
														} else { // if no response from server
															let drawerBody = document.getElementsByClassName('drawer')[0]
															drawerBody.scroll(0, 0)
															setBusy(false)
															setError(['Failed to upload picture. Please try to re-upload the image'])
														}
													})
												}

												if (vaccineReceived === 'booster') {
													vaccineController.uploadVaccineProof(doseBoosterData?.signed_url, file, '').then(resp => {
														if (resp) {
															vaccineController.postVaccineProofUpload(vaccineShotData).then(resp => {
																setBusy(false)
																setReloadVaccineData(true)
																// setVaccineReceived('booster')
																setActiveDrawer(ActiveDrawer.None)
															})
														} else {
															let drawerBody = document.getElementsByClassName('drawer')[0]
															drawerBody.scroll(0, 0)
															setBusy(false)
															setError(['Failed to upload picture. Please try to re-upload the image'])
														}
													})
												}
											} else { // if no file data or file data hasn't changed
												setBusy(false)
												setReloadVaccineData(true)
												// setVaccineReceived('')
												setActiveDrawer(ActiveDrawer.None)
											}
										} else { // currentVacineProof from global state is defined
											// Update
											// console.log('UPDATE PROOF')
											let vaccineShotData: VaccineProof = {
												type: vaccineReceived === 'booster'? 2: 1,
												company: manufacturer.medical_vaccine_company_guid, // Passing company on PUT
												proof_guid: currentVaccineProof.proof_guid,
												shots: []
											}

											if (doseOneData)
												vaccineShotData.shots.push({
													image_key: doseOneData.image_key,
													location: doseOneData.location,
													lot_number: doseOneData.lot_number,
													received_on: doseOneData.received_on,
													shot_number: doseOneData.shot_number,
												})

											if (doseTwoData)
												vaccineShotData.shots.push({
													shot_number: doseTwoData.shot_number,
													location: doseTwoData.location,
													lot_number: doseTwoData.lot_number,
													received_on: doseTwoData.received_on,
													image_key: doseTwoData.image_key,
												})

											if (doseBoosterData)
												vaccineShotData.shots.push({
													shot_number: doseBoosterData.shot_number,
													location: doseBoosterData.location,
													lot_number: doseBoosterData.lot_number,
													received_on: doseBoosterData.received_on,
													image_key: doseBoosterData.image_key,
												})

											// vaccineController.putVaccineProofUpload(vaccineShotData)
											if (file && fileChanged) {
												if (doseOneData?.status !== 'A' && vaccineReceived === 'partially') {
													vaccineController.uploadVaccineProof(doseOneData?.signed_url, file).then(resp => {
														if (resp) {
															vaccineController.putVaccineProofUpload(vaccineShotData).then(resp => {
																setBusy(false)
																setReloadVaccineData(true)
																setActiveDrawer(ActiveDrawer.None)
															})
														} else {
															let drawerBody = document.getElementsByClassName('drawer')[0]
															drawerBody.scroll(0, 0)
															setBusy(false)
															setError(['Failed to upload picture. Please try to re-upload the image'])
														}
													})
												}

												if (doseTwoData?.status !== 'A' && vaccineReceived === 'fully') {
													vaccineController.uploadVaccineProof(doseTwoData?.signed_url, file).then(resp => {
														if (resp) {
															vaccineController.putVaccineProofUpload(vaccineShotData).then(resp => {
																setBusy(false)
																setReloadVaccineData(true)
																setActiveDrawer(ActiveDrawer.None)
															})
														} else {
															let drawerBody = document.getElementsByClassName('drawer')[0]
															drawerBody.scroll(0, 0)
															setBusy(false)
															setError(['Failed to upload picture. Please try to re-upload the image'])
														}
													})
												}

												if (doseBoosterData?.status !== 'A' && vaccineReceived === 'booster') {
													vaccineController.uploadVaccineProof(doseBoosterData?.signed_url, file).then(resp => {
														if (resp) {
															vaccineController.putVaccineProofUpload(vaccineShotData).then(resp => {
																setBusy(false)
																setReloadVaccineData(true)
																setActiveDrawer(ActiveDrawer.None)
															})
														} else {
															let drawerBody = document.getElementsByClassName('drawer')[0]
															drawerBody.scroll(0, 0)
															setBusy(false)
															setError(['Failed to upload picture. Please try to re-upload the image'])
														}
													})
												}
											} else {
												vaccineController.putVaccineProofUpload(vaccineShotData).then(resp => {
													setBusy(false)
													setReloadVaccineData(true)
													setActiveDrawer(ActiveDrawer.None)
												})
											}
										}
									})
								}

								const onDelete = () => {
									methods.trigger().then(() => {
										setDelete(true)
										if (currentVaccineProof /* && (vaccineReceived !== 'booster') */) {
											vaccineController.deleteVaccineProof(currentVaccineProof.proof_guid, `${currentVaccineProof.type}`).then(resp => {
												if (resp.success) {
													setDelete(false)
													setReloadVaccineData(true)
													setCurrentVaccineProof(undefined)
													setVaccineProofList(undefined)
													setDisplayData({})
													setActiveDrawer(ActiveDrawer.None)
													setVaccineReceived('')
												}
											})
										} /* else if (currentVaccineProof && (vaccineReceived === 'booster')) {
											const shotToDelete = currentVaccineProof.shots.find(shot => shot.is_booster)?.shot_number
											if (shotToDelete) {
												vaccineController.deleteVaccineProof(currentVaccineProof.proof_guid, shotToDelete).then(resp => {
													if (resp.success) {
														setDelete(false)
														setReloadVaccineData(true)
														setCurrentVaccineProof(undefined)
														setVaccineProofList(undefined)
														setDisplayData({})
														setActiveDrawer(ActiveDrawer.None)
														setVaccineReceived('')
													}
												})
											}
										} */

										// 	if (currentVaccineProof.shots.length <= 1 && currentVaccineProof.shots[0].status !== 'A') {
										// 		vaccineController.deleteVaccineProof(currentVaccineProof.proof_guid).then(resp => {
										// 			if (resp.success) {
										// 				setDelete(false)
										// 				setReloadVaccineData(true)
										// 				setCurrentVaccineProof(undefined)
										// 				setVaccineProofList(undefined)
										// 				setDisplayData({})
										// 				setActiveDrawer(ActiveDrawer.None)
										// 				setVaccineReceived('')
										// 			}
										// 		})
										// 	} else {
										// 		setDelete(false)
										// 	}
										// 	if (
										// 		vaccineReceived === 'partially' &&
										// 		currentVaccineProof.shots.length === 2 &&
										// 		currentVaccineProof.shots[0]?.status !== 'A'
										// 	) {
										// 		vaccineController
										// 			.deleteVaccineProof(currentVaccineProof.proof_guid, currentVaccineProof.shots[0].shot_number)
										// 			.then(resp => {
										// 				if (resp.success) {
										// 					setDelete(false)
										// 					setReloadVaccineData(true)
										// 					setCurrentVaccineProof(undefined)
										// 					setVaccineProofList(undefined)
										// 					setDisplayData({})
										// 					setActiveDrawer(ActiveDrawer.None)
										// 				}
										// 			})
										// 	}
										// 	if (
										// 		vaccineReceived === 'fully' &&
										// 		currentVaccineProof.shots.length === 2 &&
										// 		currentVaccineProof.shots[1]?.status !== 'A'
										// 	) {
										// 		vaccineController
										// 			.deleteVaccineProof(currentVaccineProof.proof_guid, currentVaccineProof.shots[1].shot_number)
										// 			.then(resp => {
										// 				if (resp.success) {
										// 					setDelete(false)
										// 					setReloadVaccineData(true)
										// 					setCurrentVaccineProof(undefined)
										// 					setVaccineProofList(undefined)
										// 					setDisplayData({})
										// 					setActiveDrawer(ActiveDrawer.None)
										// 				}
										// 			})
										// 	}
										// }
									})
								}

								return (
									<>
										{vaccineReceived === 'partially' &&
											getApproverMessage(
												doseOneData?.status,
												doseOneData?.approver_message,
												doseOneData?.approver_reason_id
											) && (
												<Alert
													text={
														getApproverMessage(
															doseOneData?.status,
															doseOneData?.approver_message,
															doseOneData?.approver_reason_id
														).text
													}
													type={
														getApproverMessage(
															doseOneData?.status,
															doseOneData?.approver_message,
															doseOneData?.approver_reason_id
														).type
													}
												/>
											)}
										{vaccineReceived === 'fully' &&
											getApproverMessage(
												doseTwoData?.status,
												doseTwoData?.approver_message,
												doseTwoData?.approver_reason_id
											) &&
											currentVaccineProof && (
												<Alert
													text={
														getApproverMessage(
															doseTwoData?.status,
															doseTwoData?.approver_message,
															doseTwoData?.approver_reason_id
														).text
													}
													type={
														getApproverMessage(
															doseTwoData?.status,
															doseTwoData?.approver_message,
															doseTwoData?.approver_reason_id
														).type
													}
												/>
											)}
										{vaccineReceived === 'booster' &&
											getApproverMessage(
												doseBoosterData?.status,
												doseBoosterData?.approver_message,
												doseBoosterData?.approver_reason_id
											) &&
											currentVaccineProof && (
												<Alert
													text={
														getApproverMessage(
															doseBoosterData?.status,
															doseBoosterData?.approver_message,
															doseBoosterData?.approver_reason_id
														).text
													}
													type={
														getApproverMessage(
															doseBoosterData?.status,
															doseBoosterData?.approver_message,
															doseBoosterData?.approver_reason_id
														).type
													}
												/>
											)}
										<ImageUpload
											onChange={file => {
												imageUpload(file)
											}}
											text={appText[COVID_TEXTS["vaccine.pov.detail.photo.caption"]]}
											label={'Proof of vaccine'}
											noClear
											imageUrl={displayData.image_url}
											isLoading={isUploading}
											editButtonAriaLabel={'Edit Uploaded image.'}
											displayButtons={
												(vaccineReceived === 'partially' && doseOneData?.status !== 'A') ||
												(vaccineReceived === 'fully' && doseTwoData?.status !== 'A') ||
												(vaccineReceived === 'booster' && doseBoosterData?.status !== 'A')
											}
										/>
										<TemplateModal
											name="imagePreview"
											show={showPreview}
											autoHeight
											// onClose={() => onCloseCropModal()}
											bodyAlignment={TemplateModalAlignment.Center}
											size={TemplateModalSize.Large}
											header={<Text content={'Image preview'} type={TextType.Heading2} />}
											body={
												<>
													<img src={displayData.image_url} style={{ maxWidth: '100%' }} alt="" />
												</>
											}
											footer={
												<>
													<ButtonBar
														buttons={[
															{
																padded: true,
																icon: 'crop',
																type: ButtonType.Ghost | ButtonType.Secondary,
																text: 'Crop Image',
																onClick: () => {
																	setShowCropModal(true)
																	setShowPreview(false)
																},
																customAriaLabel: 'Crop Image.'
															},
															{
																padded: true,
																icon: 'save',
																text: 'Save Image',
																onClick: () => onSaveImage(),
																customAriaLabel: 'Save Image.'
															}
														]}
													/>
													{/* <div  className="mobile-only" style={{paddingBottom: '3rem', width: '100%'}} /> */}
													{mobile && <div style={{ paddingBottom: '3rem', width: '100%' }} />}
												</>
											}
										/>
										<ImageCrop
											showCropModal={showCropModal}
											uploadImage={displayData.image_url}
											onCloseCropModal={closeImageCropped}
											handleCropImage={handleImageCropped}
										/>
										<Spacer size="2" />
										<FormContainer methods={methods} formValidationTrigger="onSubmit" id="vaccineProofForm">
											<FormField
												editor="textbox"
												id="company"
												label={appText[COVID_TEXTS['vaccine.pov.detail.company']] ?? 'Product Name'}
												labelDisplay="nextto"
												value={manufacturer.company_name}
												properties={{
													onFocus: () => setActiveDrawer(ActiveDrawer.VaccineManufacturer)
												}}
												// readonly
												// onChange={() => console.log('onChange -> bring up manufacturers')}
												ariaLabel={`${appText[COVID_TEXTS['vaccine.pov.detail.company']] ?? 'Product Name'} is ${
													manufacturer.company_name
												}.`}
											/>
											{/* <Spacer /> */}
											{vaccineReceived === 'booster' && (
												<>
												<fieldset name={'Booster'}>
														<div className="form-group row">
															<label className="col-sm-4 text-right col-form-label" style={{ fontWeight: 300 }}>
																BOOSTER SHOT
															</label>
														</div>
												{/* DOSE THREE FORM (BOOSTER) */}
												<FormField
													editor="textbox"
													id="lotNumber"
													label={appText[COVID_TEXTS['vaccine.pov.detail.lot']] ?? 'Lot Number'}
													labelDisplay="nextto"
													value={doseBoosterData?.lot_number}
													onChange={(e: any) => changeDoseBoosterData('lot_number', e.currentTarget.value)}
													required
													readonly={doseBoosterData?.status === 'A'}
													ariaLabel={`Booster shot, ${appText[COVID_TEXTS['vaccine.pov.detail.lot']] ?? 'Lot Number'}`}
												/>
												<div className="form-group row calendar">
													<label htmlFor="dob" className="col-sm-4 text-right col-form-label">
														{appText[COVID_TEXTS['vaccine.pov.detail.received']] ?? 'Date Received'}
													</label>
													<div className="col-sm-8">
														<CalendarInput
															id="received_on"
															value={doseBoosterData?.received_on ? doseBoosterData?.received_on : null}
															onChange={(e: string) => changeDoseBoosterData('received_on', e)}
															maxDate={maxDate}
															// minDate={minDate}
															readonly={doseBoosterData?.status === 'A'}
															placeholder={'MM/DD/YYYY'}
															required={true}
															properties={{
																'aria-label': `Booster shot, ${appText[COVID_TEXTS['vaccine.pov.detail.received']] ??
																	'Date Received'} format month-month/day-day/year-year-year-year`
															}}
														/>
													</div>
												</div>
												<FormField
													editor="textbox"
													id="location"
													label={appText[COVID_TEXTS['vaccine.pov.detail.site']] ?? 'Healthcare Site'}
													labelDisplay="nextto"
													value={doseBoosterData?.location}
													onChange={(e: any) => changeDoseBoosterData('location', e.currentTarget.value)}
													required
													readonly={doseBoosterData?.status === 'A'}
													ariaLabel={`Booster shot, ${appText[COVID_TEXTS['vaccine.pov.detail.site']] ??
														'Healthcare Site'}`}
												/>
												<div className="row pt-4 pb-4">
													<div className="col-12 text-center">
														<Button
															// text='Submit'
															text={doseBoosterData ? 'Update' : 'Submit'}
															isBusy={isBusy}
															onClick={() => onSave()}
															state={isBusy ? ButtonState.Disabled : ButtonState.Enabled}
															customAriaLabel={doseBoosterData ? 'Update' : 'Submit'}
														/>
														{currentVaccineProof &&
														<>
															&nbsp;
															&nbsp;
															<Button
																text={'Delete'}
																isBusy={isDeleting}
																onClick={() => setConfirm(true)}
																state={isDeleting ? ButtonState.Disabled : ButtonState.Enabled}
																customAriaLabel="Delete"
															/>
														</>
														}
													</div>
												</div>
												</fieldset>
											</>
											)}
											{(vaccineReceived === 'partially' || (manufacturer.company_name === oneDose[0] && vaccineReceived !== 'booster')) ? (
												<>
													{/* DOSE ONE FORM */}
													<FormField
														editor="textbox"
														id="lotNumber"
														label={appText[COVID_TEXTS['vaccine.pov.detail.lot']] ?? 'Lot Number'}
														labelDisplay="nextto"
														value={doseOneData?.lot_number}
														onChange={(e: any) => changeDoseOneData('lot_number', e.currentTarget.value)}
														required
														readonly={doseOneData?.status === 'A'}
														ariaLabel={`First dose, ${appText[COVID_TEXTS['vaccine.pov.detail.lot']] ?? 'Lot Number'}`}
													/>
													<div className="form-group row calendar">
														<label htmlFor="dob" className="col-sm-4 text-right col-form-label">
															{appText[COVID_TEXTS['vaccine.pov.detail.received']] ?? 'Date Received'}
														</label>
														<div className="col-sm-8">
															<CalendarInput
																id="received_on"
																value={doseOneData?.received_on ? doseOneData?.received_on : null}
																onChange={(e: string) => changeDoseOneData('received_on', e)}
																maxDate={maxDate}
																// minDate={minDate}
																readonly={doseOneData?.status === 'A'}
																placeholder={'MM/DD/YYYY'}
																required={true}
																properties={{
																	'aria-label': `First dose, ${appText[COVID_TEXTS['vaccine.pov.detail.received']] ??
																		'Date Received'} format month-month/day-day/year-year-year-year`
																}}
															/>
														</div>
													</div>
													<FormField
														editor="textbox"
														id="location"
														label={appText[COVID_TEXTS['vaccine.pov.detail.site']] ?? 'Healthcare Site'}
														labelDisplay="nextto"
														value={doseOneData?.location}
														onChange={(e: any) => changeDoseOneData('location', e.currentTarget.value)}
														required
														readonly={doseOneData?.status === 'A'}
														ariaLabel={`First dose, ${appText[COVID_TEXTS['vaccine.pov.detail.site']] ??
															'Healthcare Site'}`}
													/>
												</>
											) : vaccineReceived !== 'booster' && (
												<>
													{/* DOSE ONE FORM */}
													<fieldset name={'Dose One'}>
														<div className="form-group row">
															<label className="col-sm-4 text-right col-form-label" style={{ fontWeight: 300 }}>
																FIRST DOSE
															</label>
														</div>
														{/* <Text content={'FIRST SHOT'} type={TextType.Body} bold/> */}
														<FormField
															editor="textbox"
															id="lotNumber_one"
															label={appText[COVID_TEXTS['vaccine.pov.detail.lot']] ?? 'Lot Number'}
															labelDisplay="nextto"
															value={doseOneData?.lot_number}
															onChange={(e: any) => changeDoseOneData('lot_number', e.currentTarget.value)}
															required
															readonly={doseOneData?.status === 'A'}
															// readonly={true}
															ariaLabel={`First dose, ${appText[COVID_TEXTS['vaccine.pov.detail.lot']] ??
																'Lot Number'}`}
														/>
														<div className="form-group row calendar">
															<label htmlFor="dob" className="col-sm-4 text-right col-form-label">
																{appText[COVID_TEXTS['vaccine.pov.detail.received']] ?? 'Date Received'}
															</label>
															<div className="col-sm-8">
																<CalendarInput
																	id="received_on_one"
																	value={doseOneData?.received_on ? doseOneData?.received_on : null}
																	onChange={(e: string) => changeDoseOneData('received_on', e)}
																	maxDate={maxDate}
																	// minDate={minDate}
																	readonly={doseOneData?.status === 'A'}
																	placeholder={'MM/DD/YYYY'}
																	required={true}
																	// readonly={true}
																	properties={{
																		'aria-label': `First dose, ${appText[COVID_TEXTS['vaccine.pov.detail.received']] ??
																			'Date Received'} format month-month/day-day/year-year-year-year`
																	}}
																/>
															</div>
														</div>
														<FormField
															editor="textbox"
															id="location_one"
															label={appText[COVID_TEXTS['vaccine.pov.detail.site']] ?? 'Healthcare Site'}
															labelDisplay="nextto"
															value={doseOneData?.location}
															onChange={(e: any) => changeDoseOneData('location', e.currentTarget.value)}
															required
															readonly={doseOneData?.status === 'A'}
															// readonly={true}
															ariaLabel={`First dose, ${appText[COVID_TEXTS['vaccine.pov.detail.site']] ??
																'Healthcare Site'}`}
														/>
													</fieldset>
													<Spacer size={'2'} />
													{/* DOSE TWO FORM */}
													<fieldset name={'Dose Two'}>
														<div className="form-group row">
															<label className="col-sm-4 text-right col-form-label" style={{ fontWeight: 300 }}>
																SECOND DOSE
															</label>
														</div>
														{/* <Text content={'SECOND SHOT'} type={TextType.Body} bold/> */}
														<FormField
															editor="textbox"
															id="lotNumber_two"
															label={appText[COVID_TEXTS['vaccine.pov.detail.lot']] ?? 'Lot Number'}
															labelDisplay="nextto"
															value={doseTwoData?.lot_number}
															onChange={(e: any) => changeDoseTwoData('lot_number', e.currentTarget.value)}
															required
															readonly={doseTwoData?.status === 'A'}
															ariaLabel={`Second dose, ${appText[COVID_TEXTS['vaccine.pov.detail.lot']] ??
																'Lot Number'}`}
														/>

														<div className="form-group row calendar">
															<label htmlFor="dob" className="col-sm-4 text-right col-form-label">
																{appText[COVID_TEXTS['vaccine.pov.detail.received']] ?? 'Date Received'}
															</label>
															<div className="col-sm-8">
																<CalendarInput
																	id="received_on_two"
																	value={doseTwoData?.received_on ? doseTwoData?.received_on : null}
																	onChange={(e: string) => changeDoseTwoData('received_on', e)}
																	maxDate={maxDate}
																	// minDate={minDate}
																	readonly={doseTwoData?.status === 'A'}
																	placeholder={'MM/DD/YYYY'}
																	required={true}
																	properties={{
																		'aria-label': `Second dose, ${appText[COVID_TEXTS['vaccine.pov.detail.received']] ??
																			'Date Received'} format month-month/day-day/year-year-year-year`
																	}}
																/>
															</div>
														</div>
														<FormField
															editor="textbox"
															id="location_two"
															label={appText[COVID_TEXTS['vaccine.pov.detail.site']] ?? 'Healthcare Site'}
															labelDisplay="nextto"
															value={doseTwoData?.location}
															onChange={(e: any) => changeDoseTwoData('location', e.currentTarget.value)}
															required
															readonly={doseTwoData?.status === 'A'}
															ariaLabel={`Second dose, ${appText[COVID_TEXTS['vaccine.pov.detail.site']] ??
																'Healthcare Site'}`}
														/>
													</fieldset>
												</>
											)}
										</FormContainer>

										{vaccineReceived === 'partially' && (
											<div className="row pt-4 pb-4">
												<div className="col-12 text-center">
													{/* <Button
														text=""
														icon="chevron-left"
														onClick={() =>
															currentVaccineProof
																? setActiveDrawer(ActiveDrawer.None)
																: setActiveDrawer(ActiveDrawer.VaccineManufacturer)
														}
														type={ButtonType.Ghost | ButtonType.Secondary}
														title="back"
														customAriaLabel="back"
													/> */}
													{doseOneData?.status !== 'A' && (
														<>
															&nbsp;&nbsp;
															<Button
																text={doseOneData?.status ? 'Update' : 'Submit'}
																isBusy={isBusy}
																onClick={() => onSave()}
																state={isBusy ? ButtonState.Disabled : ButtonState.Enabled}
																customAriaLabel={doseOneData?.status ? 'Update' : 'Submit'}
															/>
															{doseOneData?.status && (
																<>
																	&nbsp;&nbsp;
																	<Button
																		text={'Delete'}
																		isBusy={isDeleting}
																		onClick={() => setConfirm(true)}
																		state={isDeleting ? ButtonState.Disabled : ButtonState.Enabled}
																		customAriaLabel="Delete"
																	/>
																</>
															)}
														</>
													)}
													<Spacer size={'4'} />
													<Spacer size={'4'} />
													<Spacer size={'4'} />
													<Spacer size={'4'} />
												</div>
											</div>
										)}

										{vaccineReceived === 'fully' && (
											<div className="row pt-4 pb-4">
												<div className="col-12 text-center">
													{/* <Button
														text=""
														icon="chevron-left"
														onClick={() =>
															currentVaccineProof
																? setActiveDrawer(ActiveDrawer.None)
																: setActiveDrawer(ActiveDrawer.VaccineManufacturer)
														}
														type={ButtonType.Ghost | ButtonType.Secondary}
														title="back"
														customAriaLabel="back"
													/> */}
													{doseTwoData?.status !== 'A' && (
														<>
															&nbsp;&nbsp;
															<Button
																text={doseTwoData ? 'Update' : 'Submit'}
																isBusy={isBusy}
																onClick={() => onSave()}
																state={isBusy ? ButtonState.Disabled : ButtonState.Enabled}
																customAriaLabel={doseTwoData ? 'Update' : 'Submit'}
															/>
															{doseTwoData?.status && (
																<>
																	&nbsp;&nbsp;
																	<Button
																		text={'Delete'}
																		isBusy={isDeleting}
																		onClick={() => setConfirm(true)}
																		state={isDeleting ? ButtonState.Disabled : ButtonState.Enabled}
																		customAriaLabel="Delete"
																	/>
																</>
															)}
														</>
													)}
													<Spacer size={'4'} />
													<Spacer size={'4'} />
													<Spacer size={'4'} />
													<Spacer size={'4'} />
												</div>
											</div>
										)}

										<TemplateModal
											show={confirm}
											name="deleteVaccineProof"
											theme="medical"
											size={TemplateModalSize.Small}
											bodyAlignment={TemplateModalAlignment.Center}
											header={<Text content={'Confirmation'} type={TextType.Heading4} />}
											body={
												<div className="text-center">
													<Text content={'Are you sure?'} type={TextType.Body} />
													<Spacer size="2" />
													<div className="btn-padded">
														<Button
															text="No"
															type={ButtonType.Ghost | ButtonType.Secondary}
															onClick={() => handleReset()}
															customAriaLabel="No."
														/>
														<Button
															text="Yes"
															isBusy={isDeleting}
															state={isDeleting ? ButtonState.Disabled : ButtonState.Enabled}
															onClick={() => onDelete()}
															type={ButtonType.Primary}
															customAriaLabel="Yes."
														/>
													</div>
												</div>
											}
											footer={<div />}
										/>
									</>
								)
							}}
						</Form>
					</>
				)}
			</div>
		</>
	)
}

const VaccineProofEdit = withRouter(VaccineProofEditComponent)
export default VaccineProofEdit
