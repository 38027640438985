const appConfig = {
  "version": "1.0.2-release+codebuild.845",
  "stage": "release",
  "amplify": {
    "region": "us-east-1",
    "userPoolId": "us-east-1_nY154N4yV",
    "userPoolWebClientId": "vetil9r7h7ieuc16b8kn9fbe2",
    "oauth": {
      "domain": "atlas-auth-test.mit.edu",
      "scope": [
        "openid",
        "profile",
        "digital-id/user",
        "covid19/user"
      ],
      "redirectSignIn": "https://covidvaccine-test.mit.edu",
      "redirectSignOut": "https://covidvaccine-test.mit.edu/logout",
      "responseType": "token",
      "options": {
        "AdvancedSecurityDataCollectionFlag": false
      }
    }
  },
  "signInProvider": "Touchstone",
  "api": {
    "hostname": "https://api-test.mit.edu",
    "paths": {
      "elo": "elo-v1",
      "digitalId": "digital-id-v1",
      "covid19": "covid19-v1",
      "vaccine": "vaccine-v1",
      "pass": "pass-v1",
      "medical": "medical-v1"
    }
  },
  "covidPassUrl": "https://covidpass-test.mit.edu",
  "covidTravelUrl": "https://covidtravel-test.mit.edu"
};
export default appConfig;