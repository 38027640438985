import BaseController from './BaseController'

import { Profile } from '../../types'

export interface ExtendedPersonProfile {
    mit_id: string;
    krb_name: string;
    first_name: string;
    last_name: string;
    email?: string;
    phone?: string;
    gender?: string;
    date_of_birth?: string;
    type?: string;
    address: Address;
}

export interface Address {
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    postal_code?: string;
}

class ProfileController extends BaseController {

	async getProfile (): Promise<Profile> {
		let response = await this.useFetch('GET', 'profile', `${this.apiHost}/${this.apiPathDigitalId}/profile`)

		if (response.status === 200) {
			let data = response.json()
			return data
		} else {
			let text = await response.text()
			return Promise.reject(text)
		}
	}

	async getProfilePicture (mitId?: string, highResolution?: boolean): Promise<any> {
		let response = await this.useFetch('GET', 'picture', `${this.apiHost}/${this.apiPathDigitalId}/picture${mitId ? `/${mitId}${highResolution ? "?resolution=high" : ""}` : ''}`)

		if (response.status === 200) {
			let data = await response.blob();
			return {
                value: URL.createObjectURL(data)
            }
		} else {
			return {
                error: true
            }
		}
	}

	async fetchProfileExtended (mit_id: string | number): Promise<ExtendedPersonProfile> {
        let urlAppend = "/"+ mit_id

        let response = await this.useFetch('GET', 'fetchProfileExtended', `${this.apiHost}/${this.apiPathCovid}/person` + urlAppend);
        if (response.status === 200) {
            let data = await response.json()
            return data;
        } else {
            let text = await response.text();
            return Promise.reject(text);
        }
    }

	async fetchFeatures(): Promise<any> {

		try {
			let response = await this.useFetch('GET', 'features', `${this.apiHost}/${this.apiPathPass}/features`)

			if (response.status === 200) {
				let data = await response.json()
				return data
			} else {
				return {
					error: true
				}
			}
		} catch (e) {
			console.error(e);
		}
	}
}


const profileController = new ProfileController()
export default profileController
