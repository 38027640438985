// Rebuild
import * as React from 'react'
import { FC, useState, useEffect } from 'react'
// import moment, { now } from 'moment'
import { withRouter } from 'react-router'
// import PersonTests from './PersonTests'
import /* CovidText, */ { COVID_TEXTS } from '../../common/Texts'
import { useStoreActions, useStoreState } from '../../store/hooks'
import { mediumLoaderOptions } from '../../common/Defaults'
// import { RequirementIds, StatusHelper } from '../../types/CovidStatusModel'
import {VaccineCompanyInterface, VaccineProofInterface } from '../../api/controller/VaccineController'
import { store } from '../../store'
import { StoreProvider } from 'easy-peasy'
import {
	Button,
	Spacer,
	ButtonType,
	TemplateModal,
	Form,
	FormContainer,
	FormField,
	Alert,
	TemplateModalAlignment,
	TemplateModalSize,
	Text,
	TextType,
	Drawer,
	DrawerLayout,
	DrawerSize,
	DrawerType,
} from '@mit/hui'
import dataController from '../../api/controller/DataController'
import ReactLoader from 'react-loader'
import FluShotSection from '../flushot/FluShotSection'
import CovidSection from '../covid/CovidSection'
import CompactHistory from './CompactHistory'
import StatusTextBlock from './StatusTextBlock'
import { ActiveDrawer } from '../../store/models/global'
import FullHistory from './FullHistory'
import moment from 'moment'

interface VaccineProps {}

const VaccineComponent: FC<VaccineProps> = () => {
	const [isLoading, setIsLoading] = useState(false)
	// const [isDeleting, setIsDeleteing] = useState(false)
	const [isSuccess, setIsSuccess] = useState<number>(0)
	const [isShowingPopup, setIsShowingPopup] = useState<boolean>(false)

	const [showFluShotSection, setShowFluShotSection] = useState(false)
	const [showCovidSection, setShowCovidSection] = useState<any>(false)
	const [showMerged, setShowMerged] = useState(false)

	const [exemptionGranted, setExemptionGranted] = useState(false)
	const [exemptionPendingOrInfo, setExemptionPendingOrInfo] = useState(false)
	const [vaccineCompleted, setVaccineCompleted] = useState(false)
	const [boosterCompleted, setBoosterCompleted] = useState(false)
	const [lastVaccineItem, setLastVaccineItem] = useState<any>(undefined)
	const [isFluShotCompleted, setIsFluShotCompleted] = useState(false)

	const [showUploadBtn, setShowUploadBtn] = useState(false)
	const [showUploadFsBtn, setShowUploadFsBtn] = useState(false)
	const [showReqExemptBtn, setShowReqExemptBtn] = useState(false)
	const [showExemptMoreInfoBtn, setShowExemptMoreInfoBtn] = useState(false)

	const [showFullOrPartialModal, setShowFullOrPartialModal] = useState(false)
	const [uploadBtnFunction, setUploadBtnFunction] = useState<0 | 1 | 2 | 3>(0)

	const { appText, vaccineStatus/* , vaccineReceived, statusModel */, fluShotResultsList, vaccineResultsList, person, reloadVaccineData, reloadTestResults/* , vaccineShots */, vaccineCompanies, features, activeDrawer } = useStoreState(
		store => store.globalModel
	)
	const { setReloadVaccineData, setReloadTestResults, setVaccineResultsList, setVaccineStatus, setFluShotResultsList, setActiveDrawer, setVaccineReceived/* , setVaccineShots */, setCurrentVaccineProof, setManufacturer/* , setVaccineProofList */, setReqExemptBtnClicked } = useStoreActions(store => store.globalModel)

	const rejectedProofs = vaccineResultsList?.filter(item => item.result === 'VACCINE_PROOF_REJECTED')

	const pendingProofs = vaccineResultsList?.filter((item, idx) => {
		return item.result === 'VACCINE_PROOF_PENDING'
	})

	const partialProofs = vaccineResultsList?.filter((item) => item.result === 'VACCINE_PROOF_PARTIAL')
	// const hasPartialProof = partialProofs && partialProofs.length > 0

	const formatDate = (date: string | undefined) => {
		if (date) {
			return moment(date)
				.utc()
				.format('LL')
			// return moment(date).format('MMM DD, YYYY')
		}
		return ''
	}

	const saveData = (data: any) => {
		setIsLoading(true)

		dataController.postVaccineSymptoms(data).then(
			(res: any) => {
				setIsSuccess(1)
				setIsLoading(false)
			},
			(error: any) => {
				setIsLoading(false)
				setIsSuccess(-1)
			}
		)
	}

	useEffect(() => {
		// const lastVaccItem = vaccineResultsList?.filter(item => item.result.slice(0, 6) === 'EXEMPT' || item.result.slice(0, 7) === 'VACCINE')[0]
		let lastVaccItem: any = undefined
		if (vaccineResultsList) {
			lastVaccItem = vaccineResultsList[0]
		}
		const exemptPendingOrInfo = ['EXEMPT_MORE_INFO', 'EXEMPT_PENDING'].includes(lastVaccItem?.result)
		const exemptMoreInfo = lastVaccItem?.result === 'EXEMPT_MORE_INFO'
		const proofList = vaccineStatus?.vaccine_proofs
		const shots = proofList[0]?.shots
		const twoShotCompanies = vaccineCompanies?.filter((company: VaccineCompanyInterface) => company.number_of_shots === 2).map(company => company.medical_vaccine_company_guid)
		// const hasPendingRejectOrPartial = ['VACCINE_PROOF_REJECTED', 'VACCINE_PROOF_PENDING', 'VACCINE_PROOF_PARTIAL'].includes(lastVaccItem.result)
		const hasPendingRejectOrPartial = vaccineResultsList?.findIndex(item => ['VACCINE_PROOF_REJECTED', 'VACCINE_PROOF_PENDING', 'VACCINE_PROOF_PARTIAL'].includes(item.result)) !== -1
		const hasRejectedProof = lastVaccItem?.result === 'VACCINE_PROOF_REJECTED'
		const hasPartialProof = lastVaccItem?.result === 'VACCINE_PROOF_PARTIAL'
		// const hasRejectedProof = vaccineResultsList?.findIndex(item => item.result === 'VACCINE_PROOF_REJECTED') !== -1
		const vaccCompleted = vaccineResultsList?.findIndex(item => ['EXEMPT_APPROVED', 'VACCINE_PROOF'/* , 'VACCINE_BOOSTER_APPROVED' */].includes(item.result)) !== -1
		// const boostCompleted = vaccineResultsList?.findIndex(item => item.result === 'VACCINE_BOOSTER_APPROVED') !== -1
		const boostCompleted = !['VACCINE_BOOSTER_MISSING', 'VACCINE_BOOSTER_PENDING', 'VACCINE_BOOSTER_REJECTED'].includes(lastVaccItem?.result)
		// const vaccCompleted = ['EXEMPT_APPROVED', 'VACCINE_PROOF', 'VACCINE_BOOSTER_APPROVED'].includes(lastVaccItem.result)
		// const vaccCompleted = lastVaccItem.result === 'EXEMPT_APPROVED' || lastVaccItem.result === 'VACCINE_PROOF'
		const exemptGranted = lastVaccItem?.result === 'EXEMPT_APPROVED'
		setExemptionGranted(exemptGranted)
		setExemptionPendingOrInfo(exemptPendingOrInfo)
		const fsComplete = (fluShotResultsList?.findIndex(item => item.result === 'FLU_SHOT_MISSING') === -1)
		setIsFluShotCompleted(fsComplete)
		setVaccineCompleted(vaccCompleted)
		setBoosterCompleted(boostCompleted)
		setLastVaccineItem(lastVaccItem)
		const isStudent = ["Current Student"/* , "Non Current Student" */].includes(person.personType)
		// const isStudent = person.personType === "Current Student"
		setShowMerged(!vaccCompleted && (!fsComplete && isStudent))
		setShowFluShotSection((!fsComplete && isStudent) && vaccCompleted)
		setShowCovidSection((fsComplete || !isStudent) && (!vaccCompleted || !boostCompleted))

		// Upload proof button action
		if (lastVaccItem?.result?.slice(0, 15) === 'VACCINE_BOOSTER') {
			if (!(pendingProofs && pendingProofs.length > 0)) {
				setUploadBtnFunction(3)
			} else { // there are pending proofs
				if (shots && shots.length === 1) { // one pending shot
					if (shots[0].status === 'P' && !(twoShotCompanies?.includes(proofList[0].company))) { // 1 pending shot from a 1 shot company
						setUploadBtnFunction(1)
					} else { // 1 pending shot from 2 shot company
						setUploadBtnFunction(2)
					}
				} else { // 2 pending shots
					setUploadBtnFunction(2)
				}
			}
		} else if (!hasPendingRejectOrPartial) { // no pending, rejected or partial proofs
			setUploadBtnFunction(0)
		} else if (hasPartialProof) {
			setUploadBtnFunction(2)
		} else if (hasRejectedProof) {
			if (shots.length === 1) { // only one shot and status is rejected
				setUploadBtnFunction(1)
			} else if (shots.length === 2) { // two shots
				setUploadBtnFunction(2)
			}
		} else if (shots.length === 1) { // one pending shot
			if (shots[0].status === 'P' && !(twoShotCompanies?.includes(proofList[0].company))) { // 1 pending shot from a 1 shot company
				setUploadBtnFunction(1)
			} else { // 1 pending shot from 2 shot company
				setUploadBtnFunction(2)
			}
		} else if (shots.length === 2) {
				// setUploadBtnFunction(3)
				setUploadBtnFunction(2)
		} /* else if (lastVaccItem.result.slice(0, 15) === 'VACCINE_BOOSTER') {
			setUploadBtnFunction(3)
		} */

		if (exemptMoreInfo) {
			setShowExemptMoreInfoBtn(true)
		} else {
			setShowExemptMoreInfoBtn(false)
		}

	}, [vaccineResultsList, fluShotResultsList, person.personType, vaccineCompanies, vaccineStatus, pendingProofs])

	useEffect(() => {
		console.log('vaccineCompleted', vaccineCompleted)
		console.log('boosterCompleted', boosterCompleted)
		console.log('showCovidSection', showCovidSection)
		console.log('showMerged', showMerged)
		console.log('lastVaccineItem', lastVaccineItem)
	}, [vaccineCompleted, boosterCompleted, lastVaccineItem])

	useEffect(() => {
		switch (isFluShotCompleted) {
			case true:
				setShowUploadFsBtn(false)
				break;
			case false: // Flu Shot Not Completed
				setShowUploadFsBtn(true)
				break;
			default:
				break;
		}
		switch (vaccineCompleted) {
			case true:
				if (exemptionGranted || !boosterCompleted) {
					if (lastVaccineItem?.result !== 'VACCINE_BOOSTER_PENDING') {
						setShowUploadBtn(true)
					} else {
						setShowUploadBtn(false)
					}
				} else {
					setShowUploadBtn(false)
				}
				setShowReqExemptBtn(false)
				break;
			case false:
				if (!(pendingProofs && pendingProofs.length > 0) && (lastVaccineItem?.result !== 'VACCINE_BOOSTER_PENDING')) {
					setShowUploadBtn(true)
				} else {
					setShowUploadBtn(false)
				}
				if (exemptionGranted || exemptionPendingOrInfo || (pendingProofs && pendingProofs.length > 0)) {
					setShowReqExemptBtn(false)
				} else {
					setShowReqExemptBtn(true)
				}
				break;
			default:
				break;
		}
	}, [isFluShotCompleted, vaccineCompleted, exemptionGranted, exemptionPendingOrInfo])

	useEffect(() => {
		if (reloadVaccineData) {
			var vaccinePromise = dataController.fetchVaccineStatus()
		var vaccineResultsListPromise = dataController.fetchVaccines()
		Promise.all([
			vaccinePromise,
			vaccineResultsListPromise,
		])
		.then(([vaccineResponse, vaccineResultsListResponse]) => {
			setVaccineStatus(vaccineResponse.status)
			setVaccineResultsList(vaccineResultsListResponse.items)
			setReloadVaccineData(false)
		})
		.catch(err => console.log(err))
	}
	}, [reloadVaccineData, setReloadVaccineData, setVaccineResultsList, setVaccineStatus])

	useEffect(() => {
		if (reloadTestResults) {
			dataController.fetchFluShots()
			.then(data => {
				setFluShotResultsList(data.items)
				setReloadTestResults(false)
			})
			.catch(err => console.log(err))
	}
	}, [reloadTestResults, setFluShotResultsList, setReloadTestResults])

	const selectedVaccineProof = (proof_guid: string | any, shot_number: number) => {
		if (shot_number === 1) setVaccineReceived('partially');
		if (shot_number === 2) setVaccineReceived('fully');
		if (shot_number === 3) setVaccineReceived('booster');
		if (proof_guid) {
			const selectedProof = vaccineStatus?.vaccine_proofs.find((proof: any) => proof.proof_guid === proof_guid)

			if (selectedProof) {
				let vaccineStatus = 'N';
				let approverMessage: any = '';
				let approverReasonId: any = null;

				for (let i = 0; i < selectedProof.shots.length; i++) {
					if (selectedProof.shots[i].status === 'R') {
						vaccineStatus = 'R'
						approverMessage = selectedProof.shots[i].approver_message
						approverReasonId = selectedProof.shots[i].approver_reason_id
						break;
					} else if (selectedProof.shots[i].status === 'P') {
						vaccineStatus = 'P'
						break;
					} else {
						vaccineStatus = 'A'
					}
				}

				selectedProof.status = vaccineStatus;
				selectedProof.approver_message = approverMessage;
				selectedProof.approver_reason_id = approverReasonId;

				let company = vaccineCompanies?.find((itm: any) => itm.medical_vaccine_company_guid === selectedProof.company)
				setCurrentVaccineProof(selectedProof)
				setManufacturer(company)
				setActiveDrawer(ActiveDrawer.VaccineProofEdit)
				// if (shot_number === 3) {
				// 	const existingBoosterShot = selectedProof.shots.filter((shot:any) => (shot.is_booster && shot.company !== null))
				// 	if (existingBoosterShot && existingBoosterShot.length > 0) {
				// 		let boosterCompany = vaccineCompanies?.find((itm: any) => itm.medical_vaccine_company_guid === existingBoosterShot[0].company)
				// 		setManufacturer(boosterCompany)
				// 		setActiveDrawer(ActiveDrawer.VaccineProofEdit)
				// 	} else {
				// 		setActiveDrawer(ActiveDrawer.VaccineManufacturer)
				// 	}
				// } else {
				// 	setManufacturer(company)
				// 	setActiveDrawer(ActiveDrawer.VaccineProofEdit)
				// }
			}
		} else if (shot_number === 3) {
			setActiveDrawer(ActiveDrawer.VaccineManufacturer)
		}
	}

	//#region showQueLengths - Not used currently
	// const showQueueLengths = (): boolean => {
	// 	const medicalRequired = StatusHelper.getRequirement(statusModel, RequirementIds.medical)

	// 	if (!medicalRequired) {
	// 		return false
	// 	}

	// 	return features['covid19.pass.medical_queue'] && medicalRequired.required
	// }
	//#endregion

	//#region Redundant unused methods - Commented Out
	// const getVaccineIcon = (idx: number, status: string) => {
	// 	if (idx == 0) {
	// 		if (status === 'incomplete') {
	// 			return 'circle'
	// 		} else {
	// 			return 'check-circle'
	// 		}
	// 	} else if (idx == 1) {
	// 		if (status === 'incomplete') {
	// 			return 'shield'
	// 		} else {
	// 			return 'shield-check'
	// 		}
	// 	}

	// 	return ''
	// }

	// const getAppointmentDateText = (text: string): string => {
	// 	if (appointment?.vaccine_schedule) {
	// 		text = text.replace('{date}', displayDateTime(appointment?.vaccine_schedule.start_datetime))
	// 	}

	// 	return text
	// }

	// const getInviteDateText = (text: string): string => {
	// 	if (standbyStatus?.start_datetime) {
	// 		text = text.replace('{date}', displayDateTime(standbyStatus?.start_datetime))
	// 	}

	// 	return text
	// }

	// const getLinkText = (text: string): string => {
	// 	text = text.replace(
	// 		'covid_testing@med.mit.edu',
	// 		"<a href='mailto:covid_testing@mit.edu'>covid_testing@med.mit.edu</a>"
	// 	)

	// 	return text
	// }

	// const renderBarCode = () => {
	// 	if (vaccineStatus?.status === 'completed') {
	// 		return <></>
	// 	}

	// 	return <Barcode />
	// }

	// const getVaccineInProgress = () => {
	// 	if (vaccineStatus?.status === 'in_progress') {
	// 		return (
	// 			<>
	// 				<Spacer />
	// 				<div className="text-center row">
	// 					<div className="col-4 mx-auto">
	// 						{vaccineStatus?.course_progress.map((itm: any, idx: any) => (
	// 							<>
	// 								<div className="steps ">
	// 									<div className="steps-icon">
	// 										<Icon key={idx} type={'solid'} icon={getVaccineIcon(idx, itm.status)} hideAriaLabel={true} />
	// 									</div>
	// 									{itm.shot_date && moment(itm.shot_date).format('MMM D')}
	// 								</div>
	// 								{idx + 1 !== vaccineStatus?.course_progress.length && <span> - </span>}
	// 							</>
	// 						))}
	// 					</div>
	// 				</div>
	// 				<Spacer size="3" />
	// 			</>
	// 		)
	// 	}

	// 	return <></>
	// }

	// const deleteAppointment = () => {
	// 	if (profile?.mit_id) {
	// 		setIsDeleteing(true)
	// 		vaccineController.deleteAppointment(profile?.mit_id, appointment).then(() => {
	// 			dataController.fetchVaccineStatus().then((vaccineStatusResponse: VaccineStatusResponse) => {
	// 				setVaccineStatus(vaccineStatusResponse.status)
	// 				setIsLoading(false)
	// 				setIsDeleteing(false)
	// 				setActiveDrawer(ActiveDrawer.None)
	// 			})
	// 		})
	// 	}
	// }

	// const saveStandBy = (accept: boolean) => {
	// 	if (!standbyData?.vaccine_standby_guid) {
	// 		return
	// 	}

	// 	setIsLoading(true)

	// 	vaccineController.postStandbyInvite(standbyData?.vaccine_standby_guid, accept).then((result: any) => {
	// 		if (result.success) {
	// 			dataController.fetchVaccineStatus().then((vaccineStatusResponse: VaccineStatusResponse) => {
	// 				setVaccineStatus(vaccineStatusResponse.status)
	// 				setIsLoading(false)
	// 				setActiveDrawer(ActiveDrawer.None)
	// 			})
	// 		} else {
	// 			setIsLoading(false)
	// 		}
	// 	})
	// }
	//#endregion

	//#region Medical Tabs - No longer neccesary after merging covid & flu shot views
	// let medicalTabItems: NavItemProps[] = []

	// if (features['vaccine.enabled']) {
	// 	medicalTabItems = [
	// 		{
	// 			icon: 'shield-check',
	// 			iconStyle: 'light',
	// 			text: 'Vaccination',
	// 			iconOnly: false,
	// 			onClick: () => {
	// 				setTabState(0)
	// 			},
	// 			state: tabState === 0 ? NavItemState.Active : NavItemState.None
	// 		},
	// 		{
	// 			icon: 'syringe',
	// 			iconStyle: 'light',
	// 			text: 'Flu shot',
	// 			iconOnly: false,
	// 			onClick: () => {
	// 				setTabState(1)
	// 			},
	// 			state: tabState === 1 ? NavItemState.Active : NavItemState.None
	// 		}
	// 	]
	// } else {
	// 	medicalTabItems = [
	// 		{
	// 			icon: 'syringe',
	// 			iconStyle: 'light',
	// 			text: 'Flu shot',
	// 			iconOnly: false,
	// 			onClick: () => {
	// 				setTabState(1)
	// 			},
	// 			state: tabState === 1 ? NavItemState.Active : NavItemState.None
	// 		}
	// 	]
	// }
	//#endregion

	return (
		<>
			{/* <ReactLoader loaded={!isLoading} options={mediumLoaderOptions}>
				<div className="lifted-nav">
					<NavBar itemAlignment={1} type={NavBarTypes.IconText} tabs={medicalTabItems} />
				</div>
			</ReactLoader> */}
			<>
				<div className={`submitter-view`}>
					<div className={'nav-container'}>
						<div className={`main-content light-green`}>
							<div>
								<div className={'test-landing'}>
									<div className={'content-page smallPaddingTop white-content max-width-content text-align-center'}>
										<>
											{showFluShotSection && <FluShotSection />}
											{showCovidSection && (
												<CovidSection isLoading={isLoading} setIsLoading={setIsLoading} />
											)}
											{showMerged && (
												<StatusTextBlock
													icon="calendar-day"
													heading={appText[COVID_TEXTS['covid.vaccine.general.noflu.novaccine.heading']]}
													text={appText[COVID_TEXTS['covid.vaccine.general.noflu.novaccine.text']]}
													iconColor="red"
												/>
											)}
											{!showMerged && !showFluShotSection && !showCovidSection && (
												<StatusTextBlock
												icon='shield-check'
												iconLight
												heading={appText[COVID_TEXTS['covid.vaccine.general.flu.vaccine.heading']]}
												iconColor='#339CA2'
												text={appText[COVID_TEXTS['covid.vaccine.general.flu.vaccine.text']].replace('{date}', formatDate(vaccineStatus?.booster.due_date))}
											/>
											)}
											<>
												<Spacer size='2'/>
												{showExemptMoreInfoBtn && features['vaccine.exemption.enabled'] &&
												<div className="m-2 text-center">
													<Button
														type={ButtonType.Primary}
														customBackgroundColor='#FFF'
														customBorderColor='#175c5f'
														outlined={true}
														text={`View request`}
														// icon={'syringe'}
														onClick={() => setActiveDrawer(ActiveDrawer.ReqExemption)}
													/>
												</div>}
												{showUploadBtn &&
												<div className="m-2 text-center">
													<Button
														type={ButtonType.Primary}
														text={uploadBtnFunction === 3? `Upload covid vaccine booster`:` Upload covid vaccine proof`}
														icon={'cloud-upload'}
														onClick={() => {
															if (uploadBtnFunction === 0) {
																setShowFullOrPartialModal(true)
															} else if (pendingProofs && pendingProofs.length > 0) {
																selectedVaccineProof(pendingProofs[0].test_guid, uploadBtnFunction)
															} else if (rejectedProofs && rejectedProofs.length > 0) {
																selectedVaccineProof(rejectedProofs[0].test_guid, uploadBtnFunction)
															} else if (partialProofs && partialProofs.length > 0) {
																selectedVaccineProof(partialProofs[0].test_guid, uploadBtnFunction)
															} else if (uploadBtnFunction === 3) {
																const existingBooster = vaccineStatus?.vaccine_proofs.find((proof: VaccineProofInterface) => proof.type === 2)
																selectedVaccineProof(existingBooster? existingBooster.proof_guid: undefined, 3)
															}
															// } else if (uploadBtnFunction === 3) {
															// 	selectedVaccineProof(vaccineStatus?.vaccine_proofs[0].proof_guid, 3)
															// }
														}
														}
													/>
												</div>}
												{/* {showReqExemptBtn && features['vaccine.exemption.enabled'] && */}
												{features['vaccine.exemption.enabled'] &&
												<div className="m-2 text-center">
													<Button
														type={ButtonType.TextNaked}
														text={`Request Exemption`}
														// icon={'cloud-upload'}
														onClick={() => {
															setActiveDrawer(ActiveDrawer.ReqExemption)
															setReqExemptBtnClicked(true)
														}} // Exemption flow Drawer
													/>
												</div>}
												{showUploadFsBtn &&
												<div className="m-2 text-center">
													<Button
														type={ButtonType.Secondary}
														outlined={true}
														text={` Upload external flu shot`}
														icon={'syringe'}
														onClick={() => setActiveDrawer(ActiveDrawer.FlushotProofEdit)}
														// onClick={() => setShowNoVaccineView(true)}
													/>
												</div>}
											</>
											<CompactHistory selectedVaccineProof={selectedVaccineProof} setShowFullOrPartialModal={setShowFullOrPartialModal} uploadBtnFunction={uploadBtnFunction}/>
											{/* Upload & Req Exempt buttons */}
											{/* {tabState == 0 && renderVaccine()}
											{tabState == 1 && renderFluShot()} */}
										</>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>

			<Drawer
				contentAriaLabel={`Full Vaccine History`}
				layout={DrawerLayout.Hero}
				size={DrawerSize.Medium}
				themedColor={'medical'}
				header={<Text type={TextType.Heading3} content={'Vaccination History'} />}
				type={DrawerType.Right}
				show={activeDrawer === ActiveDrawer.FullHistory}
				contents={
					<StoreProvider store={store}>
						<FullHistory selectedVaccineProof={selectedVaccineProof} setShowFullOrPartialModal={setShowFullOrPartialModal} uploadBtnFunction={uploadBtnFunction}/>
					</StoreProvider>
				}
				footer={[]}
				onClose={() => setActiveDrawer(ActiveDrawer.None)}
			/>

			<TemplateModal
				body={
					<>
						<Form>
							{(methods: any) => {
								return (
									<FormContainer
										methods={methods}
										isValid={(e: any) => console.log(e)}
										formValidationTrigger="onChange"
										submitText="Request"
										id="TestForm"
									>
										{isSuccess === 0 && (
											<ReactLoader loaded={!isLoading} options={mediumLoaderOptions}>
												<FormField
													id="comment"
													editor="multilinetextbox"
													label={'Symptoms'}
													labelDisplay="nextto"
													registerObject={{
														required: true
													}}
												/>
												<Button
													icon="check"
													type={ButtonType.Primary}
													text="Save"
													isFormSubmitButton={true}
													onClick={() => {
														if (methods.trigger() && Object.entries(methods.errors).length === 0) {
															saveData(methods.getValues())
														}
													}}
												/>
											</ReactLoader>
										)}

										<Spacer />
										{isSuccess === 1 && (
											<Alert
												text="Thank you for reporting your symptoms, MIT Medical has been notified."
												type="success"
											/>
										)}
										{isSuccess === -1 && <Alert text="An error occurred. Please try again later." type="error" />}
									</FormContainer>
								)
							}}
						</Form>
					</>
				}
				show={isShowingPopup}
				onClose={() => {
					setIsShowingPopup(false)
					setIsSuccess(0)
				}}
				bodyAlignment={TemplateModalAlignment.Left}
				header={appText[COVID_TEXTS['covid19.vaccine.completed.action']] ?? 'Report side effects'}
				size={TemplateModalSize.Default}
				imageUrl=""
				theme="medical-dark"
				footer={<div></div>}
				name="symptoms"
			/>
			<TemplateModal
				body={
					<div className="text-center">
						<Button
							// icon="check"
							type={ButtonType.Primary}
							text="Fully"
							onClick={() => {
								setVaccineReceived('fully')
								setActiveDrawer(ActiveDrawer.VaccineManufacturer)
								setShowFullOrPartialModal(false)
							}}
						/>
						&nbsp;
						<Button
							// icon="check"
							type={ButtonType.Primary}
							text="Partially"
							onClick={() => {
								setVaccineReceived('partially')
								setActiveDrawer(ActiveDrawer.VaccineManufacturer)
								setShowFullOrPartialModal(false)
							}}
						/>
					</div>
				}
				bodyAlignment={TemplateModalAlignment.Left}
				show={showFullOrPartialModal}
				onClose={() => setShowFullOrPartialModal(false)}
				header={<Text content={'Are you fully or partially vaccinated?'} type={TextType.Heading4} />}
				size={TemplateModalSize.Default}
				imageUrl=""
				footer={<div></div>}
				name="full of partiall vaccination state"
			/>
		</>
	)
}

const Vaccine = withRouter(VaccineComponent)
export default Vaccine
