import { VaccineSymptomModel } from '../../types/VaccineSymptomModel'
import { AuthProvider } from '../AuthProvider'
import BaseController from './BaseController'
import { VaccineTypeResponse } from './VaccineController'

export interface FetchPersonRelationshipsResponse {
	items?: RelationshipInterface[]
	error?: any
	unauth?: any
}

export interface RelationshipInterface {
	person_relationship_type_id: number
	name: string
}

export interface FetchHousingPersonResponse {
	items?: PersonInterface[]
	error?: any
	unauth?: any
}

export interface PersonInterface {
	person_guid: string
	mit_id: string | null
	main_person_mit_id: string
	main_person_guid: string
	is_main_person: boolean
	relationship_type_id: number
	relationship_type_name: string
	first_name: string
	middle_name: string
	last_name: string
	best_phone: string
	email_address: string
	date_of_birth: string
	address1: string
	address2: string
	city: string
	state: string
	country: string
	job_role_type_id: number
	job_role_type_name: string
	postal_code: string
	last_test: string
	is_housing_checked_in: boolean
	data_update_in_progress: string
}

export interface PersonAws {
    mitid: string;
    kerberos_id: string;
    first_name: string;
    last_name: string;
    display_name: string;
}

export interface FetchPeopleResponseAws {
    people?: PersonAutocomplete[];
    error?: any;
}

export interface PersonAutocomplete {
    //id: number;
    label: string;
    mitId: string;
    kerberosId: string;
    firstName: string;
    lastName: string;
    displayName: string;
}

export const peopleResponseConverterAws = (people: PersonAws[]): PersonAutocomplete[] => {

    return people.map((awsPerson: PersonAws, index:number) => ({
        id: index + 1,
        label: `${awsPerson.display_name} (${awsPerson.kerberos_id})`,
        kerberosId: awsPerson.kerberos_id,
        mitId: awsPerson.mitid,
        firstName: awsPerson.first_name,
        lastName: awsPerson.last_name,
        displayName: awsPerson.display_name,

    }))
}

class PersonController extends BaseController {
	readonly url_people: string;
	readonly url_fetch_person: string
	readonly url_fetch_person_relationships: string
	readonly url_fetch_person_jobs: string

	constructor() {
		super()

		this.url_people = `${this.apiHost}/${this.apiPathDigitalId}/search?q=`
		this.url_fetch_person = `${this.apiHost}/${this.apiPathCovid}/person`
		this.url_fetch_person_relationships = `${this.apiHost}/${this.apiPathCovid}/person/relationship_type`
		this.url_fetch_person_jobs = `${this.apiHost}/${this.apiPathCovid}/person/job_role_type`
	}

	checkStatusCode(status: number): boolean {
		return status >= 200 && status < 300
	}

	fetchPeopleAws = async (query: string): Promise<FetchPeopleResponseAws> => {
        let response = await fetch(this.url_people + query, await this.requestHeaderGet())
        if (response.status === 200) {
            let people = await response.json()
            if (people) {
                let peopleResults: PersonAutocomplete[] = peopleResponseConverterAws(people)
                return {
                    people: peopleResults
                }
            } else {
                return {
                    error: true
                }
            }
        } else {
            return {
                error: true
            }
        }
    }

	fetchPerson = async (id: string): Promise<FetchHousingPersonResponse> => {
		let response = await this.useFetch('GET', 'fetchPerson', this.url_fetch_person + '?relationship_source=prescreening&id=' + id)
		if (this.checkStatusCode(response.status)) {
			let data = await response.json()
			return {
				error: false,
				items: data
			}
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true
			}
		} else {
			return {
				error: true
			}
		}
	}

	fetchRelationships = async (): Promise<FetchPersonRelationshipsResponse> => {
		let response = await this.useFetch('GET', 'fetchRelationship', this.url_fetch_person_relationships + '?relationship_source=prescreening')
		if (this.checkStatusCode(response.status)) {
			let data = await response.json()
			return {
				error: false,
				items: data
			}
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true
			}
		} else {
			return {
				error: true
			}
		}
	}

	fetchJobType = async (): Promise<VaccineTypeResponse> => {
		let response = await this.useFetch('GET', 'fetchJob', this.url_fetch_person_jobs)
		if (this.checkStatusCode(response.status)) {
			let data = await response.json()
			return {
				error: false,
				data: data
			}
		} else {
			return {
				error: true
			}
		}
	}

	async createPerson(body: any): Promise<any> {
		let response = await this.useFetch('POST', 'createHousingPerson', this.url_fetch_person + '?relationship_source=prescreening', body)
		if (this.checkStatusCode(response.status)) {
			let data = await response.json()
			return {
				error: false,
				items: data
			}
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true
			}
		} else {
			return {
				error: true
			}
		}
	}

	async updatePerson(body: any): Promise<any> {
		let response = await this.useFetch('PUT', 'updateHousingPerson', this.url_fetch_person + '?relationship_source=prescreening', body)
		if (this.checkStatusCode(response.status)) {
			let data = await response
			return {
				error: false,
				items: data
			}
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true
			}
		} else {
			return {
				error: true
			}
		}
	}

	async deletePerson(body: any): Promise<any> {
		let response = await this.useFetch('DELETE', 'deleteHousingPerson', this.url_fetch_person + '?relationship_source=prescreening', body)
		if (this.checkStatusCode(response.status)) {
			return {
				error: false,
				message: 'Item deleted successfully'
			}
		} else if (response.status === 507 || response.status === 403) {
			return {
				unauth: true
			}
		} else {
			return {
				error: true
			}
		}
	}

	postVaccineSymptoms = async (symptoms: VaccineSymptomModel, kerbId?: string): Promise<any> => {
		let token = await new AuthProvider().getToken()

		const headerInit: any = {
			Authorization: 'Bearer ' + token.token,
			'Content-Type': 'application/json'
		}

		//if (kerbId && kerbId != "") {
		//	headerInit["X-Impersonated-User"] = kerbId;
		//}

		const options: RequestInit = {
			method: 'POST',
			headers: new Headers(headerInit),
			body: JSON.stringify(symptoms),
			mode: 'cors'
		}

		let response = await fetch(`/sideeffect`, options)
		// let response = await fetch('' + `/sideeffect`, options)

		if (response.status === 200) {
			// let data = await response.json()
			return {
				success: true
			}
		} else {
			return {
				error: true
			}
		}
	}
}

const personController = new PersonController()
export default personController
