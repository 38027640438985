import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ButtonBar, Text, TemplateModal, TemplateModalAlignment, TemplateModalSize, TextType } from '@mit/hui'
import 'react-image-crop/dist/ReactCrop.css'
import ReactCrop from 'react-image-crop'
import { useStoreState } from '../store/hooks'

interface Props {
  showCropModal: boolean
  uploadImage: any
  onCloseCropModal: () => void
  handleCropImage: (blob: Blob, previewUrl: string) => void
}

const defaultCrop = { unit: '%', width: 65, aspect: 16 / 9 }

export const ImageCrop: React.FC<Props> = ({ showCropModal, uploadImage, onCloseCropModal, handleCropImage }) => {
  const [crop, setCrop] = useState<any>({ ...defaultCrop })
  const [completedCrop, setCompletedCrop] = useState<any>({ ...defaultCrop })
  const previewCanvasRef = useRef<any>(null)
  const imgRef = useRef<any>(null)
  const { mobile } = useStoreState(store => store.globalModel)

  const onCropImage = (): void => {
    const canvas = previewCanvasRef.current

    canvas.toBlob(
      (blob: any) => {
        const previewUrl = window.URL.createObjectURL(blob)
        handleCropImage(blob, previewUrl)
      },
      'image/*',
      1
    )
  }

  const cropSelect = (c: ReactCrop.Crop) => {
    delete c.aspect
    setCrop(c)
  }

  const onLoad = useCallback(img => {
    imgRef.current = img
    setCrop({ ...defaultCrop })
    return false
  }, [])

  useEffect(() => {
    // eslint-disable-next-line
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = completedCrop

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')
    const pixelRatio = window.devicePixelRatio

    canvas.width = crop.width * pixelRatio
    canvas.height = crop.height * pixelRatio

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height)
  }, [completedCrop])
  return (
    <>
      <TemplateModal
        name='pictureCropper'
        show={showCropModal}
        autoHeight
        // onClose={() => onCloseCropModal()}
        bodyAlignment={TemplateModalAlignment.Center}
        size={TemplateModalSize.Large}
        header={<Text content={'Crop image'} type={TextType.Heading2} />}
        body={
          <>
            <div className='crop-description text-left'>
              {/* <h2>Crop image</h2> */}
              <ol>
                <li>
                  <b>Pull the corners</b> of the selection to the desired size. Should the selection dissappear, simply drag and select an area on the
                  canvas.
                </li>
                <li>
                  <b>Move the selection area</b> to cover the area of the photo at the ideal position.
                </li>
                <li>
                  Press <b>Crop Image</b> at the bottom right.
                </li>
              </ol>
            </div>
            <ReactCrop
              src={uploadImage}
              onImageLoaded={onLoad}
              keepSelection={true}
              // maxHeight={window.screen.height * 0.5}
              crop={crop}
              onChange={c => cropSelect(c)}
              onComplete={c => setCompletedCrop(c)}
							imageStyle={{maxHeight: '65vh'}}
            />
          </>
        }
        footer={
          <>
            <ButtonBar buttons={[{ icon: 'crop', text: 'Crop Image', onClick: onCropImage }]} />
            {/* <div className="mobile-only" style={{paddingBottom: '3rem', width: '100%'}} /> */}
            {mobile && ( <div style={{paddingBottom: '3rem', width: '100%'}} /> )}
          </>
        }
      />
      <div className='ImageCrop' style={{ display: 'none' }}>
        <canvas
          ref={previewCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          style={{
            width: Math.round(completedCrop?.width ?? 0),
            height: Math.round(completedCrop?.height ?? 0)
          }}
        />
      </div>
    </>
  )
}
