import React, { useEffect, useState } from 'react'
import { Profile, DropdownItemProps, DropDownState } from '@mit/hui'

import { AuthProvider } from '../../api/AuthProvider'
import profileController from '../../api/controller/ProfileController'
import { Profile as ProfileType } from '../../types'
import { useStoreActions } from '../../store/hooks'

const authProvider = new AuthProvider()
interface Props {
	placeholder?: any
}

export const LoggedInUser: React.FC<Props> = () => {
	const [profile, setProfile] = useState<ProfileType>({
		department: '',
		display_name: '',
		email: '',
		first_name: '',
		initials: '',
		kerberos: '',
		kerberos_id: '',
		krb_name: '',
		last_name: '',
		mit_id: '',
		mitid: '',
		office_location: '',
		phone_number: '',
		source: ''
	})
	const [profilePicture, setPicture] = useState<any>(null)
	const { setProfile: setStoreProfile, setProfilePicture } = useStoreActions(actions => actions.globalModel)

	const subMenuData: DropdownItemProps[] = [
		{
			icon: 'sign-out',
			text: 'Logout',
			state: DropDownState.None,
			onClick: () => {
				authProvider.logout().then(data => {})
			}
		}
	]

	useEffect(() => {
		if (!profile.display_name) {
			profileController.getProfile().then(data => {
				setProfile(data)
				setStoreProfile(data)
			})
		}
		if (!profilePicture) {
			profileController.getProfilePicture().then(data => {
				setPicture(data.value);
				setProfilePicture(data.value)
			})
		}
		//eslint-disable-next-line
	}, [])

	return (
		<Profile
			name={profile?.display_name}
			subtitle={profile?.department}
			imageUrl={profilePicture}
			submenu={subMenuData}
		/>
	)
}
