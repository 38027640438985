import * as React from 'react'
import { FC, useEffect } from 'react'
import { useState } from 'react'
// import { Row } from 'react-bootstrap'
import 'react-day-picker/lib/style.css'
import { PageError } from '../../types/PageErrors'
// import ReactLoader from 'react-loader'
import moment from 'moment'
import { COVID_TEXTS } from '../../common/Texts'
import { ActionList, ListItemProps, ActionListType, Text, Toggle, Spacer, TextType, ButtonType, Button, ButtonState } from '@mit/hui'
import vaccineController, {
	VaccineAppointmentInterface,
	VaccineAppointmentResponse,
	VaccineScheduleDateResponse,
	VaccineScheduleInterface,
	VaccineScheduleResponse,
	VaccineStandbyResponse
} from '../../api/controller/VaccineController'
import { useStoreState, useStoreActions } from '../../store/hooks'
import { ActiveDrawer } from '../../store/models/global'
import dataController, { VaccineStatusResponse } from '../../api/controller/DataController'

export interface AppointmentProps {}

const AppointmentComponent: FC<AppointmentProps> = () => {
	const [isLoading, setIsLoading] = useState(true)
	const [isBusy, setIsBusy] = useState(false)
	const [pageError, setPageError] = useState<PageError>({ error: false, errorText: '', showRetry: false })
	const { appText } = useStoreState(store => store.globalModel)
	const { setActiveDrawer, setVaccineStatus } = useStoreActions(store => store.globalModel)

	const [schedules, setSchedules] = useState<ListItemProps[]>([])
	const [times, setTimes] = useState<ListItemProps[]>([])
	const [showTimes, setShowTimes] = useState<boolean>(false)
	const [dateText, setDateText] = useState<string>('')
	const [standby, setStandby] = useState<boolean>(false)
	const [appointment, setAppointment] = useState<VaccineAppointmentInterface>()
	const [newAppointment, setNewAppointment] = useState<VaccineAppointmentInterface>()
	const [vaccineStandbyGuid, setVaccineStandbyGuid] = useState<string>('')

	useEffect(() => {
		const buildDates = (
			appointment: VaccineAppointmentInterface | undefined,
			resp: VaccineScheduleDateResponse
		): ListItemProps[] => {
			if (!resp || !resp.schedules) return []

			var listItems = resp.schedules.map((itm: any) => {
				let date = moment(itm.date).format('dddd, ll')

				var listItem: ListItemProps = {
					text: date,
					onClick: () => {
						setDateText(date)
						buildTimes(appointment, itm.date)
					},
					icon: itm.selected ? 'check' : ''
				}

				return listItem
			})

			return listItems
		}
		vaccineController.fetchScheduleDates().then((scheduleResponse: VaccineScheduleDateResponse) => {
			if (scheduleResponse && scheduleResponse.schedules && !scheduleResponse.error) {
				vaccineController.fetchAppointments().then((appointmentResponse: VaccineAppointmentResponse) => {
					let appointment: VaccineAppointmentInterface | undefined
					setIsLoading(false)

					if (
						appointmentResponse &&
						appointmentResponse.appointments &&
						appointmentResponse.appointments.length !== 0
					) {
						appointment = appointmentResponse.appointments[0]

						setAppointment(appointment)

						let newAppointment: any = {
							active: true,
							vaccine_schedule: appointment.vaccine_schedule
						}

						setNewAppointment(newAppointment)
					}

					setSchedules(buildDates(appointment, scheduleResponse))

					getStandby()
				})
			} else {
				setPageError({
					error: true,
					errorText: `An error occurred loading the forms. If the problem persists, please contact ${
						appText[COVID_TEXTS['covid19.pass.help_email']]
					}.`,
					showRetry: false
				})
			}
		})
	}, [appText])

	const getStandby = () => {
		vaccineController.fetchStandby().then((resp: VaccineStandbyResponse) => {
			if (!resp || !resp.standby || resp.standby.length === 0) {
				setStandby(false)
			} else {
				setVaccineStandbyGuid(resp.standby[0].vaccine_standby_guid)
				setStandby(true)
			}
		})
	}

	const buildTimes = (appointment: VaccineAppointmentInterface | undefined, itm: any) => {
		setIsLoading(true)

		vaccineController.fetchSchedules(itm, itm).then((resp: VaccineScheduleResponse) => {
			if (!resp || !resp.schedules) return []

			let times = resp.schedules
				.filter(itm => itm.available_capacity > 0)
				.map((time: VaccineScheduleInterface) => {
					var timeItem: ListItemProps = {
						text: moment(time.start_datetime).format('LT') + ' - ' + moment(time.end_datetime).format('LT'),
						onClick: () => {
							//@ts-ignore
							let appointment: VaccineAppointmentInterface = {
								active: true,
								vaccine_schedule: time
							}

							setNewAppointment(appointment)
						},
						icon: time.selected ? 'check' : ''
					}

					return timeItem
				})

			setIsLoading(false)
			setTimes(times)
			setShowTimes(true)
		})
	}

	const backDateSelection = () => {
		setShowTimes(false);
	}

	const submitAppointment = () => {
		if (!newAppointment) {
			return
		}

		setIsBusy(true)

		vaccineController.postAppointment(newAppointment).then((result: any) => {
			if (result.success) {
				dataController.fetchVaccineStatus().then((vaccineStatusResponse: VaccineStatusResponse) => {
					setVaccineStatus(vaccineStatusResponse.status)

					setIsBusy(false)
					setActiveDrawer(ActiveDrawer.None)
				})
			} else {
				setIsBusy(false)

				setPageError({
					error: true,
					errorText: `An error occurred submitting your consent. If the problem persists, please contact ${
						appText[COVID_TEXTS['covid19.pass.help_email']]
					}.`,
					showRetry: false
				})
			}
		})
	}

	const submitStandby = () => {
		setIsBusy(true)

		vaccineController.postStandby().then((result: any) => {
			if (result.success) {
				setStandby(true);
				setVaccineStandbyGuid(result.data.vaccine_standby_guid)

				dataController.fetchVaccineStatus().then((vaccineStatusResponse: VaccineStatusResponse) => {
					setVaccineStatus(vaccineStatusResponse.status)
					setIsBusy(false)
				})
			} else {
				setIsBusy(false)

				setPageError({
					error: true,
					errorText: `An error occurred submitting your consent. If the problem persists, please contact ${
						appText[COVID_TEXTS['covid19.pass.help_email']]
					}.`,
					showRetry: false
				})
			}
		})
	}

	const deleteStandby = () => {
		setIsBusy(true)

		vaccineController.deleteStandby(vaccineStandbyGuid).then((result: any) => {
			if (result.success) {
				setStandby(false);
				dataController.fetchVaccineStatus().then((vaccineStatusResponse: VaccineStatusResponse) => {
					setVaccineStatus(vaccineStatusResponse.status)

					setIsBusy(false)
					setActiveDrawer(ActiveDrawer.None)
				})
			} else {
				setIsBusy(false)

				setPageError({
					error: true,
					errorText: `An error occurred submitting your consent. If the problem persists, please contact ${
						appText[COVID_TEXTS['covid19.pass.help_email']]
					}.`,
					showRetry: false
				})
			}
		})
	}

	const setStandbyFlag = (data: any) => {
		if (data.choice === 'Positive') {
			submitStandby()
		} else if(data.choice === "Negative") {
			deleteStandby()
		}
	}

	return (
		<>
			{schedules && !showTimes && (
				<>
					<Text content={appText[COVID_TEXTS['covid19.vaccine.appointment.text_date']] ?? "Select one of the available days"} />
					<Spacer />
					<ActionList items={schedules} isLoading={isLoading} type={ActionListType.Flush} />
				</>
			)}

			{times && showTimes && (
				<>
					<Text content={dateText} type={TextType.Heading3} />
					<Text content={appText[COVID_TEXTS['covid19.vaccine.appointment.text_time']]} />
					<Spacer />
					<ActionList items={times} isLoading={isLoading} type={ActionListType.Flush} />
					<Spacer />
					<div style={{display:"flex"}} >
						<Toggle onStateChanged={setStandbyFlag} choice={standby ? 'Positive' : 'Negative'} ariaLabel="standby"/>
						<div className="toggletext" style={{display:"flex",alignItems:"center"}}><Text  content={appText[COVID_TEXTS['covid19.vaccine.standby.text']]} /></div>
					</div>
					<p style={{opacity:"65%"}}>{appText['covid19.vaccine.standby.message'] ?? 'This will ensure that when medical has an open spot, you can be invited perhaps earlier. This is not linked to your appointment.'}</p>

					<div className="btn-padded text-right mt-3">
						<Button
							type={ButtonType.Ghost | ButtonType.Secondary}
							icon="chevron-left"
							text={''}
							onClick={backDateSelection}
							title='back'
						/>
						<Button
							isBusy={isLoading || isBusy}
							state={ isLoading || isBusy ? ButtonState.Disabled : ButtonState.Enabled}
							type={ButtonType.Primary}
							text={appText[COVID_TEXTS['covid19.vaccine.appointment.action']]}
							onClick={submitAppointment}
						/>
					</div>
				</>
			)}
		</>
	)
}

const Appointment = AppointmentComponent
export default Appointment
