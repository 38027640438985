import * as React from 'react'
import { RequirementIds, StatusHelper } from '../../types/CovidStatusModel'
import { useStoreActions, useStoreState } from '../../store/hooks'
import { ActiveDrawer } from '../../store/models/global'
// import PersonTests from '../vaccine/PersonTests'
import Barcode from '../vaccine/Barcode'
import CovidText, { COVID_TEXTS } from '../../common/Texts'
import {
	// Button,
	// TextType,
	// Text,
	// TextArea,
	// Spacer,
	// ButtonType,
	// NavItemProps,
	// NavItemState,
	// NavBar,
	// NavBarTypes,
	// Icon,
	// TemplateModal,
	// Form,
	// FormContainer,
	// FormField,
	// Alert,
	// TemplateModalAlignment,
	// TemplateModalSize,
	// ButtonState,
	// GridContainer,
	// LayoutColumn,
	// Container,
	// ButtonBar,
	// ButtonProps,
	// ButtonBarType,
	ToastContainer,
	ToastContainerProps,
	ToastPosition,
	ListItem
} from '@mit/hui'

// let medicalRequirement = StatusHelper.getRequirement(statusModel, RequirementIds.medical)

// if (!medicalRequirement) {
// 	return
// }

// if (
// 	medicalRequirement.flu_shot_visible ||
// 	(medicalRequirement.flu_shot_required && !medicalRequirement.flu_shot_completed)
// ) {
// 	return (
// 		<>
// 			<div className={'p-4'}>
// 				<h3>{appText['covid19.medical.flushot.header']}</h3>
// 				<h3>{appText['covid19.medical.flushot.title']}</h3>

// 				{features['covid19.medical.flu_shot.title.visible'] && <CovidText textId={COVID_TEXTS['covid19.medical.flushot.text']} />}
// 				<a
// 					href={appText['covid19.medical.flushot.url']}
// 					target={'_blank'}
// 				>{`${appText['covid19.medical.flushot.action1']} ${appText['covid19.medical.flushot.action2']}`} <span className="sr-only">, opens in new browser tab or window.</span></a>
// 			</div>
// 			<Spacer />
// 			<hr />
// 		</>
// 	)
// }

const FluShotSection = () => {
	const {
		appText,
		statusModel,
		features,
		/* tabState,  */
		// vaccineStatus,
		// profile,
		// vaccineCompanies,
		// reason,
		// reloadVaccineData,
		// vaccineReceived,
		// mobile,
		isFluShotProofUploaded
	} = useStoreState(store => store.globalModel)
	const {
		setActiveDrawer,
		// setTabState,
		// setVaccineStatus,
		// setVaccineReceived,
		// setCurrentVaccineProof,
		// setVaccineProofList,
		// setManufacturer,
		// setReasons,
		// setReloadVaccineData,
		// setPreScreeningStepperIndex,
		setFluShotProofUploaded
	} = useStoreActions(store => store.globalModel)

	const medicalRequirement = StatusHelper.getRequirement(statusModel, RequirementIds.medical)

	const fluShotToastListData: ToastContainerProps = {
		position: ToastPosition.TopCenter,
		toasts: [
			{
				icon: {
					type: 'light',
					icon: 'check',
					padded: false
				},
				title: '',
				subtext: '',
				message: 'Your flu shot information was uploaded successfully',
				show: isFluShotProofUploaded,
				onClose: () => setFluShotProofUploaded(false)
			}
		]
	}

	const renderFluShotHeader = () => {
		const medicalRequirement = StatusHelper.getRequirement(statusModel, RequirementIds.medical)
		return (
			<>
				<div className={'p-4'}>
					<h3 className="text-center mb-4">{appText['covid19.medical.flushot.header']}</h3>
					{/* <h3>{appText['covid19.medical.flushot.title']}</h3> */}

					{medicalRequirement && medicalRequirement.flu_shot_required ? (
						features['covid19.medical.flu_shot.title.visible'] && (
							<CovidText textId={COVID_TEXTS['covid19.medical.flushot.text']} />
						) // Student & FS Required
					) : (
						<CovidText textId={COVID_TEXTS['covid19.medical.flushot.not.required.text']} />
					) // FS Not Required
					}

					<a href={appText['covid19.medical.flushot.url']} target={'_blank'} rel="noopener noreferrer">
						{`${appText['covid19.medical.flushot.action1']} ${appText['covid19.medical.flushot.action2']}`}{' '}
						<span className="sr-only">, opens in new browser tab or window.</span>
					</a>
				</div>
				{/* <Spacer />
				<hr /> */}
			</>
		)
	}

	return (
		<>
			<div className="row justify-content-center">
				<div className="col-md-8">{renderFluShotHeader()}</div>
				{/* {showQueueLengths() && <QueueLengths />} */}
				<ToastContainer {...fluShotToastListData} position={ToastPosition.TopCenter} />
				{medicalRequirement && !medicalRequirement.flu_shot_completed && (
					<div className="col-md-8">
						<div aria-busy="false" role="list" className="list-group">
							<ListItem
								icon="syringe"
								// New text API ?
								text={'Upload external flu shot'}
								className={'custom-item list-group-flush'}
								// actions={[{ icon: 'chevron-right', state: ButtonState.Enabled }]}
								// This needs to change to new Drawer for Flue Vaccine Upload. Currently on Covid Vaccine Upload
								onClick={() => setActiveDrawer(ActiveDrawer.FlushotProofEdit)}
								// onClick={() => setActiveDrawer(ActiveDrawer.VaccineProofEdit)}
							/>
						</div>
						<div className={'smallMarginTop'}>
							<Barcode />
						</div>
					</div>
				)}
				{/* {medicalRequirement && !medicalRequirement.flu_shot_completed &&
				<div className={'smallMarginTop'}>
					<Barcode />
				</div>
			} */}
			</div>
		</>
	)

}

export default FluShotSection
