import * as React from 'react'
// import { useState } from 'react'
// import moment, { now } from 'moment'
// import { withRouter } from 'react-router'
// import PersonTests from './PersonTests'
import { useStoreState, useStoreActions } from '../../store/hooks'
import dataController from '../../api/controller/DataController'
import moment from 'moment'
import { ActiveDrawer } from '../../store/models/global'

import { ActionList, Container, Spacer } from '@mit/hui'
import { COVID_TEXTS } from '../../common/Texts'

type FullHistoryProps = {
	selectedVaccineProof: (proof_guid: string | any, shot_number: number) => void,
	setShowFullOrPartialModal: React.Dispatch<React.SetStateAction<boolean>>,
	uploadBtnFunction: 0 | 1 | 2 | 3
}

const FullHistory = ({selectedVaccineProof, setShowFullOrPartialModal, uploadBtnFunction}: FullHistoryProps) => {
	const { vaccineResultsList, fluShotResultsList, person, vaccineStatus, appText } = useStoreState(store => store.globalModel)
	const { setActiveDrawer } = useStoreActions(store => store.globalModel)
	// const [isDownloading, setDownloading] = useState(false)
	const isStudent = person?.personType === "Current Student"

	const downloadFile = (data: any, filename: any, mime: any) => {
		// It is necessary to create a new blob object with mime-type explicitly set
		// otherwise only Chrome works like it should
		const blob = new Blob([data], { type: mime || 'application/octet-stream' })
		//@ts-ignore
		if (typeof window.navigator.msSaveBlob !== 'undefined') {
			// IE doesn't allow using a blob object directly as link href.
			// Workaround for "HTML7007: One or more blob URLs were
			// revoked by closing the blob for which they were created.
			// These URLs will no longer resolve as the data backing
			// the URL has been freed."
			//@ts-ignore
			window.navigator.msSaveBlob(blob, filename)
			return
		}
		// Other browsers
		// Create a link pointing to the ObjectURL containing the blob
		const blobURL = window.URL.createObjectURL(blob)
		const tempLink = document.createElement('a')
		tempLink.style.display = 'none'
		tempLink.href = blobURL
		tempLink.setAttribute('download', filename)
		// Safari thinks _blank anchor are pop ups. We only want to set _blank
		// target if the browser does not support the HTML5 download attribute.
		// This allows you to download files in desktop safari if pop up blocking
		// is enabled.
		if (typeof tempLink.download === 'undefined') {
			tempLink.setAttribute('target', '_blank')
		}
		document.body.appendChild(tempLink)
		tempLink.click()
		document.body.removeChild(tempLink)
		setTimeout(() => {
			// For Firefox it is necessary to delay revoking the ObjectURL
			window.URL.revokeObjectURL(blobURL)
		}, 100)
	}

	const downloadClicked = (proof_guid: string, flushot: boolean, vaccine?: boolean) => {
		// setDownloading(true)
		dataController.fetchMedicalResultPDF(false, false, true, proof_guid).then(resp => {
			// setDownloading(false)
			downloadFile(resp.value, `vaccine_proof_${moment().format('YYYY-MM-DD')}.pdf`, 'application/pdf')
		})
	}

	const formatDate = (date: string | undefined) => {
		if (date) {
			return moment(date)
				.utc()
				.format('LL')
			// return moment(date).format('MMM DD, YYYY')
		}
		return ''
	}

	// Possible results:
	// FLU_SHOT_MISSING
	// VACCINE_MISSING
	// FLU_SHOT_PROOF
	// VACCINE_PROOF
	// VACCINE_MISSING
	// VACCINE_PROOF_PARTIAL
	// VACCINE_PROOF_PENDING
	// EXEMPT_PENDING
	// EXEMPT_REJECTED
	// VACCINE_PROOF_REJECTED
	// EXEMPT_APPROVED
	// EXEMPT_MORE_INFO
	// VACCINE_BOOSTER_MISSING
	// VACCINE_BOOSTER_PENDING
	// VACCINE_BOOSTER_REJECTED
	// VACCINE_BOOSTER_APPROVED

	const getStatusBlockProps = (item: any, canDownload: boolean) => {
		switch (item.result) {
			case 'FLU_SHOT_MISSING':
				if (isStudent)
					return {
						icon: 'calendar-day',
						className: 'vaccine-list-item red',
						text: appText[COVID_TEXTS['covid19.medical.vaccine.flu_shot_title']] || 'Flu Shot',
						// text: 'Flu Shot',
						secondaryText: 'Missing',
						actions: [
							{
								icon: 'chevron-right',
								onClick: () => setActiveDrawer(ActiveDrawer.FlushotProofEdit), // should take to flushot upload proof page
								state: 0,
								text: '',
								type: 32
							}
						]
					}
				else
					return {
						icon: 'check-circle',
						className: 'vaccine-list-item grey',
						// text: 'Flu Shot',
						text: appText[COVID_TEXTS['covid19.medical.vaccine.flu_shot_title']] || 'Flu Shot',
						secondaryText: 'Missing',
						actions: []
					}
			case 'VACCINE_MISSING':
				return {
					icon: 'calendar-day',
					className: 'vaccine-list-item red',
					// text: 'Covid Vaccine',
					text: appText[COVID_TEXTS['covid19.medical.vaccine.vaccine_title']] || `Covid Vaccine`,
					secondaryText: 'Missing',
					actions: [
						{
							icon: 'chevron-right',
							onClick: () => setShowFullOrPartialModal(true), // should take to upload proof page
							state: 0,
							text: '',
							type: 32
						}
					]
				}
			case 'FLU_SHOT':
				return {
					icon: canDownload? 'shield-check': 'check-circle',
					className: `vaccine-list-item ${canDownload? 'green': 'grey'}`,
					text: appText[COVID_TEXTS['covid19.medical.vaccine.flu_shot_title']] || 'Flu Shot',
					// text: 'Flu Shot',
					secondaryText: item.test_date ? formatDate(item.test_date) : null,
					actions: !canDownload? []:[
						{
							icon: 'cloud-download-alt',
							onClick: () => downloadClicked(item.test_guid, true),
							state: 0,
							text: '',
							type: 32
						}
					]
				}
			case 'VACCINE_PROOF':
				return {
					icon: 'shield-check',
					className: 'vaccine-list-item green',
					// text: 'Covid Vaccine',
					text: appText[COVID_TEXTS['covid19.medical.vaccine.vaccine_title']] || `Covid Vaccine`,
					// text: item.test_company,
					secondaryText: item.test_date ? formatDate(item.test_date) : null,
					actions: [
						{
							icon: 'cloud-download-alt',
							onClick: () => downloadClicked(item.test_guid, false, true /* vaccine */),
							state: 0,
							text: '',
							type: 32
						}
					]
				}
			case 'VACCINE_BOOSTER_APPROVED':
				return {
					icon: 'shield-check',
					className: 'vaccine-list-item green',
					// text: 'COVID-19 Booster',
					text: appText[COVID_TEXTS['covid19.medical.vaccine.booster_title']] || 'COVID-19 Booster',
					// text: item.test_company,
					secondaryText: item.test_date ? formatDate(item.test_date) : null,
					actions: [
						{
							icon: 'cloud-download-alt',
							onClick: () => downloadClicked(item.test_guid, false, true /* vaccine */),
							state: 0,
							text: '',
							type: 32
						}
					]
				}
			case 'VACCINE_PROOF_PARTIAL':
				return {
					icon: 'shield',
					className: 'vaccine-list-item green',
					text: appText[COVID_TEXTS['covid19.medical.vaccine.partial_vaccine_title']],
					// text: 'Partial Covid',
					secondaryText: item.test_date ? formatDate(item.test_date) : null,
					actions: [
						{
							icon: 'cloud-download-alt',
							onClick: () => downloadClicked(item.test_guid, false, true /* vaccine */),
							state: 0,
							text: '',
							type: 32
						}
					]
				}
			case 'VACCINE_PROOF_PENDING':
				return {
					icon: 'clock',
					className: 'vaccine-list-item grey',
					text: appText[COVID_TEXTS['covid19.medical.vaccine.vaccine_title']] || `Covid Vaccine`,
					// text: 'Covid Vaccine',
					secondaryText: appText[COVID_TEXTS['covid19.medical.vaccine.secondary_text.pending']] || 'Pending',
					actions: [
						{
							icon: 'chevron-right',
							onClick: () => {
								let shotNumber = 1
								const proofList = vaccineStatus?.vaccine_proofs
								if (proofList[0].shots.length > 1) {
									shotNumber = 2
								}
								selectedVaccineProof(item.test_guid, shotNumber)
								// selectedVaccineProof(lastVaccineItem.test_guid, shotNumber)
							},
							state: 0,
							text: '',
							type: 32
						}
					]
				}
			case 'EXEMPT_PENDING':
				return {
					icon: 'clock',
					className: 'vaccine-list-item grey',
					text: appText[COVID_TEXTS['covid19.medical.vaccine.exemption_title']] || 'Covid Exempt',
					// text: 'Covid Exempt',
					secondaryText: appText[COVID_TEXTS['covid19.medical.vaccine.secondary_text.pending']] || 'Pending',
					actions: [
						{
							icon: 'chevron-right',
							onClick: () => setActiveDrawer(ActiveDrawer.ReqExemption),
							state: 0,
							text: '',
							type: 32
						}
					]
				}
			case 'EXEMPT_REJECTED':
				return {
					icon: 'ban',
					className: 'vaccine-list-item red',
					text: appText[COVID_TEXTS['covid19.medical.vaccine.exemption_title']] || 'Covid Exempt',
					// text: 'Covid Exempt',
					secondaryText: appText[COVID_TEXTS['covid19.medical.vaccine.secondary_text.denied']] || `Denied`,
					actions: [
						{
							icon: 'chevron-right',
							onClick: () => setActiveDrawer(ActiveDrawer.ReqExemption),
							state: 0,
							text: '',
							type: 32
						}
					]
				}
			case 'EXEMPT_MORE_INFO':
				return {
					icon: 'question-circle',
					className: 'vaccine-list-item red',
					text: appText[COVID_TEXTS['covid19.medical.vaccine.exemption_title']] || 'Covid Exempt',
					// text: 'Covid Exempt',
					// secondaryText: item.test_date ? formatDate(item.test_date) : null,
					secondaryText: appText[COVID_TEXTS['covid19.medical.vaccine.secondary_text.information']] || `Information`,
					actions: [
						{
							icon: 'chevron-right',
							onClick: () => setActiveDrawer(ActiveDrawer.ReqExemption),
							state: 0,
							text: '',
							type: 32
						}
					]
				}
			case 'VACCINE_PROOF_REJECTED':
				return {
					icon: 'ban',
					className: 'vaccine-list-item red',
					// text: 'Covid Vaccine',
					text: appText[COVID_TEXTS['covid19.medical.vaccine.vaccine_title']] || `Covid Vaccine`,
					secondaryText: appText[COVID_TEXTS['covid19.medical.vaccine.secondary_text.denied']] || `Denied`,
					actions: [
						{
							icon: 'chevron-right',
							onClick: () => selectedVaccineProof(item.test_guid, uploadBtnFunction),
							state: 0,
							text: '',
							type: 32
						}
					]
				}
			case 'EXEMPT_APPROVED':
				return {
					icon: 'chevron-circle-right',
					className: 'vaccine-list-item green',
					text: appText[COVID_TEXTS['covid19.medical.vaccine.exemption_title']] || 'Covid Exempt',
					// text: 'Covid Exempt',
					secondaryText: item.test_date ? formatDate(item.test_date) : 'Approved',
					actions: [
						{
							icon: 'chevron-right',
							onClick: () => setActiveDrawer(ActiveDrawer.ReqExemption),
							state: 0,
							text: '',
							type: 32
						}
					]
				}
			case 'VACCINE_BOOSTER_MISSING':
				return {
					icon: 'calendar-day',
					className: 'vaccine-list-item grey',
					// text: 'COVID-19 Booster',
					text: appText[COVID_TEXTS['covid19.medical.vaccine.booster_title']] || 'COVID-19 Booster',
					// secondaryText: 'Optional',
					// secondaryText: appText[COVID_TEXTS['covid19.medical.vaccine.secondary_text.optional']] || `Optional`,
					// secondaryText: `Due ${item.test_date ? formatDate(item.test_date) : null}`,
					secondaryText: `Due ${formatDate(vaccineStatus?.booster.due_date)}`,
					actions: [
						{
							icon: 'chevron-right',
							onClick: () => selectedVaccineProof(undefined, 3), // should take to booster upload page
							state: 0,
							text: '',
							type: 32
						}
					]
				}
			case 'VACCINE_BOOSTER_PENDING':
				return {
					icon: 'clock',
					className: 'vaccine-list-item grey',
					// text: 'COVID-19 Booster',
					text: appText[COVID_TEXTS['covid19.medical.vaccine.booster_title']] || 'COVID-19 Booster',
					secondaryText: appText[COVID_TEXTS['covid19.medical.vaccine.secondary_text.pending']] || 'Pending',
					actions: [
						{
							icon: 'chevron-right',
							onClick: () => selectedVaccineProof(item.test_guid, 3), // should take to booster upload page to edit
							state: 0,
							text: '',
							type: 32
						}
					]
				}
			case 'VACCINE_BOOSTER_REJECTED':
				return {
					icon: 'ban',
					className: 'vaccine-list-item red',
					// text: 'COVID-19 Booster',
					text: appText[COVID_TEXTS['covid19.medical.vaccine.booster_title']] || 'COVID-19 Booster',
					secondaryText: appText[COVID_TEXTS['covid19.medical.vaccine.secondary_text.denied']] || `Denied`,
					actions: [
						{
							icon: 'chevron-right',
							// onClick: () => selectedVaccineProof(lastVaccineItem.test_guid, uploadBtnFunction),
							onClick: () => selectedVaccineProof(item.test_guid, 3), // should take to booster upload page to edit
							state: 0,
							text: '',
							type: 32
						}
					]
				}
			default:
				return {
					icon: ' ',
					className: ' ',
					text: ' ',
					secondaryText: ' ',
					actions: []
				}
		}
	}

	let covidHistoryList: any[] = []
	let fluShotHistoryList: any[] = []

	if (vaccineResultsList && vaccineResultsList.length > 0) {
		covidHistoryList = vaccineResultsList.map((item, index) => {
			const canDownload = index === 0
			const blockProps = getStatusBlockProps(item, canDownload)
			// console.log('vaccineData', blockProps)
			return blockProps
		})
	}

	// const proofs = vaccineStatus?.vaccine_proofs
	// let shots = []
	// if (proofs && proofs.length > 0) {
	// 	shots = proofs[0].shots
	// }
	// let boosterApproved = []
	// if (shots && shots.length > 0) {
	// 	boosterApproved = shots.filter((shot: any) => shot.is_booster && shot.status === 'A')
	// }
	// if (boosterApproved && boosterApproved.length > 0) {
	// 	covidHistoryList.push({
	// 		icon: 'shield-check',
	// 		className: 'vaccine-list-item green',
	// 		text: 'COVID-19 Booster',
	// 		secondaryText: boosterApproved[0] ? formatDate(boosterApproved[0].received_on) : null,
	// 		actions: [
	// 			{
	// 				icon: 'cloud-download-alt',
	// 				onClick: () => downloadClicked(proofs[0].proof_guid, false, true /* vaccine */),
	// 				state: 0,
	// 				text: '',
	// 				type: 32
	// 			}
	// 		]
	// 	})
	// }

	if (fluShotResultsList && fluShotResultsList.length > 0) {
		fluShotHistoryList = fluShotResultsList.map((item, index) => {
			// const canDownload = index === 0
			const canDownload = item.can_download
			const blockProps = getStatusBlockProps(item, canDownload)
			// console.log('fluShotData', blockProps)
			return blockProps
		})
	}

	return (
	<div className="row justify-content-center">
		<div className="col-lg-10" style={{textAlign: 'center'}}>
			<>
				<p className="pl-3 text-left mt-5">COVID 19</p>
				<Container
					alignment="left"
					className=""
					contents={<ActionList type="flush" selectable={false} items={covidHistoryList} />}
					// padding={5}
					raised={false}
				/>
			</>
			<Spacer size='3'/>
			<>
				<p className="pl-3 text-left">FLU SHOT</p>
				<Container
					alignment="left"
					className=""
					contents={<ActionList type="flush" selectable={false} items={fluShotHistoryList} />}
					// padding={5}
					raised={false}
				/>
			</>
		</div>
	</div>)
}

export default FullHistory
