import * as React from 'react'
import { FC, useEffect, useState, Fragment } from 'react'
import { PageError } from '../../types/PageErrors'
import ReactLoader from 'react-loader'
import {
	Toggle,
	Button,
	ButtonType,
	Spacer,
	TemplateModalAlignment,
	Text,
	TemplateModal,
	TemplateModalSize,
	TextType,
	ButtonState
} from '@mit/hui'
import vaccineController, {
	VaccineAnswer,
	VaccineAnswerDetail,
	VaccineQuestion,
	VaccineQuestionResponse
} from '../../api/controller/VaccineController'
import { mediumLoaderDarkOptions, mediumLoaderOptions } from '../../common/Defaults'
import { useStoreState, useStoreActions } from '../../store/hooks'
import { COVID_TEXTS } from '../../common/Texts'
import { ActiveDrawer } from '../../store/models/global'
import dataController, { VaccineStatusResponse } from '../../api/controller/DataController'
import { withComponent } from '../../common/WithComponent'

export interface QuestionsProps {}

interface Answers {
	[id: string]: boolean
}

const QuestionsComponent: React.FC<QuestionsProps> = () => {
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [answers, setAnswers] = useState<Answers>({})
	const [allQuestions, setAllQuestions] = useState<VaccineQuestion[]>([])
	const [questions, setQuestions] = useState<VaccineQuestion[]>([])
	const [pageError, setPageError] = useState<PageError>({ error: false, errorText: '', showRetry: false })
	const { appText, profile, vaccineStatus } = useStoreState(store => store.globalModel)
	const { setActiveDrawer, setVaccineStatus } = useStoreActions(store => store.globalModel)
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [validQuestions, setValidQuestions] = useState<boolean>(false)
	const [showConfirmationPopup, setShowConfirmationPopup] = useState<boolean>(false)

	let triggerQuestions = 0

	const onAnswerClick = (id: string, evt: any) => {
		if (evt.SWITCH_STATE.choice !== 'Positive') {
			updateAnswer(id, true)
		} else {
			updateAnswer(id, false)
		}
	}

	useEffect(() => {
		vaccineController.fetchQuestions().then((response: VaccineQuestionResponse) => {
			if (response.questions) {
				setAllQuestions(response.questions)
				filterQuestions(response.questions, 1)
			} else {
				//setPageError({ error: true, errorText: 'An error occurred loading initial questions.' })
			}

			setIsLoading(false)
		})
	}, [])

	useEffect(() => {
		if (currentPage === 1) {
			setValidQuestions(Object.keys(answers).length === questions.length)
		} else if (currentPage === 2) {
			setValidQuestions(
				Object.keys(answers).length === allQuestions.filter(i => !i.follow_up_multiple).length + triggerQuestions
			)
		}
	}, [answers, currentPage, questions, allQuestions, triggerQuestions])

	const filterQuestions = (questions: VaccineQuestion[], page: number) => {
		var localQuestions = questions.filter(itm => itm.page === page)

		setQuestions(localQuestions)
	}

	const updateAnswer = (id: string, newValue: boolean) => {
		let newAnswers = {
			...answers,
			[id]: newValue
		}

		setAnswers(newAnswers)
	}

	const deleteAnswer = (id: string) => {
		let newAnswers = {
			...answers
		}

		delete newAnswers[id]

		setAnswers(newAnswers)
	}

	const renderQuestion = (question: VaccineQuestion, value: boolean, invertColors: boolean = false): JSX.Element => {
		const { id, text } = question

		return (
			<Fragment key={`render-q-${question.id}-${question.page}`}>
				<div className={'single-question'}>
					<div className={'question-text'}>{text}</div>

					<Toggle
						showBothOptions
						inverse={invertColors}
                        ariaLabel="question"
						positiveLabel="no"
						negativeLabel="yes"
						choice={'NotSelected'}
						onClick={(evt: any) => onAnswerClick(id, evt)}
						onStateChanged={() => null}
					/>
				</div>
				{answers[id] &&
					question.follow_up_questions &&
					question.follow_up_questions.map((followUpQ: VaccineQuestion) => {
						return renderQuestion(followUpQ, answers[followUpQ.id], true)
					})}
			</Fragment>
		)
	}

	const checkForTrigger = (triggerFields: string[]) => {
		for (let id of triggerFields) {
			if (answers[id]) return true
		}

		return false
	}

	const renderFollowUpMultipleQuestions = () => {
		return questions.map(question => {
			if (question.follow_up_multiple && checkForTrigger(question.follow_up_trigger)) {
				triggerQuestions = triggerQuestions + 1
				return renderQuestion(question, answers[question.id], true)
			}

			if (
				question.follow_up_multiple &&
				!checkForTrigger(question.follow_up_trigger) &&
				answers[question.id] !== undefined
			) {
				triggerQuestions = triggerQuestions - 1
				return deleteAnswer(question.id)
			}
			return null
		})
	}

	const submitQuestions = (fromPopup: boolean) => {
		setShowConfirmationPopup(false)

		if (!vaccineStatus) {
			return
		}

		const answerDetail: VaccineAnswerDetail[] = Object.keys(answers).map((questionKey: string) => {
			return {
				question_id: questionKey,
				question_answer: answers[questionKey]
			}
		})

		const answer: VaccineAnswer = {
			vaccine_course_guid: vaccineStatus.course_guid,
			answers: answerDetail
		}

		if (currentPage === 1) {
			if (fromPopup) {
				setIsLoading(true)
				vaccineController.postQuestions(answer).then((result: any) => {
					dataController.fetchVaccineStatus().then((vaccineStatusResponse: VaccineStatusResponse) => {
						setVaccineStatus(vaccineStatusResponse.status)
						setIsLoading(false)
						setActiveDrawer(ActiveDrawer.None)
					})
				})
				return
			}

			filterQuestions(allQuestions, 2)
			setCurrentPage(2)

			return
		}

		setIsLoading(true)

		vaccineController.postQuestions(answer).then((result: any) => {
			dataController.fetchVaccineStatus().then((vaccineStatusResponse: VaccineStatusResponse) => {
				setVaccineStatus(vaccineStatusResponse.status)
				setIsLoading(false)

				if (!fromPopup) {
					setActiveDrawer(ActiveDrawer.SuccessPopup)
				} else {
					setActiveDrawer(ActiveDrawer.None)
				}
			})
		})
	}

	// const getFooterText = () => {
	// 	let text = appText[COVID_TEXTS['covid19.vaccine.question1.text']]

	// 	if (currentPage === 2) {
	// 		text = appText[COVID_TEXTS['covid19.vaccine.question2.text']]
	// 	}

	// 	text = text && text.replace('{name}', profile?.display_name)

	// 	return text
	// }

	const getHeaderText = () => {
		let text = appText[COVID_TEXTS['covid19.vaccine.question1.title']]

		if (currentPage === 2) {
			text = appText[COVID_TEXTS['covid19.vaccine.question2.title']]
		}

		return text
	}

	const getModalText = (): string => {
		let text =
			appText[COVID_TEXTS['covid19.vaccine.question1.popup']] ??
			'I {name} agree that because I answered "YES" to some of the questions, I should not have the Moderna Vaccine at this time.'

		if (currentPage === 2) {
			const triggerQuestionId = allQuestions.filter(itm => itm.follow_up_multiple)[0].id
			let triggerQuestionAnswer = answers[triggerQuestionId]

			if (triggerQuestionAnswer !== undefined) {
				if (triggerQuestionAnswer === true) {
					text =
						appText[COVID_TEXTS['covid19.vaccine.question2.popup.yes']] ??
						'You will be submitting that you have answered “YES” to one or more vaccine eligibility questions and you that you have been cleared by an MIT Medical clinician. You should proceed with your Covid-19 Vaccine appointment as scheduled.'
				} else {
					text =
						appText[COVID_TEXTS['covid19.vaccine.question2.popup.no']] ??
						'You will be submitting that you have answered “YES” to one or more vaccine eligibility questions and that you have not been cleared by an MIT Medical clinician. At this time you should not receive the Covid-19 Vaccine.'
				}
			} else {
				text =
					appText[COVID_TEXTS['covid19.vaccine.question2.popup']] ??
					'I {name} agree that I have answered "NO" to some of the questions, or because I have discussed with an MIT Medical clinical team member and have determined that I am a candidate to receive the Moderna Vaccine at this time.'
			}
		}

		text = text.replace('{name}', profile?.display_name)

		return text
	}

	const confirmQuestions = () => {
		const hasTrueQuestions = Object.keys(answers).filter(itm => answers[itm]).length > 0

		setShowConfirmationPopup(hasTrueQuestions)

		if (!hasTrueQuestions) {
			submitQuestions(false)
		}
	}

	const ModalPopup = withComponent(TemplateModal)

	return (
		<>
			{pageError.error && <div>{pageError.errorText}</div>}
			<ReactLoader loaded={!isLoading} options={mediumLoaderDarkOptions}>
				{!pageError.error && (
					<div className={'question-container'}>
						<div className={'symptoms-header'}>
							<p className={'symptoms-header-question'}>
								<Text content={getHeaderText()} />
							</p>
						</div>

						{questions.map((question: VaccineQuestion, index: number) => {
							if (!question.follow_up_multiple) {
								return (
									<Fragment key={`main-q-${question.id}-${index}`}>
										{renderQuestion(question, answers[question.id])}
									</Fragment>
								)
							}
							return null
						})}
						{renderFollowUpMultipleQuestions()}
						<Spacer />

						<div className="text-center w-100 mt-3">
							<Button
								text={'Submit'}
								type={ButtonType.Secondary}
								onClick={confirmQuestions}
								state={validQuestions ? ButtonState.Enabled : ButtonState.Disabled}
							/>
						</div>
						<Spacer size="5" />
					</div>
				)}
			</ReactLoader>
			<ModalPopup
				body={
					<ReactLoader loaded={true} options={{ ...mediumLoaderOptions, color: 'black' }}>
						{getModalText()}
					</ReactLoader>
				}
				bodyAlignment={TemplateModalAlignment.Left}
				show={showConfirmationPopup}
				header={<Text content={'Confirmation'} type={TextType.Heading3} />}
				size={TemplateModalSize.Small}
				theme="medical-dark"
				imageUrl=""
				footer={
					<div>
						<Button
							type={ButtonType.Secondary | ButtonType.Ghost}
							onClick={() => setShowConfirmationPopup(false)}
							text={'I made a mistake'}
						/>
						&nbsp;
						<Button type={ButtonType.Secondary} onClick={() => submitQuestions(true)} text={'Yes, I agree'} />
					</div>
				}
				name="confirmationDialog"
			/>
		</>
	)
}

const Questions = QuestionsComponent
export default Questions
