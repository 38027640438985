import * as React from 'react'
import { useEffect, useState } from 'react'
import {
	Toggle,
	Button,
	ButtonType,
	Spacer,
	FormField,
	FormContainer,
	Alert,
	Dropdown,
	ActionList,
	CalendarInput,
	ButtonState,
	// ValidationUtility,
	Form
} from '@mit/hui'
import { useStoreActions, useStoreState } from '../../store/hooks'
import vaccineController, { VaccinePrescreeningInterface } from '../../api/controller/VaccineController'
import FamilyMembers from '../person/FamilyMembers'
import PersonInfo from '../person/PersonInfo'
import personController, { PersonInterface } from '../../api/controller/PersonController'
import dataController/* , { VaccineStatusResponse } */ from '../../api/controller/DataController'

export interface PreScreeingQuestionsProps {
	onSubmit: (prescreeningData?: VaccinePrescreeningInterface) => void
	onBack: () => void
	stepIndex: number
}

type PrescreeningKeys = 'nr_comorbidities' | 'mit_medical_patient' | 'standby' | 'received'

const PreScreeingQuestionsComponent: React.FC<PreScreeingQuestionsProps> = ({ onSubmit, onBack, stepIndex }) => {
	const { profile, appText, receivedTypes, interests, locations, availabilities, comorbidities, vaccineStatus } = useStoreState(
		store => store.globalModel
	)
	const { setVaccineStatus } = useStoreActions(actions => actions.globalModel)
	const [isLoading, setLoading] = useState<boolean>(false)
	const [isBusy, setBusy] = useState<boolean>(false)
	const [error, setError] = useState<string>('')
	const [mainPersonData, setMainPerson] = useState<PersonInterface>()
	const [showMemberInfo, setShowMemberInfo] = useState<boolean>(false)
	const [prescreeningData, setPrescreeningData] = useState<VaccinePrescreeningInterface>({
		vaccine_prescreening_guid: '',
		has_family_members: false,
		mit_id: '',
		mit_medical_patient: false,
		nr_comorbidities: 1,
		person_guid: '',
		vaccine_interest_type: 2,
		standby: false,
		active: true,
		vaccine_location_id: 1,
		vaccine_availability: 1,
		created_by: '',
		created_on: '',
		received: 3
	})

	const isObjectEmpty = (obj: Object) => {
		return Object.keys(obj).length === 0
	}

	useEffect(() => {
		setLoading(true)
		vaccineController.fetchPrescreening().then(response => {
			if (response.items) {
				if (!isObjectEmpty(response.items)) {
					setPrescreeningData(response.items)
				}
			}

			setLoading(false)
		})
	}, [])

	useEffect(() => {
		if (profile?.mit_id) fetchFamilyMembers(profile.mit_id)
	}, [profile])

	const fetchFamilyMembers = (id: string) => {
		setLoading(true)

		//Added setTimeout due to the useFetch canceling the first call
		setTimeout(() => {
			personController.fetchPerson(id).then(response => {
				if (response.items) {
					const index = response.items.findIndex(itm => itm.is_main_person)
					let getMainMember = response.items[index]
					setMainPerson(getMainMember)
					setLoading(false)
				} else {
					setLoading(false)
				}
			})
		}, 0);
	}

	const changePrescreeningData = (key: PrescreeningKeys, value: any) => {
		if (prescreeningData) {
			let prescreeningCopy = { ...prescreeningData }
			//@ts-ignore
			prescreeningCopy[key] = value
			setPrescreeningData(prescreeningCopy)
		}
	}

	const changeInterestAnswer = (data: any) => {
		if (prescreeningData) {
			let prescreeningCopy = { ...prescreeningData }
			prescreeningCopy.vaccine_interest_type = data.type_id

			setPrescreeningData(prescreeningCopy)
		}
	}

	const changeReceivedAnswer = (data: any) => {
		if (prescreeningData) {
			let prescreeningCopy = { ...prescreeningData }
			prescreeningCopy.received = data.type_id

			setPrescreeningData(prescreeningCopy)
		}
	}

	const changeLocationAnswer = (data: any) => {
		if (prescreeningData) {
			let prescreeningCopy = { ...prescreeningData }
			prescreeningCopy.vaccine_location_id = data.type_id

			setPrescreeningData(prescreeningCopy)
		}
	}

	const changeComorbidityAnswer = (data: any) => {
		if (prescreeningData) {
			let prescreeningCopy = { ...prescreeningData }
			prescreeningCopy.nr_comorbidities = data.type_id

			setPrescreeningData(prescreeningCopy)
		}
	}

	const changeAvailabilityAnswer = (data: any) => {
		if (prescreeningData) {
			let prescreeningCopy = { ...prescreeningData }
			prescreeningCopy.vaccine_availability = data.type_id

			setPrescreeningData(prescreeningCopy)
		}
	}

	const changeFamilyAnswer = (choice: any) => {
		if (choice.choice === 'Negative') {
			if (prescreeningData) {
				let prescreeningCopy = { ...prescreeningData }
				prescreeningCopy.has_family_members = false
				setPrescreeningData(prescreeningCopy)
			}
		} else if (choice.choice === 'Positive') {
			if (prescreeningData) {
				let prescreeningCopy = { ...prescreeningData }
				prescreeningCopy.has_family_members = true
				setPrescreeningData(prescreeningCopy)
			}
		}
	}

	const getFamilyChoice = () => {
		if (prescreeningData?.has_family_members) return 'Positive'

		if (!prescreeningData?.has_family_members) return 'Negative'

		return 'NotSelected'
	}

	const getInterests: any = () => {
		if (interests) {
			let results = interests.map((itm: any) => {
				return {
					text: itm.name,
					id: itm.type_id,
					icon: '',
					onClick: () => changeInterestAnswer(itm)
				}
			})

			return results
		}

		return []
	}

	const getReceivedTypes: any = () => {
		if (receivedTypes) {
			let results = receivedTypes.map((itm: any) => {
				return {
					text: itm.name,
					id: itm.type_id,
					icon: '',
					onClick: () => changeReceivedAnswer(itm)
				}
			})

			return results
		}

		return []
	}

	const getInterestTypeValue: any = () => {
		if (interests && prescreeningData.vaccine_interest_type) {
			let interestIndex = interests.findIndex((itm: any) => itm.type_id === prescreeningData.vaccine_interest_type)
			return interests[interestIndex].name
		}

		return ''
	}

	const getReceivedTypeValue: any = () => {
		if (receivedTypes && prescreeningData.received) {
			let receivedIndex = receivedTypes.findIndex((itm : any) => itm.type_id === prescreeningData.received)
			return receivedTypes[receivedIndex].name
		}

		return ''
	}

	const getLocationTypeValue: any = () => {
		if (locations && prescreeningData.vaccine_location_id) {
			let index = locations.findIndex((itm: any) => itm.type_id === prescreeningData.vaccine_location_id)
			return locations[index].name
		}

		return ''
	}

	const getAvailabilityTypeValue: any = () => {
		if (availabilities && prescreeningData.vaccine_availability) {
			let index = availabilities.findIndex((itm: any) => itm.type_id === prescreeningData.vaccine_availability)
			return availabilities[index].name
		}

		return ''
	}

	const getComorbidityTypeValue: any = () => {
		if (comorbidities && prescreeningData.nr_comorbidities) {
			let index = comorbidities.findIndex((itm: any) => itm.type_id === prescreeningData.nr_comorbidities)
			return comorbidities[index].name
		}

		return ''
	}

	const getLocations: any = () => {
		if (locations) {
			let results = locations.map((itm: any) => {
				return {
					text: itm.name,
					id: itm.type_id,
					icon: '',
					onClick: () => changeLocationAnswer(itm)
				}
			})

			return results
		}

		return []
	}

	const getComorbidities: any = () => {
		if (comorbidities) {
			let results = comorbidities.map((itm: any) => {
				return {
					text: itm.name,
					id: itm.type_id,
					icon: '',
					onClick: () => changeComorbidityAnswer(itm)
				}
			})

			return results
		}

		return []
	}

	const getAvailablity: any = () => {
		if (availabilities) {
			let results = availabilities.map((itm: any) => {
				return {
					text: itm.name,
					id: itm.type_id,
					icon: '',
					onClick: () => changeAvailabilityAnswer(itm)
				}
			})

			return results
		}

		return []
	}

	return (
		<>
			{stepIndex === 1 && (
				<>
					<Alert
						text={
							appText['vaccine.screen.title'] ??
							'MIT is following the Commonwealth of Massachusetts vaccine rollout plan, and asking MIT community members to complete this form to help determine their vaccine eligibility group. The information you provide will be used to send you an invitation when the vaccine is available.'
						}
						type="error"
					/>
					{isLoading && <ActionList items={[]} isLoading />}
					{!isLoading && (
						<>
							{error && <Alert text={error} type="error" />}
							<Form formValidationTrigger={'onBlur'}>
								{methods => {
									const handleSubmit = () => {
										methods.trigger().then(valid => {
											if (valid) {
												setError('')
												if (prescreeningData && getInterestTypeValue() !== 'No') {
													if (!mainPersonData?.date_of_birth) {
														setError('Please select or enter a valid Date of Birth')
														return
													}

													if (mainPersonData.date_of_birth) {
														var myDate = new Date(mainPersonData.date_of_birth)
														var today = new Date()

														if (myDate > today) {
															setError('Please select or enter a valid Date of Birth that is not greater than today')
															return
														}
													}
												}

												setBusy(true)
												vaccineController
													.postPresreening({
														mit_medical_patient: prescreeningData?.mit_medical_patient,
														has_family_members: prescreeningData.has_family_members,
														nr_comorbidities: prescreeningData.nr_comorbidities,
														vaccine_availability: prescreeningData.vaccine_availability,
														vaccine_interest_type: prescreeningData.vaccine_interest_type,
														vaccine_location_id: prescreeningData.vaccine_location_id,
														standby: prescreeningData.standby,
														received: prescreeningData.received
													})
													.then(() => {
														setBusy(true)
														Promise.all([
															personController.updatePerson(mainPersonData),
															dataController.fetchVaccineStatus()
														])
															.then(([personResponse, statusResponse]) => {
																setVaccineStatus(statusResponse.status)
																onSubmit(prescreeningData)
																setBusy(false)
															})
															.catch(() => {
																onSubmit()
																setBusy(false)
															})
													})
											}
										})
									}

									return (
										<FormContainer methods={methods} id="form" formValidationTrigger="onChange">
											{vaccineStatus?.status === 'none' && (
												<>
													<div className="light-text text-center p-1">
														<span>You can change your answers at any time</span>
													</div>
													<div className="form-group row" style={{ marginBottom: '0rem' }}>
														<label htmlFor="vaccine-recieved" className="col-sm-7 col-form-label">
															{appText['vaccine.screen.question_had_it'] ?? 'Have you already had a vaccine shot?'}
														</label>
														<div className="col-sm-5">
															<div className="btn-padded">
																<Dropdown
																	id="vaccine-received"
																	dropdownItems={getReceivedTypes()}
																	name="vaccine-received"
																	text={getReceivedTypeValue()}
																/>
															</div>
														</div>
													</div>
													<Spacer size={'2'} />
													{prescreeningData.received === 3 && (
														<>
															<div className="form-group row" style={{ marginBottom: '0rem' }}>
																<label htmlFor="vaccine" className="col-sm-7 col-form-label">
																	{appText['vaccine.screen.question_want'] ?? 'Do you want the vaccine?'}
																</label>
																<div className="col-sm-5">
																	<div className="btn-padded">
																		<Dropdown
																			id="vaccine"
																			dropdownItems={getInterests()}
																			name="vaccine"
																			text={getInterestTypeValue()}
																		/>
																	</div>
																</div>
															</div>
															<Spacer size={'1'} />
															{prescreeningData && getInterestTypeValue() !== 'No' && (
																<>
																	<div className="row" style={{ marginTop: '1rem' }}>
																		<label htmlFor="location" className="col-sm-7 col-form-label">
																			{appText['vaccine.screen.question_where'] ??
																				'Where do you want to get the vaccine?'}
																		</label>
																		<div className="col-sm-5">
																			<div className="btn-padded">
																				<Dropdown
																					id="location"
																					dropdownItems={getLocations()}
																					text={getLocationTypeValue()}
																					name="location"
																				/>
																			</div>
																		</div>
																	</div>
																	<div className="row form-group" style={{ marginTop: '1rem' }}>
																		<label htmlFor="prescreen-choice-switch" className="col-sm-7 col-form-label">
																			{appText['covid19.vaccine.standby.text'] ?? "I'm willing to be on standby"}
																		</label>
																		<div className="col-sm-5">
																			<div className="btn-padded">
																				<Toggle
																					name="prescreen-choice-switch"
																					choice={prescreeningData.standby ? 'Positive' : 'Negative'}
																					showBothOptions
																					positiveLabel="Yes"
																					negativeLabel="No"
																					ariaLabel="standby"
																					altAriaLabel={appText['covid19.vaccine.standby.text'] ?? "I'm willing to be on standby"}
																					onStateChanged={(e: any) =>
																						changePrescreeningData('standby', e.choice === 'Positive' ? true : false)
																					}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className="extra-q">
																		<div className="row">
																			<div className="col-sm-7 light-text ">
																				<span>
																					{appText['covid19.vaccine.standby.message'] ??
																						'By accepting to be on standby, you agree to let MIT Medical contact you if an earlier appointment becomes available'}
																				</span>
																			</div>
																		</div>
																	</div>
																</>
															)}

															{prescreeningData && getInterestTypeValue() !== 'No' && (
																<>
																	<hr />
																	<div className="prescreen-q">
																		<div className="container">

																			<div>
																				<>
																					<div className="form-group row calendar">
																						<label htmlFor="bod" className="col-sm-4 text-right col-form-label">Date of Birth</label>
																						<div className="col-sm-8">
																							<CalendarInput
																								id="dob"
																								value={
																									mainPersonData?.date_of_birth ? mainPersonData?.date_of_birth : null
																								}
																								onChange={(e: string) => {
																									if (mainPersonData) {
																										let personCopy = { ...mainPersonData }
																										personCopy.date_of_birth = e
																										setMainPerson(personCopy)
																									}
																								}}
																							/>
																						</div>
																					</div>
																					<FormField
																						mainClassName="phone"
																						editor="textbox"
																						id="phone"
																						required
																						label={appText['vaccine.screen.info_phone']}
																						value={mainPersonData?.best_phone}
																						onChange={(e: any) => {
																							if (mainPersonData) {
																								let personCopy = { ...mainPersonData }
																								personCopy.best_phone = e.currentTarget.value
																								setMainPerson(personCopy)
																							}
																						}}
																						labelDisplay="nextto"
																					/>
																					<FormField
																						mainClassName="email"
																						editor="textbox"
																						id="email"
																						registerObject={{
																							pattern: {
																								value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*[ ]*$/,
																								message: 'Email format'
																							},
																							required: true
																						}}
																						onChange={(e: any) => {
																							if (mainPersonData) {
																								let personCopy = { ...mainPersonData }
																								personCopy.email_address = e.currentTarget.value
																								setMainPerson(personCopy)
																							}
																						}}
																						label={appText['vaccine.screen.info_email']}
																						value={mainPersonData?.email_address}
																						labelDisplay="nextto"
																					/>
																					<FormField
																						mainClassName="medical-patient"
																						editor="toggle"
																						properties={{
																							showBothOptions: true,
																							positiveLabel: 'Yes',
																							negativeLabel: 'No',
																							onStateChanged: (e: any) =>
																								changePrescreeningData(
																									'mit_medical_patient',
																									e.choice === 'Positive'
																								)
																						}}
																						choice={prescreeningData.mit_medical_patient ? 'Positive' : 'Negative'}
																						id="medicalPatient"
																						label={
																							appText['vaccine.screen.question_patient'] ??
																							'Are you MIT Medical patient?'
																						}
																						ariaLabel={appText['vaccine.screen.question_patient'] ??
																						'Are you MIT Medical patient?'}
																						labelDisplay="nextto"
																					/>
																					{!prescreeningData.mit_medical_patient && (
																						<>
																							<FormField
																								mainClassName="comorb"
																								editor="dropdown"
																								id="co-morbidities"
																								value={getComorbidityTypeValue()}
																								options={getComorbidities()}
																								label={
																									appText['vaccine.screen.question_comorbidities'] ??
																									'Do you have a condition recognized as causing an increased risk of illness from the virus that causes COVID-19?'
																								}
																								labelDisplay="nextto"
																							/>
																							<div className="row">
																								<div className="col-sm-4 text-right">
																									<a
																										href={
																											appText['vaccine.screen.sources1.url'] ??
																											'https://covidapps.mit.edu/vaccine-information#comorbidities'
																										}
																										rel="noopener noreferrer"
																										target="_blank"
																										className="greenlinks"
																									>
																										{appText['vaccine.screen.sources1.text'] ?? 'CDC Co-morbids list'}
																										<span className="sr-only" >, opens in new browser tab or window.</span>
																									</a>
																								</div>
																							</div>
																						</>
																					)}
																					<FormField
																						mainClassName="when"
																						editor="dropdown"
																						options={getAvailablity()}
																						value={getAvailabilityTypeValue()}
																						id="when"
																						label={
																							appText['vaccine.screen.question_when'] ??
																							'How quickly would you come in to get it?'
																						}
																						labelDisplay="nextto"
																					/>
																					<div className="extra-q">
																						<div className="row">
																							<div className="col-sm-4 light-text ">
																								<span>If the vaccine was available at MIT Medical for your group</span>
																							</div>
																						</div>
																					</div>
																				</>
																			</div>
																		</div>
																	</div>
																</>
															)}
														</>
													)}
												</>
											)}
											<hr />
											<div className="prescreen">
												<div className="row mt-3">
													<label htmlFor="family-vaccination-switch" className="col-sm-7" aria-labelledby={appText['vaccine.screen.question_members_dtl']} aria-label={appText['vaccine.screen.question_members_dtl']}>
														{appText['vaccine.screen.question_members_dtl'] ??
															'Do you have household family members including dependents who want to get the vaccine at MIT Medical when their group is declared eligible for the vaccine by the Commonwealth of Massachusetts'}
													</label>
													<div className="col-sm-5">
														<div className="">
															<Toggle
																name="family-vaccination-switch"
																showBothOptions
																ariaLabel="household family members"
																positiveLabel="Yes"
																negativeLabel="No"
																choice={getFamilyChoice()}
																onStateChanged={(c: any) => changeFamilyAnswer(c)}
																altAriaLabel={appText['vaccine.screen.question_members_dtl'] ??
																'Do you have household family members including dependents who want to get the vaccine at MIT Medical when their group is declared eligible for the vaccine by the Commonwealth of Massachusetts'}
															/>
														</div>
													</div>
												</div>
												<div className="row pt-3">
													<div className="col-12 text-center medical nobackground">
														<Button
															isBusy={isBusy}
															state={isBusy ? ButtonState.Disabled : ButtonState.Enabled}
															text="Submit"
															onClick={() => {
																handleSubmit()
															}}
														/>
														<Spacer size="2" />
													</div>
												</div>
											</div>
											<Spacer />
											<hr></hr>
											<div className="container text-center">
												<a
													href={appText['vaccine.screen.sources3.url'] ?? 'https://medical.mit.edu/privacy'}
													rel="noopener noreferrer"
													className="greenlinks"
													target="_blank"
												>
													{appText['vaccine.screen.sources3.text'] ?? 'Privacy Statement'}
													<span className="sr-only" >, opens in new browser tab or window.</span>
												</a>
											</div>
										</FormContainer>
									)
								}}
							</Form>
						</>
					)}
				</>
			)}
			{stepIndex === 2 && (
				<>
					<div>
						<div>
							{appText['vaccine.screen.question_members_dtl'] ??
								'Do you have family members who wants to get the vaccine at MIT Medical their group is declared eligible for the vaccine by the Commonwealth of Massachusetts?'}
						</div>
						<Spacer size="3" />
						{isLoading && <ActionList items={[]} isLoading />}
						{!isLoading && <FamilyMembers id={profile?.mit_id ?? ''} />}

						<div>
							<div className="p-3 text-center medical nobackground">
								<Button
									type={ButtonType.Secondary | ButtonType.Ghost}
									icon="plus"
									text="Add family member"
									onClick={() => {
										setShowMemberInfo(true)
									}}
								/>
								<div className="my-2 light-text">
									<span>{appText['vaccine.members_list.info'] ?? 'Household family members only'}</span>
								</div>
							</div>
						</div>
						<div className={'text-center medical nobackground btn-padded'}>
							<Button
								text=""
								title="back"
								icon={'chevron-left'}
								type={ButtonType.Secondary | ButtonType.Ghost}
								onClick={onBack}
							/>
							<Button text="Done" icon={''} type={ButtonType.Primary} onClick={() => onSubmit()} />
						</div>
					</div>
					<PersonInfo
						onAction={() => {
							setShowMemberInfo(false)
							fetchFamilyMembers(profile?.mit_id ?? '')
						}}
						mainPersonId={mainPersonData?.mit_id ?? ''}
						show={showMemberInfo}
						onClose={() => {
							setShowMemberInfo(false)
						}}
						onBack={() => {
							setShowMemberInfo(false)
						}}
					/>
					<Spacer />
					<hr></hr>
					<div className="container text-center">
						<a
							href={appText['vaccine.screen.sources3.url'] ?? 'https://medical.mit.edu/privacy'}
							rel="noopener noreferrer"
							className="greenlinks"
							target="_blank"
						>
							{appText['vaccine.screen.sources3.text'] ?? 'Privacy Statement'}
							<span className="sr-only" >, opens in new browser tab or window.</span>
						</a>
					</div>
				</>
			)}
		</>
	)
}

const PreScreeningQuestions = PreScreeingQuestionsComponent
export default PreScreeningQuestions
