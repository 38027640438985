import * as React from 'react'
import { FC, useEffect } from 'react'
import { useState } from 'react'
import { Row } from 'react-bootstrap'
import 'react-day-picker/lib/style.css'
import { PageError } from '../../types/PageErrors'
import ErrorMessage from '../../common/ErrorMessage'
import ReactLoader from 'react-loader'
import { mediumLoaderOptions } from '../../common/Defaults'
import { COVID_TEXTS } from '../../common/Texts'
import {
	PostWaiverResponse,
	VaccineWaiverInterface,
	VaccineWaiverResponse
} from '../../api/controller/VaccineController'
import {
	// PagingContainer,
	Button,
	// ButtonState,
	Text,
	ButtonType,
	TemplateModal,
	TemplateModalSize,
	TemplateModalAlignment,
	TextType
} from '@mit/hui'
import $ from 'jquery'
import { withComponent } from '../../common/WithComponent'
import vaccineController from '../../api/controller/VaccineController'
import dataController, { /* FetchPassStatusResponse,  */VaccineStatusResponse } from '../../api/controller/DataController'
import { useStoreActions, useStoreState } from '../../store/hooks'
import { ActiveDrawer } from '../../store/models/global'

export interface VaccineWaiverProps {}

// const VaccineWaiverComponent: FC<VaccineWaiverProps> = ({}) => {
const VaccineWaiverComponent: FC<VaccineWaiverProps> = () => {
	const [isShowingConfirmationPopup, setIsShowingConfirmationPopup] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [pageError, setPageError] = useState<PageError>({ error: false, errorText: '', showRetry: false })
	const { appText } = useStoreState(store => store.globalModel)
	const { /* setStatusModel,  */setActiveDrawer, setVaccineStatus } = useStoreActions(actions => actions.globalModel)

	// const [validationError, setValidationError] = useState(false)

	const [waivers, setWaivers] = useState<VaccineWaiverInterface[] | undefined>(undefined)
	const [currentStep, setCurrentStep] = useState<number>(0)
	const [agreements, setAgreements] = useState<any>({})

	useEffect(() => {
		vaccineController.fetchWaiverTextAll().then((resp: VaccineWaiverResponse) => {
			setIsLoading(false)

			if (!resp.error) {
				setWaivers(resp.waivers)
				setAgreements(resp.waivers!.reduce((total, curr, i) => ({ ...total, [i]: undefined }), {}))

				//mainAppState!.stepperSelectedIndex = 0;
				//mainAppState!.stepperItemCount = resp.waivers!.length;
			} else {
				setPageError({
					error: true,
					errorText: `An error occurred loading the forms. If the problem persists, please contact ${
						appText[COVID_TEXTS['covid19.pass.help_email']]
					}.`,
					showRetry: false
				})
			}
		})
	}, [appText])

	const clickAgreeButton = () => {
		agreements[currentStep] = true

		setAgreements(agreements)

		submit(true)
	}

	const clickVaccineDeclineButton = () => {
		agreements[currentStep] = false

		setAgreements(agreements)

		submit(false)
	}

	const submit = (agree: boolean) => {
		if (currentStep === waivers!.length - 1 || !agree) {
			//last step or declined
			let agree = agreements[waivers!.filter((w: VaccineWaiverInterface) => w.type === 'waiver').length - 1]
			submitVaccineAgreement(!!agree)
		} else {
			$('.drawer').scrollTop(0)
			setCurrentStep(currentStep + 1)
		}

		//if ((mainAppState!.stepperItemCount - 1) !== mainAppState!.stepperSelectedIndex) {
		//	mainAppState!.stepperSelectedIndex = currentStep + 1;
		//}
	}

	const submitVaccineAgreement = (agreed: boolean) => {
		hideModal()
		setIsLoading(true)

		vaccineController.postVaccineWaiver(agreed).then((result: PostWaiverResponse) => {
			if (result.success) {
				dataController.fetchVaccineStatus().then((vaccineStatusResponse: VaccineStatusResponse) => {
					setVaccineStatus(vaccineStatusResponse.status)

					setIsLoading(false)

					if (agreed) {
						setActiveDrawer(ActiveDrawer.VaccineAppointment)
					} else {
						setActiveDrawer(ActiveDrawer.None)
					}
				})
			} else {
				setIsLoading(false)

				setPageError({
					error: true,
					errorText: `An error occurred submitting your consent. If the problem persists, please contact ${
						appText[COVID_TEXTS['covid19.pass.help_email']]
					}.`,
					showRetry: false
				})
			}
		})
	}

	const hideModal = () => {
		setIsShowingConfirmationPopup(false)
	}

	const showModal = () => {
		//@ts-ignore
		setIsShowingConfirmationPopup(true)
	}

	const getModalText = () => {
		return 'You consent is required in order to receive the vaccine. By not agreeing, you are ineligible to receive the vaccine at MIT Medical at this time.'
	}

	//Because our (HUI) Modal gets appended to the documentElement root, we need to rerender and update the class component in order to let our Modal re-append to the documentElement root with the latest info
	const ModalPopup = withComponent(TemplateModal)

	return (
		<>
			<ReactLoader loaded={!isLoading} options={{ ...mediumLoaderOptions, color: 'black' }}>
				<div className={'splash-container p-4'}>
					{waivers && (
						<Row>
							<div className="text-center">
								<img src={'mit-logo-black.png'} alt='' className={'splash-icon'} />
							</div>
							<div className={'splash-text'}>
								<h1>{waivers[currentStep].ack_title}</h1>
								<div className={'waiver-text'}>
									{waivers && <div dangerouslySetInnerHTML={{ __html: waivers[currentStep].text }} />}
								</div>
							</div>

							{/* {validationError && (
								<Alert variant={'danger'} className={'smallMarginTop'}>
									Please review your answers and try again.
								</Alert>
							)} */}
							<div className="text-right w-100">
								<Button
									theme="medical-dark"
									text={'I disagree'}
									type={ButtonType.Primary | ButtonType.Ghost}
									icon="times"
									onClick={showModal}
								/>
								&nbsp;&nbsp;
								<Button
									theme="medical-dark"
									text={'I agree to the above'}
									type={ButtonType.Primary}
									icon="check"
									onClick={clickAgreeButton}
								/>
							</div>
							{pageError.error && <ErrorMessage text={pageError.errorText} className={'color-black'} />}
						</Row>
					)}
				</div>
			</ReactLoader>
			<ModalPopup
				body={
					<ReactLoader loaded={true} options={{ ...mediumLoaderOptions, color: 'black' }}>
						{getModalText()}
					</ReactLoader>
				}
				bodyAlignment={TemplateModalAlignment.Left}
				show={isShowingConfirmationPopup}
				header={<Text content={'Are you sure?'} type={TextType.Heading3} />}
				size={TemplateModalSize.Small}
				theme="medical-dark"
				imageUrl=""
				footer={
					<div>
						<Button type={ButtonType.Secondary | ButtonType.Ghost} onClick={hideModal} text={'Go back'} />
						&nbsp;
						<Button type={ButtonType.Secondary} onClick={clickVaccineDeclineButton} text={"Yes, I'm sure"} />
					</div>
				}
				name="confirmationDialog"
			/>
		</>
	)
}

const VaccineWaiver = VaccineWaiverComponent
export default VaccineWaiver
