import { VaccineStatusModel } from '../../types/VaccineStatusModel'
import { VaccineSymptomModel } from '../../types/VaccineSymptomModel';
import { AuthProvider } from '../AuthProvider'
import BaseController from './BaseController'
import { getResultText } from "../../common/Formatters";
import { PassStatusModel } from '../../types/CovidStatusModel';

export interface TextItem {
	id: string
	text: string
}

export interface FetchArrayResponse {
	items?: any[]
	error?: any
}

export interface VaccineStatusResponse {
	status?: VaccineStatusModel;
	error?: any;
}

export interface FetchBlobResponse {
	value?: Blob;
	error?: any;
}

export interface MedicalQueueLengths {
	favorite?: boolean;
	wait_time: string;
	wait_time_text: string;
	location_id: number;
	name: string;
	day_title: string;
	open_time: string;
	close_time: string;
	is_open: boolean;
	medical_queue_indicator: boolean;
}

export interface FetchMedicalQueueLengthResponse {
	items?: MedicalQueueLengths[];
	error?: any;
}

export interface FetchPassStatusResponse {
	status_model?: PassStatusModel;
	error?: any;
}

export interface FetchStringResponse {
	value?: string;
	error?: any;
}

export interface FetchPersonRelationshipsResponse {
	items?: RelationshipInterface[];
	error?: any;
	unauth?: any;
}

export interface RelationshipInterface {
	person_relationship_type_id: number,
	name: string;
}

class DataController extends BaseController {
	readonly url_array: string
	readonly url_vaccine: string;
	readonly url_medical_queue_time: string;
	readonly url_barcode: string;
	readonly url_fetch_results: string;
	readonly url_tests_result_pdf: string;
	readonly url_pass_status: string;
	url_fetch_flu_shots: string;
	url_fetch_vaccines: string;

	constructor () {
		super()

		this.url_array = `${this.apiHost}/${this.apiPathPass}/pass`;
		this.url_vaccine = `${this.apiHost}/${this.apiPathVaccine}/vaccine`;
		this.url_medical_queue_time = `${this.apiHost}/${this.apiPathPass}/pass/medical/queue_times`;
		this.url_barcode = `${this.apiHost}/${this.apiPathPass}/daily_pass`;
		this.url_fetch_results = `${this.apiHost}/${this.apiPathMedical}/tests/results?optional=flu_shots`;
		this.url_fetch_flu_shots = `${this.apiHost}/${this.apiPathMedical}/tests/results?optional=flu_shots,flushot_missing`;
		this.url_fetch_vaccines = `${this.apiHost}/${this.apiPathMedical}/tests/results?optional=vaccine,vaccine_missing,exemption`;
		// this.url_fetch_results = `${this.apiHost}/${this.apiPathMedical}/tests/results?optional=medical,flu_shots,vaccine,exemption,vaccine_missing,flushot_missing`;
		// this.url_fetch_results = `${this.apiHost}/${this.apiPathMedical}/tests/results?optional=exemption`;
		this.url_tests_result_pdf = `${this.apiHost}/${this.apiPathMedical}/tests/result/pdf`;
		this.url_pass_status = `${this.apiHost}/${this.apiPathPass}/pass/access_status`;
	}

	checkStatusCode(status: number): boolean {
        return status >= 200 && status < 300
    }

	fetchArray = async (endpoint: string): Promise<FetchArrayResponse> => {
		let response = await this.useFetch('GET', 'fetchArray',`${this.url_array}/${endpoint}`)

		if (response.status === 200) {
			let data = await response.json()
			return { items: data }
		} else {
			return {
				error: true
			}
		}
	}

	fetchPassStatus = async (signal?: any, kerbId?: string): Promise<FetchPassStatusResponse> => {

		try {
			let response = await this.useFetch('GET', 'fetchPassStatus', this.url_pass_status);

			if (response.status === 200) {
				let data: PassStatusModel = await response.json();

				const resp: FetchPassStatusResponse = {
					//@ts-ignore
					status_model: {
						...data,
					},
				};
				return resp;
			} else {
				return {
					error: true,
				};
			}
		} catch (e) {
			console.error(e);
			return {
				error: true,
			};
		}
	};

	fetchVaccineStatus = async (signal?: any, kerbId?: string): Promise<VaccineStatusResponse> => {

		try {
			let response = await this.useFetch('GET', 'fetchVaccineStatus',this.url_vaccine + "/status");

			if (response.status === 200) {
				let data: VaccineStatusModel = await response.json();

				const resp: VaccineStatusResponse = {
					status: {
						...data,
					},
				};
				return resp;
			} else {
				return {
					error: true,
				};
			}
		} catch (e) {
			console.error(e);
			return {
				error: true,
			};
		}
	};

	postVaccineSymptoms = async (symptoms: VaccineSymptomModel, kerbId?: string): Promise<any> => {
		let token = await new AuthProvider().getToken();

		const headerInit: any = {
			Authorization: "Bearer " + token.token,
			"Content-Type": "application/json",
		};

		//if (kerbId && kerbId != "") {
		//	headerInit["X-Impersonated-User"] = kerbId;
		//}

		const options: RequestInit = {
			method: "POST",
			headers: new Headers(headerInit),
			body: JSON.stringify(symptoms),
			mode: "cors",
		};

		let response = await fetch(this.url_vaccine + `/sideeffect`, options);

		if (response.status === 200) {
			// let data = await response.json();
			return {
				success: true,
			};
		} else {
			return {
				error: true,
			};
		}
	};

	async fetchMedicalQueueLengths(signal: AbortSignal, number_of_days: number): Promise<FetchMedicalQueueLengthResponse> {

		let response = await this.useFetch('GET', 'fetchMedicalQueueLengths',`${this.url_medical_queue_time}?number_of_days=${number_of_days}`);
		if (response.status === 200) {
			return { items: await response.json() };
		} else {
			return {
				error: true,
			};
		}
	}

	async fetchTestResults(signal: AbortSignal): Promise<FetchArrayResponse> {

		let response = await this.useFetch('GET', 'fetchTestResults',`${this.url_fetch_results}`);
		if (response.status >= 200 && response.status < 300) {
			let data = await response.json();

			return {
				items: data
					? data.map((item: any) => ({
						date: item.test_date,
						result: getResultText(item),
						rawResult: item.result ?? "Pending",
						guid: item.test_guid,
						can_download: item.can_download,
					}))
					: undefined,
			};
		} else {
			return {
				error: true,
			};
		}
	}

	async fetchFluShots(): Promise<FetchArrayResponse> {
	// async fetchFluShots(signal: AbortSignal): Promise<FetchArrayResponse> {

		let response = await this.useFetch('GET', 'fetchFluShots',`${this.url_fetch_flu_shots}`);
		if (response.ok) {
			let data = await response.json();

			return {items: data? data: []};
		} else {
			return {
				error: true,
			};
		}
	}

	async fetchVaccines(): Promise<FetchArrayResponse> {
	// async fetchVaccines(signal: AbortSignal): Promise<FetchArrayResponse> {

		let response = await this.useFetch('GET', 'fetchVaccines',`${this.url_fetch_vaccines}`);
		if (response.ok) {
			let data = await response.json();

			return {items: data? data: []};
		} else {
			return {
				error: true,
			};
		}
	}

	fetchBarcode = async (signal?: any, kerbId?: string): Promise<FetchStringResponse> => {

		let response = await this.useFetch('GET', 'fetchBarcode', `${this.url_barcode}`);
		if (response.status === 200) {
			let data = await response.blob();
			return {
				value: URL.createObjectURL(data),
			};
		} else {
			return {
				error: true,
			};
		}
	};

	async fetchMedicalResultPDF(isFluShot: boolean, isVaccine: boolean, isProof: boolean, guid: string, signal?: any): Promise<FetchBlobResponse> {

		let url = `${this.url_tests_result_pdf}`;

		if (isFluShot) {
			url += "?flushot=1&test_guid=" + guid;
		} else if (isVaccine) {
			url += "?vaccine=1&test_guid=" + guid;
		} else if (isProof) {
			url += "?proof_guid=" + guid + '&vaccine_external=1';
		} else {
			url += "?test_guid=" + guid;
		}

		let response = await this.useFetch('GET', 'fetchMedicalResultPDF', url);
		if (response.status === 200) {
			let data = await response.blob();
			return {
				value: data,
			};
		} else {
			return {
				error: true,
			};
		}
	}
}

const dataController = new DataController()
export default dataController
