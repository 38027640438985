import * as React from 'react'
import { FC } from 'react'
import { ActionList, ListItemProps, ActionListType, Icon/* , ItemAlignment */ } from '@mit/hui'
import { useEffect, useCallback } from 'react'
import personController, { PersonInterface } from '../../api/controller/PersonController'
import { useState } from 'react'
import PersonInfo from './PersonInfo'

interface FamilyMembersProps {
	id: string
}

const FamilyMembers: FC<FamilyMembersProps> = ({ id }) => {
	const [familyMembers, setFamilyMembers] = useState<PersonInterface[]>([])
	const [isLoading, setLoading] = useState<boolean>(false)
	const [editMember, setEditMember] = useState<boolean>(false)
	const [selectedPerson, setSelectedPerson] = useState<PersonInterface>()
	console.log('FamilyMembers',selectedPerson)
	const fetchFamilyMembers = useCallback(() => {
		setLoading(true)

		personController.fetchPerson(id).then(response => {
			if (response.items) {
				setFamilyMembers(response.items)
				setLoading(false)
			} else {
				setLoading(false)
			}
		})
	}, [id])

	useEffect(() => {
		if (id) fetchFamilyMembers()
	}, [id, fetchFamilyMembers])


	const checkInfoCompleted: any = (itm: PersonInterface) => {
		if(!itm.first_name || !itm.last_name || !itm.date_of_birth || !itm.email_address || !itm.best_phone)
		return {content: <Icon icon="exclamation-circle" type="solid" />, type: "warning"}
		else return null;
	}

	const familyListItems: ListItemProps[] = []

	familyMembers.forEach(itm => {
		if(!itm.is_main_person)
		{
			let item: ListItemProps = {
				text: `${itm.first_name} ${itm.last_name}`,
				icon: 'user',
				secondaryText: itm.mit_id,
				badge: checkInfoCompleted(itm),
				tertiaryText: itm.relationship_type_name,
				onClick: () => {setSelectedPerson(itm); setEditMember(true)}
			}

			familyListItems.push(item)
		}
	})

	if (isLoading) return <ActionList isLoading items={[]} />

	return (
		<>
			<div className="container family-list">
				<ActionList items={familyListItems} compact type={ActionListType.Flush} />
				<PersonInfo onAction={() => {setEditMember(false); fetchFamilyMembers()}} mainPersonId={id} show={editMember} onClose={() => setEditMember(false)} person={selectedPerson} onBack={() => {setEditMember(false)}} />
			</div>
		</>
	)
}
export default FamilyMembers
