import { AuthProvider } from '../AuthProvider'
import appConfig from '../../app-config'

export default class BaseController {
	readonly apiHost: string
	readonly apiPath: string
	readonly apiPathDigitalId: string
	readonly apiPathCovid: string
	readonly apiPathVaccine: string
	readonly apiPathPass: string
	readonly apiPathMedical: string
	fetchController: any

	constructor () {
		// this.apiHost = process.env.REACT_APP_ENV === 'local' ? '' : process.env.REACT_APP_API_HOST ? process.env.REACT_APP_API_HOST : '';
		///@ts-ignore
		this.apiHost = appConfig.api.hostname
		///@ts-ignore
		this.apiPath = appConfig.api.paths['elo']
		///@ts-ignore
		this.apiPathDigitalId = appConfig.api.paths['digitalId']
		///@ts-ignore
		this.apiPathCovid = appConfig.api.paths['covid19']
		this.apiPathVaccine = appConfig.api.paths["vaccine"];
        this.apiPathPass = appConfig.api.paths["pass"];
        this.apiPathMedical = appConfig.api.paths["medical"];
		this.fetchController = {}
	}

	requestHeaderGet = async (moreHeaders?: any): Promise<RequestInit> => {
		let token = await new AuthProvider().getToken()
		token = token.token
        const options: RequestInit = {
            method: "GET",
            headers: new Headers({
                'Authorization': 'Bearer ' + token,
                ...moreHeaders
            })
        }
        return options
    }

	async getHeaders (method: string, file?: boolean, fileName?: any, impersonateKerb?: string): Promise<Headers> {
		let token = await new AuthProvider().getToken()
		token = token.token

		let headers: Headers = new Headers({
			// 'Method': method,
			// 'Authorization': 'Bearer ' + token,
		})

		if (file) {
			headers.append('Content-Type', 'image/jpeg');
			// headers.append('Content-Filename', fileName);
		} else {
			headers.append('Content-Type', 'application/json')
			//headers.append('Cache-Control', 'private, max-age=3600')
			headers.append('Authorization', 'Bearer ' + token)
		}

		if (impersonateKerb) {
			headers.append('X-Impersonated-User', impersonateKerb)
		}

		return headers
	}

	async useFetch (
		method: string,
		fetchKey: string,
		url: string,
		body?: any,
		file?: boolean,
		fileName?: string | null,
		impersonateKerb?: string
	): Promise<any> {
		if (fetchKey && this.fetchController[fetchKey]) {
			this.fetchController[fetchKey].abort()
		}

		this.fetchController[fetchKey] = new AbortController()
		let { signal } = this.fetchController[fetchKey]

		let options: any = {
			method: method,
			headers: await this.getHeaders(method, file, fileName, impersonateKerb),
			redirect: 'follow',
			signal
		}
		if (body) {
			options['body'] = file ? body : JSON.stringify(body)
		}

		let response = await fetch(url, options)

		//check for SCP timeout
		let sessionExpiredHeader = response.headers.get('com.sap.cloud.security.login')

		if (sessionExpiredHeader) {
			//show reload dialog
			console.error('SESSION EXPIRED!', 'RELOAD')
			window.location.reload()
		}

		//clear out controller
		this.fetchController[fetchKey] = null

		return response
	}

	async logout (): Promise<any> {
		await new AuthProvider().logout()
	}

	async getUserAuth (): Promise<any> {
		let response = await this.useFetch('GET', 'initialize', `${this.apiHost}/${this.apiPath}/authorization`)

		if (response.status === 200) {
			let data = response.json()
			return data
		} else {
			let text = await response.text()
			return Promise.reject(text)
		}
	}
}
