import appConfig from '../app-config'

export const getEnv = () => {
	switch (appConfig.stage) {
		case 'develop':
			return 'dev'
		case 'release':
			return 'staging'
		case 'master':
			return 'production'
	}
	return 'production'
}
