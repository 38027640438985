import Amplify, { Auth } from 'aws-amplify'
import { FederatedSignInOptionsCustom } from '@aws-amplify/auth/lib/types'
import appConfig from '../app-config'

Amplify.configure(appConfig.amplify)

export class AuthProvider {
	controller: any

	getToken = async (): Promise<any> => {
		return new Promise((resolve, reject) => {
			Auth.currentAuthenticatedUser()
				.then(res => {
					// console.log('logged in', res)
					// resolve(res.getSignInUserSession().accessToken.jwtToken);
					// debugger;
					resolve({
						token: res.getSignInUserSession().accessToken.jwtToken,
						user: res
							.getUsername()
							.split('Touchstone_')[1]
							.split('@')[0]
					})
				})
				.catch(err => {
					console.log('not logged in', err)
					Auth.federatedSignIn({
						customProvider: appConfig.signInProvider
					} as FederatedSignInOptionsCustom).catch(err1 => {
						console.log('login err', err1)
					})
				})
		})
	}

	logout = async (): Promise<any> => {
		await Auth.signOut()
	}
}
