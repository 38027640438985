import {
	ActionList,
	Alert,
	Button,
	ButtonState,
	ButtonType,
	CalendarInput,
	Drawer,
	DrawerSize,
	DrawerType,
	DropdownItemProps,
	Form,
	FormContainer,
	FormField,
	Spacer,
	Text,
	TextType,
	ValidationUtility
} from '@mit/hui'
import * as React from 'react'
import { useState } from 'react'
import { FC } from 'react'
import personController, { PersonInterface } from '../../api/controller/PersonController'
import vaccineController, { VaccinePrescreeingFamily } from '../../api/controller/VaccineController'
import { useStoreState } from '../../store/hooks'

interface PersonInfoComponentProps {
	person?: PersonInterface
	show: boolean
	mainPersonId: string
	onClose: () => void
	onBack: () => void
	onAction: () => void
}

const PersonInfo: FC<PersonInfoComponentProps> = ({ person, show, onClose, onBack, onAction, mainPersonId }) => {
	const { relationships, availabilities, jobTypes, appText, comorbidities } = useStoreState(store => store.globalModel)
	const [familyData, setFamilyData] = useState<VaccinePrescreeingFamily>({
		has_family_members: false,
		created_by: '',
		created_on: '',
		mit_id: '',
		nr_comorbidities: 1,
		mit_medical_patient: false,
		person_guid: '',
		vaccine_availability: 1,
		vaccine_interest_type: 1,
		vaccine_location_id: 1,
		vaccine_prescreening_guid: ''
	})
	const [isLoading, setLoading] = useState<boolean>(false)
	const [isBusy, setBusy] = useState<boolean>(false)
	const [error, setError] = useState<string>('')
	const [knowMITID, setKnowMITID] = useState<boolean>(false)
	const [isBusyRemoving, setBusyRemoving] = useState<boolean>(false)
	const [personData, setPersonData] = useState<PersonInterface>(
		person ?? {
			address1: '',
			address2: '',
			best_phone: '',
			city: '',
			country: '',
			date_of_birth: '',
			data_update_in_progress: '',
			email_address: '',
			first_name: '',
			person_guid: '',
			main_person_guid: '',
			is_housing_checked_in: false,
			is_main_person: false,
			last_name: '',
			last_test: '',
			main_person_mit_id: mainPersonId,
			middle_name: '',
			job_role_type_id: 1,
			job_role_type_name: '',
			mit_id: null,
			postal_code: '',
			relationship_type_id: 1,
			relationship_type_name: '',
			state: ''
		}
	)

	const isObjectEmpty = (obj: Object) => {
		return Object.keys(obj).length === 0
	}

	React.useEffect(() => {
		setPersonData({
			address1: '',
			address2: '',
			best_phone: '',
			city: '',
			country: '',
			date_of_birth: '',
			data_update_in_progress: '',
			email_address: '',
			first_name: '',
			person_guid: '',
			main_person_guid: '',
			is_housing_checked_in: false,
			is_main_person: false,
			last_name: '',
			last_test: '',
			main_person_mit_id: mainPersonId,
			middle_name: '',
			job_role_type_id: 1,
			job_role_type_name: '',
			mit_id: null,
			postal_code: '',
			relationship_type_id: 1,
			relationship_type_name: '',
			state: ''
		})
	}, [show, mainPersonId])

	React.useEffect(() => {
		const fetchPersonFamilyData = (person_guid: string) => {
			setLoading(true)
			vaccineController.fetchVaccinePrescreeningFamily(person_guid).then(response => {
				if (response.data) {
					if (!isObjectEmpty(response.data)) {
						setFamilyData(response.data)
					}
					setLoading(false)
				} else {
					setLoading(false)
				}
			})
		}
		if (person) {
			setPersonData(person)

			if (person.person_guid) fetchPersonFamilyData(person.person_guid)
		} else {
			setPersonData({
				address1: '',
				address2: '',
				best_phone: '',
				city: '',
				country: '',
				date_of_birth: '',
				data_update_in_progress: '',
				email_address: '',
				first_name: '',
				person_guid: '',
				main_person_guid: '',
				is_housing_checked_in: false,
				is_main_person: false,
				last_name: '',
				last_test: '',
				main_person_mit_id: mainPersonId,
				middle_name: '',
				job_role_type_id: 1,
				job_role_type_name: '',
				mit_id: null,
				postal_code: '',
				relationship_type_id: 1,
				relationship_type_name: '',
				state: ''
			})
		}
	}, [person, mainPersonId])

	const getRelationships = () => {
		if (relationships) {
			let results: DropdownItemProps[] = relationships.map((itm: any) => {
				return {
					text: itm.name,
					id: itm.person_relationship_type_id,
					icon: ''
				}
			})

			return results
		}

		return []
	}

	const getAvailablity: any = () => {
		if (availabilities) {
			let results = availabilities.map((itm: any) => {
				return {
					text: itm.name,
					id: itm.type_id,
					icon: '',
					onClick: () => null //changeAvailabilityAnswer(itm)
				}
			})

			return results
		}

		return []
	}

	const getJobs: any = () => {
		if (jobTypes) {
			let results = jobTypes.map((itm: any) => {
				return {
					text: itm.name,
					id: itm.type_id,
					icon: ''
				}
			})

			return results
		}

		return []
	}

	const changeComorbidityAnswer = (data: any) => {
		if (familyData) {
			let prescreeningCopy = { ...familyData }
			prescreeningCopy.nr_comorbidities = data.type_id

			setFamilyData(prescreeningCopy)
		}
	}

	const getAvailabilityTypeValue: any = () => {
		if (availabilities && familyData.vaccine_availability) {
			let index = availabilities.findIndex((itm: any) => itm.type_id === familyData.vaccine_availability)
			return availabilities[index].name
		}

		return ''
	}

	const getComorbidityTypeValue: any = () => {
		if (comorbidities && familyData.nr_comorbidities) {
			let index = comorbidities.findIndex((itm: any) => itm.type_id === familyData.nr_comorbidities)
			return comorbidities[index].name
		}

		return ''
	}

	const getComorbidities: any = () => {
		if (comorbidities) {
			let results = comorbidities.map((itm: any) => {
				return {
					text: itm.name,
					id: itm.type_id,
					icon: '',
					onClick: () => changeComorbidityAnswer(itm)
				}
			})

			return results
		}

		return []
	}

	const changePersonData = (key: any, value: any) => {
		if (personData) {
			let personCopy = { ...personData }
			//@ts-ignore
			personCopy[key] = value
			setPersonData(personCopy)
		}
	}

	const changeJobData = (id: any, name: any) => {
		if (personData) {
			let personCopy = { ...personData }
			//@ts-ignore
			personCopy.job_role_type_id = id
			personCopy.job_role_type_name = name
			setPersonData(personCopy)
		}
	}

	const changeRelationshipData = (id: any, name: any) => {
		if (personData) {
			let personCopy = { ...personData }
			//@ts-ignore
			personCopy.relationship_type_id = id
			personCopy.relationship_type_name = name
			setPersonData(personCopy)
		}
	}

	const changeFamilyData = (key: any, value: any) => {
		if (familyData) {
			let familyCopy = { ...familyData }
			//@ts-ignore
			familyCopy[key] = value
			setFamilyData(familyCopy)
		}
	}

	const removeFamilyMember = () => {
		setBusyRemoving(true)
		personController.deletePerson(personData).then(() => {
			setBusyRemoving(false)
			onAction()
		})
	}

	return (
		<div>
			<Drawer
				contentAriaLabel="Personal Information"
				show={show}
				onClose={onClose}
				size={DrawerSize.Medium}
				contents={
					<div className="medical nobackground prescreen-q prescreen-q-more">
						{error && <Alert text={error} type="error" />}
						{isLoading && <ActionList items={[]} isLoading />}
						{!isLoading && (
							<>
								<Form formValidationTrigger={'onBlur'}>
									{methods => {
										const onSave = () => {
											methods.trigger().then(valid => {
												//Validation
												if (!valid) return

												setError('')
												if (!personData.date_of_birth) {
													setError('Please select or enter a valid Date of Birth')
													return
												}

												if (personData.date_of_birth) {
													var myDate = new Date(personData.date_of_birth)
													var today = new Date()

													if (myDate > today) {
														setError('Please select or enter a valid Date of Birth that is not greater than today')
														return
													}
												}

												if (!personData.relationship_type_name) {
													setError('Please select a valid Relationship')
													return
												}

												if (!person) {
													setBusy(true)
													//Create new
													personController.createPerson(personData).then(response => {
														if (familyData) {
															vaccineController.postPresreeningFamily({
																has_family_members: familyData.has_family_members,
																mit_id: personData.mit_id ?? '',
																mit_medical_patient: familyData.mit_medical_patient,
																nr_comorbidities: familyData.nr_comorbidities,
																person_guid: response.items.person_guid,
																vaccine_availability: familyData.vaccine_availability,
																vaccine_interest_type: familyData.vaccine_interest_type,
																vaccine_location_id: familyData.vaccine_location_id
															})
														}
														setBusy(false)
														onAction()
													})
												} else {
													setBusy(true)
													// Update
													personController.updatePerson(personData).then(response => {
														if (familyData) {
															vaccineController.postPresreeningFamily({
																has_family_members: familyData.has_family_members,
																mit_id: personData.mit_id ?? '',
																mit_medical_patient: familyData.mit_medical_patient,
																nr_comorbidities: familyData.nr_comorbidities,
																person_guid: personData.person_guid,
																vaccine_availability: familyData.vaccine_availability,
																vaccine_interest_type: familyData.vaccine_interest_type,
																vaccine_location_id: familyData.vaccine_location_id
															})
														}
														setBusy(false)
														onAction()
													})
												}
											})
										}

										return (
											<>
												<FormContainer methods={methods} formValidationTrigger="onChange" id="personForm">
													{
														<div className="mt-2">
															{!person && (
																<FormField
																	mainClassName="mitid"
																	label={
																		appText['vaccine.member.fam.mit_question'] ??
																		'Do they have an MIT ID Number and do you know it?'
																	}
																	labelDisplay="nextto"
																	id="excistingId"
																	editor="toggle"
																	choice={knowMITID ? 'Positive' : 'Negative'}
																	properties={{
																		showBothOptions: true,
																		positiveLabel: 'Yes',
																		negativeLabel: 'No',
																		onStateChanged: (e: any) => {
																			setKnowMITID(e.choice === 'Positive')
																		}
																	}}
																/>
															)}
														</div>
													}
													{person ? (
														<FormField
															editor="textbox"
															id="mitid"
															readonly
															label="MIT ID"
															labelDisplay="nextto"
															value={personData.mit_id}
														/>
													) : (
														<>
															{knowMITID && (
																<FormField
																	editor="textbox"
																	id="mitid"
																	registerObject={{
																		pattern: { value: ValidationUtility.mitIdPattern, message: 'ID format' },
																	}}
																	properties={{ type: 'number' }}
																	label="MIT ID (optional)"
																	labelDisplay="nextto"
																	onChange={(e: any) => changePersonData('mit_id', e.currentTarget.value)}
																/>
															)}
														</>
													)}
													<FormField
														editor="textbox"
														id="firstName"
														label="First Name"
														labelDisplay="nextto"
														value={personData?.first_name}
														onChange={(e: any) => changePersonData('first_name', e.currentTarget.value)}
														required
													/>
													<FormField
														editor="textbox"
														id="LastName"
														label="Last Name"
														labelDisplay="nextto"
														value={personData?.last_name}
														onChange={(e: any) => changePersonData('last_name', e.currentTarget.value)}
														required
													/>
													<div className="form-group row calendar">
														<label htmlFor="dob" className="col-sm-4 text-right col-form-label">Date of Birth</label>
														<div className="col-sm-8">
															<CalendarInput
																id="dob"
																value={personData?.date_of_birth ? personData?.date_of_birth : null}
																onChange={(e: string) => changePersonData('date_of_birth', e)}
															/>
														</div>
													</div>
													<FormField
														mainClassName="phone"
														editor="textbox"
														id="phone"
														label="Phone"
														labelDisplay="nextto"
														onChange={(e: any) => changePersonData('best_phone', e.currentTarget.value)}
														value={personData?.best_phone}
														required
													/>
													<FormField
														mainClassName="email"
														editor="textbox"
														registerObject={{
															pattern: { value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*[ ]*$/, message: 'Email format' },
															required: true
														}}
														id="email"
														label="Email"
														labelDisplay="nextto"
														onChange={(e: any) => changePersonData('email_address', e.currentTarget.value)}
														value={personData?.email_address}
														required
													/>
													<FormField
														mainClassName="relationship"
														editor="dropdown"
														id="relationship"
														label="Relationship"
														options={getRelationships()}
														onChange={(e: any) => {
															changeRelationshipData(e.id, e.text)
														}}
														labelDisplay="nextto"
														value={personData?.relationship_type_name}
													/>
													<FormField
														editor="dropdown"
														id="job"
														label="Job Role"
														options={getJobs()}
														onChange={(e: any) => {
															changeJobData(e.id, e.text)
														}}
														value={personData.job_role_type_name}
														labelDisplay="nextto"
														mainClassName="role"
													/>
													<div className="row">
														<div className="col-sm-4 text-right geenlinks">
															<a
																href={
																	appText['vaccine.screen.sources.roles.url'] ??
																	'https://covidapps.mit.edu/vaccine-information#job-families'
																}
																rel="noopener noreferrer"
																target="_blank"
																className="greenlinks"
															>
																{appText['vaccine.screen.sources.roles.text'] ?? 'Job Families'}
																<span className="sr-only" >, opens in new browser tab or window.</span>
															</a>
														</div>
													</div>
													<FormField
														mainClassName="medical-patient"
														editor="toggle"
														properties={{
															showBothOptions: true,
															positiveLabel: 'Yes',
															negativeLabel: 'No',
															onStateChanged: (e: any) =>
																changeFamilyData('mit_medical_patient', e.choice === 'Positive' ? true : false)
														}}
														id="medicalPatient"
														choice={familyData.mit_medical_patient ? 'Positive' : 'Negative'}
														label={appText['vaccine.screen.fam.question_patient'] ?? 'Are they MIT Medical patient?'}
														labelDisplay="nextto"
													/>
													{!familyData.mit_medical_patient && (
														<>
															<FormField
																mainClassName="comorb"
																editor="dropdown"
																id="co-morbidities"
																value={getComorbidityTypeValue()}
																options={getComorbidities()}
																label={
																	appText['vaccine.screen.fam.question_comorbidities'] ??
																	'Do you have a condition recognized as causing an increased risk of illness from the virus that causes COVID-19?'
																}
																labelDisplay="nextto"
															/>
															<div className="row">
																<div className="col-sm-4 text-right geenlinks">
																	<a
																		href={
																			appText['vaccine.screen.sources1.url'] ??
																			'https://covidapps.mit.edu/vaccine-information#comorbidities'
																		}
																		rel="noopener noreferrer"
																		target="_blank"
																		className="greenlinks"
																	>
																		{appText['vaccine.screen.sources1.text'] ?? 'CDC Co-morbids list'}
																		<span className="sr-only" >, opens in new browser tab or window.</span>
																	</a>
																</div>
															</div>
														</>
													)}
													<br/>
													<br/>
													<div className="row">
														<div className="extra-q col-sm-7">
															<div>
																<span>{appText['vaccine.screen.fam.question_when'] ??
																'How quickly would you come in to get it?'}</span>
															</div>
															<div className="light-text">
																<span>If the vaccine was available at MIT Medical for your group</span>
															</div>
														</div>
														<FormField
															mainClassName="col-sm-5 family-dropdown-container"
															editor="dropdown"
															id="quickly"
															options={getAvailablity()}
															value={getAvailabilityTypeValue()}
															onChange={(e: any) => changeFamilyData('vaccine_availability', e.id)}
															labelDisplay="nextto"
														/>
													</div>

													<div className="row pt-4 pb-4">
														<div className="col-12 text-center">
															<Button
																text=""
																icon="chevron-left"
																onClick={onBack}
																type={ButtonType.Ghost | ButtonType.Secondary}
																title="back"
															/>
															<Button
																text="Save"
																isBusy={isBusy}
																onClick={() => onSave()}
																state={isBusy ? ButtonState.Disabled : ButtonState.Enabled}
															/>
															{person && (
																<span className="delete">
																<Button
																	state={isBusyRemoving ? ButtonState.Disabled : ButtonState.Enabled}
																	isBusy={isBusyRemoving}
																	text="Remove"
																	onClick={() => removeFamilyMember()}
																	type={ButtonType.Ghost | ButtonType.Secondary}
																/>
															</span>
															)}
														</div>
														<Spacer size="5"/>
													</div>
												</FormContainer>

												<hr />
												<div className="container text-center">
													<p>
														Eligibility for minors may vary base on available vaccines. A household family member must
														be at least 16 years of age to receive vaccine.
													</p>
												</div>
											</>
										)
									}}
								</Form>
							</>
						)}
					</div>
				}
				footer={[]}
				header={<Text content={`Household Family Member`} type={TextType.Heading4} />}
				headerContent={
					<Text
						content={
							appText['vaccine.member.title'] ??
							'Please enter information for your household family member.'
						}
					/>
				}
				type={DrawerType.Right}
			/>
		</div>
	)
}

export default PersonInfo
