import * as React from 'react'
import { FC/* , useEffect */, useState } from 'react'
import { useStoreActions, useStoreState } from '../../store/hooks'
import { withRouter } from 'react-router'
import * as pdfjsLib from 'pdfjs-dist/es5/build/pdf'
import pdfjsWorker from 'pdfjs-dist/es5/build/pdf.worker.entry'

import {
	ActionList,
	// Button,
	ButtonType,
	// Alert,
	Form,
	FormContainer,
	FormField,
	// CalendarInput,
	ButtonState,
	ImageUpload,
	// DropdownItemProps,
	Spacer,
	Text,
	TextType,
	TemplateModal,
	TemplateModalSize,
	TemplateModalAlignment,
	ButtonBar,
	ButtonBarType,
	Alert
} from '@mit/hui'
import fluShotController, {
	// VaccineCompanyInterface,
	FluShotProof
	// VaccineReviewReasons
} from '../../api/controller/FluShotController'
// import vaccineController, {
// 	VaccineCompanyInterface,
// 	VaccineProofShot,
// 	VaccineReviewReasons
// } from '../../api/controller/VaccineController'
import { ActiveDrawer } from '../../store/models/global'
import moment from 'moment'
// import { COVID_TEXTS } from '../../common/Texts'
import Resizer from 'react-image-file-resizer'
import { ImageCrop } from '../../common/ImageCrop'

interface FlushotProofEditProps {}

// interface VaccineProof {
// 	type: number
// 	company: string
// 	proof_guid?: string
// 	shots: VaccineProofShot[]
// }

const FlushotProofEditComponent: FC<FlushotProofEditProps> = () => {
	const [isLoading, setLoading] = useState<boolean>(false)
	const [isUploading, setUploading] = useState<boolean>(false)
	const [fileChanged, setFileChanged] = useState<boolean>(false)
	// const [hasImage, setHasImage] = useState<boolean>(false)
	const [displayData, setDisplayData] = useState<any>({})
	const [showPreview, setShowPreview] = useState<boolean>(false)
	const [showCropModal, setShowCropModal] = useState<boolean>(false)
	const [file, setFile] = useState<any>()
	const [fluShotData, setFluShotData] = useState<FluShotProof>()
	const [isBusy, setBusy] = useState<boolean>(false)
	const { appText, isFluShotProofUploaded, } = useStoreState(store => store.globalModel)
	const { setReloadTestResults, setFluShotProofUploaded } = useStoreActions(store => store.globalModel)
	const [isImgPortrait, setIsImagePortrait] = useState<boolean>(false)
	const [hasImage, setHasImage] = useState(false)
	const [errorAlerts, setErrorAlerts] = useState<string[]>([])

	const {
		setActiveDrawer
		// setCurrentVaccineProof,
		// setVaccineProofList,
		// setReloadVaccineData,
		// setVaccineReceived
	} = useStoreActions(store => store.globalModel)

	const imageUpload = async (fileData: any) => {
		if (fileData.length >= 1 && fileData[0].type !== 'application/pdf') {
			setUploading(true)
			const file = fileData[0]
			const image = await resizeFile(file)
			// Check if image is portrait
			const {naturalWidth, naturalHeight} = getImgDimension(image);
			if (naturalWidth < naturalHeight) {
				setIsImagePortrait(true)
			} else {
				setIsImagePortrait(false)
			}
			// end check
			setFileChanged(true)
			setHasImage(true)
			setDisplayData({
				image_url: image
			})
			setShowPreview(true)
			// setShowCropModal(true)
		} else {
			setUploading(true)
			const file = fileData[0]
			var fileReader = new FileReader()
			pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker

			fileReader.onload = async function(e) {
				var typedarray = new Uint8Array(e.target!.result as ArrayBuffer)

				const loadingTask = pdfjsLib.getDocument(typedarray)
				const pdfDoc = await loadingTask.promise

				const page = await pdfDoc.getPage(1)
				const viewport = page.getViewport({ scale: 1.0 })

				var canvas = document.createElement('canvas'),
					ctx = canvas.getContext('2d')
				var renderContext = {
					canvasContext: ctx as object,
					viewport: viewport
				}

				canvas.height = viewport.height
				canvas.width = viewport.width

				const renderTask = page.render(renderContext)
				await renderTask.promise

				const image = canvas.toDataURL('image/jpeg')
				// Check if image is portrait
				const {naturalWidth, naturalHeight} = getImgDimension(image);
				if (naturalWidth < naturalHeight) {
					setIsImagePortrait(true)
				} else {
					setIsImagePortrait(false)
				}
				// end check
				setFileChanged(true)
				// setHasImage(true)
				setDisplayData({
					image_url: image
				})
				setShowPreview(true)
				// setShowCropModal(true)
			}

			fileReader.readAsArrayBuffer(file)
		}
	}

	const getImgDimension = (src: any) => {
    var i = new Image();
    i.src = src;
    return {
        naturalWidth: i.width,
        naturalHeight: i.height
    };
	}

	const resizeFile = (file: any) => {
		return new Promise(resolve => {
			Resizer.imageFileResizer(
				file, // Is the file of the image which will resized
				800, // Is the maxWidth of the resized new image.
				1000, // Is the maxHeight of the resized new image.
				'JPEG', // Is the compressFormat of the resized new image.
				100, // Is the quality of the resized new image.
				0, // Is the degree of clockwise rotation to apply to uploaded image.
				uri => { // Is the callBack function of the resized new image URI.
					resolve(uri)
				},
				'base64' // Is the output type of the resized new image.
			)
		})
	}

	const onSaveImage = (): void => {
		const blob = b64toBlob(displayData.image_url)
		setFile(blob)
		setShowPreview(false)
		setUploading(false)
		saveImage()
	}

	const saveImage = (): void => {
		fluShotController.getUploadUrl().then(response => {
			let fluShot: FluShotProof = {
				externalLocation: fluShotData ? fluShotData.externalLocation : '',
				dateReceived: fluShotData ? fluShotData.dateReceived : '',
				imageKey: response.data.imageKey,
				imageUrl: response.data.imageUrl,
				// id: fluShotData ? fluShotData.id : '',
				name: fluShotData ? fluShotData.name : '',
				provider: fluShotData ? fluShotData.provider : ''
			}
			setFluShotData(fluShot)
		})
	}

	function b64toBlob(dataURI: any) {
		const byteString = atob(dataURI.split(',')[1])
		const ab = new ArrayBuffer(byteString.length)
		const ia = new Uint8Array(ab)

		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i)
		}
		return new Blob([ab], { type: 'image/jpeg' })
	}

	const closeImageCropped = (): void => {
		setShowCropModal(false)
		setUploading(false)
		setFileChanged(false)
		setFile('')
		setDisplayData({
			image_url: ''
		})
	}

	const handleImageCropped = (blob: Blob, previewUrl: string): void => {
		setFile(blob)
		setDisplayData({
			image_url: previewUrl
		})
		setShowCropModal(false)
		setUploading(false)
		saveImage()
	}

	const changeFluShotData = (key: any, value: any) => {
		let localFluShotData = fluShotData

		if (!localFluShotData) {
			localFluShotData = { name: '', provider: '', externalLocation: '', dateReceived: '' }
		}

		let fluShotDataCopy = { ...localFluShotData }

		// @ts-ignore
		fluShotDataCopy[key] = value
		setFluShotData(fluShotDataCopy)
	}

	return (
		<>
			{isLoading && <ActionList items={[]} isLoading />}
			{isFluShotProofUploaded && (
				<>
					<Spacer size='4' />
					<h2 style={{textAlign: 'center'}}>Your flu shot information was uploaded successfully</h2>
				</>
			)}
			{!isLoading && !isFluShotProofUploaded && (
				<Form formValidationTrigger="onSubmit">
					{methods => {
						const onSave = () => {
							// setLoading(true)
							methods.trigger().then(valid => {
								if (!valid) return
								const errorsArr: string[] = []
								if (!hasImage) {
									// let drawerBody = document.getElementsByClassName('drawer')[0]
									// drawerBody.scroll(0, 0)
									errorsArr.push('Please select a valid image')
									// setLoading(false)
								}
								if (!fluShotData) {
									errorsArr.push('Please complete all the required fields before submitting')
								} else {
									const {name, provider ,externalLocation , dateReceived} = fluShotData
									if (name.trim() === '') {
										errorsArr.push('Please enter a valid flushot name')
									}
									if (provider.trim() === '') {
										errorsArr.push('Please enter a valid flushot provider')
									}
									if (externalLocation.trim() === '') {
										errorsArr.push('Please enter a valid location')
									}
									if (dateReceived.trim() === '') {
										errorsArr.push('Please select a valid date')
									}
								}
								if (errorsArr.length > 0) {
									setErrorAlerts(errorsArr)
									// setLoading(false)
									return
								}
								// Form Validation here

								// let formData = methods.getValues()

								// setBusy(true)
								// if (fluShotData) {
								// 	fluShotController.postFluShotProofUpload(fluShotData)
								// }

								if (file && fileChanged) {
									setLoading(true)
									fluShotController.uploadFluShotProof(fluShotData?.imageUrl, file, '').then(resp => {
										setBusy(false)
										if (resp) {
											fluShotController.postFluShotProofUpload({...fluShotData, imageUrl: ''}).then(resp => {
												if (resp.error) {
													console.log('Error uploading data')
												} else if (resp.data) {
													setReloadTestResults(true)
													setFluShotProofUploaded(true)
													setLoading(false)
													setActiveDrawer(ActiveDrawer.None)
												} else { // error
													// setUploadError(true)
													// console.log('There was an error uploading the data')
												}
											})
										} else {
											// if no response from server
											console.log('Error: Failed to upload picture. Please try to re-upload the image')
											// let drawerBody = document.getElementsByClassName('drawer')[0]
											// drawerBody.scroll(0, 0)
											setBusy(false)
											setLoading(false)
											// setError(['Failed to upload picture. Please try to re-upload the image'])
										}
									})
									.catch(err => console.log(err))
								} else {
									// if no file data or file data hasn't changed
									setBusy(false)
									// setReloadVaccineData(true) // Todo for flushot -> global state
									// setVaccineReceived('') // Todo for flushot -> global state
									setActiveDrawer(ActiveDrawer.None)
								}
							})
						}

						return (
							<>
								{/* {message.text && <Alert {...message} />} */}
								{errorAlerts &&
									errorAlerts.length > 0 &&
									errorAlerts.map((er, index) => (
										<div key={`er-${index}`}>
											<Alert text={er} type="error" />
										</div>
									))}
								<div style={{margin: 'auto', maxWidth: isImgPortrait? '70%': '100%' }}>
									<ImageUpload
										onChange={file => {
											imageUpload(file)
										}}
										text={appText['flu.pov.detail.photo.caption']}
										label={'Proof of flu shot'}
										noClear
										imageUrl={displayData.image_url}
										isLoading={isUploading}
										editButtonAriaLabel={'Edit Uploaded image.'}
										displayButtons={true}
										// displayButtons={
										// 	(vaccineReceived === 'partially' && fluShotData?.status !== 'A') ||
										// 	(vaccineReceived === 'fully' && doseTwoData?.status !== 'A')
										// }
									/>
								</div>
								<TemplateModal
									name="imagePreview"
									show={showPreview}
									autoHeight
									// // onClose={() => onCloseCropModal()}
									bodyAlignment={TemplateModalAlignment.Center}
									size={TemplateModalSize.Large}
									header={<Text content={'Image preview'} type={TextType.Heading2} />}
									body={
										<>
											<img src={displayData.image_url} style={{ maxWidth: '100%', maxHeight: '65vh' }} alt="" />
										</>
									}
									footer={
										<>
											<ButtonBar
												buttons={[
													{
														padded: true,
														icon: 'crop',
														type: ButtonType.Ghost | ButtonType.Secondary,
														text: 'Crop Image',
														onClick: () => {
															setShowCropModal(true)
															setShowPreview(false)
														},
														customAriaLabel: 'Crop Image.'
													},
													{
														padded: true,
														icon: 'save',
														text: 'Save Image',
														onClick: () => onSaveImage(),
														customAriaLabel: 'Save Image.'
													}
												]}
											/>
											{/* <div  className="mobile-only" style={{paddingBottom: '3rem', width: '100%'}} /> */}
											{/* {mobile && <div style={{ paddingBottom: '3rem', width: '100%' }} />} */}
										</>
									}
								/>
								<ImageCrop
									showCropModal={showCropModal}
									uploadImage={displayData.image_url}
									onCloseCropModal={closeImageCropped}
									handleCropImage={handleImageCropped}
								/>
								<Spacer size="2" />
								<FormContainer methods={methods} formValidationTrigger="onSubmit" id="programForm">
									<FormField
										editor="textbox"
										label="Flu shot Name"
										required
										labelDisplay="nextto"
										id="name"
										placeholder="Flu shot name"
										onChange={(e: any) => changeFluShotData('name', e.currentTarget.value)}
									/>
									<FormField
										editor="calendar"
										label="Date received"
										maxDate={{
											year: parseInt(moment().format('YYYY')),
											month: parseInt(moment().format('M')),
											day: parseInt(moment().format('D'))
										}}
										required
										labelDisplay="nextto"
										id="dateReceived"
										value={fluShotData?.dateReceived ? fluShotData?.dateReceived : null}
										onChange={(e: string) => changeFluShotData('dateReceived', e)}
									/>
									<FormField
										editor="textbox"
										label="Provider"
										required
										labelDisplay="nextto"
										id="provider"
										placeholder="Provider"
										onChange={(e: any) => changeFluShotData('provider', e.currentTarget.value)}
									/>
									<FormField
										editor="textbox"
										label="Location"
										required
										labelDisplay="nextto"
										id="externalLocation"
										placeholder="Location"
										onChange={(e: any) => changeFluShotData('externalLocation', e.currentTarget.value)}
									/>
								</FormContainer>
								<div className="text-right">
									<ButtonBar
										type={ButtonBarType.Padded}
										buttons={[
											{
												text: 'Save',
												type: ButtonType.Primary,
												onClick: () => onSave(),
												isBusy: isBusy,
												state: isBusy ? ButtonState.Disabled : ButtonState.Enabled
											}
										]}
									/>
								</div>
							</>
						)
					}}
				</Form>
			)}
		</>
	)
}

const FlushotProofEdit = withRouter(FlushotProofEditComponent)
export default FlushotProofEdit
