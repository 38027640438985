import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../store/hooks'
import { withRouter } from 'react-router'
import {
		// VaccineCompanyResponse,
		// VaccineTypeResponse,
		VaccineCompanyInterface
	} from '../../api/controller/VaccineController'
import { ActiveDrawer } from '../../store/models/global'
// import VaccineProofList from './VaccineProofList'
// import VaccineProofEdit from './VaccineProofEdit'
import { ActionList, ActionListType, ListItemProps, Text } from '@mit/hui'

interface VaccineManufacturerProps {}

const VaccineManufacturerComponent: FC<VaccineManufacturerProps> = () => {
	const [isLoading, setIsLoading] = useState(false)
	const { vaccineCompanies, vaccineReceived } = useStoreState(store => store.globalModel)
	const { setActiveDrawer, setManufacturer, setVaccineProofList } = useStoreActions(store => store.globalModel)

	useEffect(() => {
		setIsLoading(true)
		setVaccineProofList(undefined);
        setTimeout(() => {
            setIsLoading(false)
        }, 1000);
	}, [setVaccineProofList])

    const chooseManufacturer = (manufacturer: string) => {
        const company = vaccineCompanies?.find((company: any) => company.company_name === manufacturer)

        if (company) {
			setManufacturer(company)
			setActiveDrawer(ActiveDrawer.VaccineProofEdit)
        }
    }

	let manufacturerData: ListItemProps[] = [];

	// vaccineCompanies?.map((company: VaccineCompanyInterface) =>
	// 	manufacturerData.push({ icon: 'syringe', text: company.company_name, onClick: () => chooseManufacturer(company.company_name) })
	// )
	if ( ['fully', 'booster'].includes(vaccineReceived)) {
		vaccineCompanies?.map((company: VaccineCompanyInterface) =>
			manufacturerData.push({ icon: 'syringe', text: company.company_name, onClick: () => chooseManufacturer(company.company_name) })
		)
	}

	if (vaccineReceived === 'partially') {
		const twoShotCompanies = vaccineCompanies?.filter((company: VaccineCompanyInterface) => company.number_of_shots !== 1)
		twoShotCompanies?.forEach(company => {
			manufacturerData.push({ icon: 'syringe', text: company.company_name, onClick: () => chooseManufacturer(company.company_name) })
		})
	}

	return (
		<>
			{isLoading ? (
				<ActionList items={[]} isLoading compact type={ActionListType.Flush} />
			) : (
				<>
                    <Text content={'Which vaccination manufacturer?'} />
                    <ActionList items={manufacturerData} type={ActionListType.Normal} />
					<br/><br/><br/><br/><br/>
				</>
			)}
		</>
	)
}

const VaccineManufacturer = withRouter(VaccineManufacturerComponent)
export default VaccineManufacturer
