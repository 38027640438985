import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import dataController from '../../api/controller/DataController'
import { COVID_TEXTS } from '../../common/Texts'
import { Barcode } from '@mit/hui'
import { useStoreState } from '../../store/hooks'

interface BarcodeProps {}

const BarcodeComponent: FC<BarcodeProps> = () => {
	const [imageUrl, setImageUrl] = useState('')
	const [isLoading, setIsLoading] = useState(true)
	const [barcodeError, setBarcodeError] = useState('')
	// const [displayName, setDisplayName] = useState('')
	const { appText, profile } = useStoreState(store => store.globalModel)

	useEffect(() => {
		const abortController = new AbortController()
		dataController.fetchBarcode(abortController.signal).then(
			(url: any) => {
				setImageUrl(url.value)
				setIsLoading(false)
			},
			(error: any) => {
				setBarcodeError(
					`Your barcode could not be loaded at this time. If the problem persists, please contact ${
						appText[COVID_TEXTS['covid19.pass.help_email']]
					}.`
				)
				setIsLoading(false)
			}
		)

		return () => {
			abortController.abort()
		}
	}, [appText])

	return (
		<Barcode
			imageUrl={imageUrl}
			label={profile && profile.display_name ? profile.display_name : ''}
			barcodeError={barcodeError}
			isLoading={isLoading}
			description={"Please present your barcode to MIT Medical upon entry"}
		/>
	)
}

const MyBarcode = BarcodeComponent
export default MyBarcode
