import * as React from 'react'
import { useState, useEffect } from 'react'
// import { RequirementIds, StatusHelper } from '../../types/CovidStatusModel'
import { useStoreActions, useStoreState } from '../../store/hooks'
import CovidText, { COVID_TEXTS } from '../../common/Texts'
// import dataController, { VaccineStatusResponse } from '../../api/controller/DataController'
// import moment, { now } from 'moment'
// import
// 	vaccineController,
// 	{
// 		VaccineAppointmentInterface,
// 		VaccineScheduleInterface,
// 		VaccineStandbyResponse,
// 		VaccineProofShot,
// 		VaccineProofResponse,
// 		VaccineProofInterface,
// 		VaccinePrescreeingUpdate,
// 		VaccineCompanyInterface
// 	} from '../../api/controller/VaccineController'
import { Text, Spacer, ToastContainer, ToastContainerProps, ToastPosition } from '@mit/hui'
// import NoVaccineView from './NoVaccineView';
import StatusTextBlock from '../vaccine/StatusTextBlock'
// import { COVID_TEXTS } from '../../common/Texts'
// import ExemptionView from './ExemptionView';
import moment from 'moment'

// interface ProofShot extends VaccineProofShot {
// 	title?: string | any
// 	class?: string | any
// 	icon?: string | any
// 	icon_type?: string | any
// 	icon_color?: string | any
// }

type CovidSectionProps = {
	isLoading: boolean
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const CovidSection = ({ isLoading, setIsLoading }: CovidSectionProps) => {
	const {
		// appText,
		// statusModel,
		// features,
		/* tabState,  */ vaccineStatus,
		// profile,
		// vaccineCompanies,
		// reason,
		// reloadVaccineData,
		// vaccineReceived,
		// mobile,
		// isFluShotProofUploaded,
		// activeDrawer,
		isExemptionReqSubmitted,
		vaccineResultsList,
		appText
	} = useStoreState(store => store.globalModel)
	const {
		// setActiveDrawer,
		// setTabState,
		// setVaccineStatus,
		// setVaccineReceived,
		// setCurrentVaccineProof,
		setVaccineProofList,
		// setManufacturer,
		// setReasons,
		setReloadVaccineData,
		// setPreScreeningStepperIndex,
		// setFluShotProofUploaded,
		setIsExemptionReqSubmitted
	} = useStoreActions(store => store.globalModel)
	// const [preScreeningOptions, setPreScreeningOptions] = useState<'not yet' | 'no' | 'yes' | ''>('')
	// const [vaccineEnquiry, setVaccineEnquiry] = useState<string>('')
	const [vaccinationReason, setVaccinationReason] = useState<boolean>(false)
	// const [notEligibleReasons, setNotEligibleReasons] = useState<any>()
	// const [exemptionType, setExemptionType] = useState<string>('')
	// const [exemptionReason, setExemptionReason] = useState<string>('')
	// const [externalVaccine, setExternalVaccine] = useState<boolean>(false)
	// const [isLoading, setIsLoading] = useState(false)
	// const [proofStatus, setProofStatus] = useState<VaccineProofInterface[]>([])
	// const [oneDoseVaccine, setOneDoseVaccine] = useState<any>([])
	// const [isDownloading, setDownloading] = useState(false)
	// const [standbyStatus, setStandbyStatus] = useState<VaccineScheduleInterface>()
	// const [standbyData, setStandbyData] = useState<any>()
	// const [appointment, setAppointment] = useState<VaccineAppointmentInterface>()

	// const downloadFile = (data: any, filename: any, mime: any) => {
	// 	// It is necessary to create a new blob object with mime-type explicitly set
	// 	// otherwise only Chrome works like it should
	// 	const blob = new Blob([data], {type: mime || 'application/octet-stream'});
	// 	//@ts-ignore
	// 	if (typeof window.navigator.msSaveBlob !== 'undefined') {
	// 	  // IE doesn't allow using a blob object directly as link href.
	// 	  // Workaround for "HTML7007: One or more blob URLs were
	// 	  // revoked by closing the blob for which they were created.
	// 	  // These URLs will no longer resolve as the data backing
	// 	  // the URL has been freed."
	// 	  //@ts-ignore
	// 	  window.navigator.msSaveBlob(blob, filename);
	// 	  return;
	// 	}
	// 	// Other browsers
	// 	// Create a link pointing to the ObjectURL containing the blob
	// 	const blobURL = window.URL.createObjectURL(blob);
	// 	const tempLink = document.createElement('a');
	// 	tempLink.style.display = 'none';
	// 	tempLink.href = blobURL;
	// 	tempLink.setAttribute('download', filename);
	// 	// Safari thinks _blank anchor are pop ups. We only want to set _blank
	// 	// target if the browser does not support the HTML5 download attribute.
	// 	// This allows you to download files in desktop safari if pop up blocking
	// 	// is enabled.
	// 	if (typeof tempLink.download === 'undefined') {
	// 	  tempLink.setAttribute('target', '_blank');
	// 	}
	// 	document.body.appendChild(tempLink);
	// 	tempLink.click();
	// 	document.body.removeChild(tempLink);
	// 	setTimeout(() => {
	// 	  // For Firefox it is necessary to delay revoking the ObjectURL
	// 	  window.URL.revokeObjectURL(blobURL);
	// 	}, 100);
	//   }

	// const getApproverMessage: any = (approverMessage: string, approverMessageId: number) => {
	// 	if (approverMessageId) {
	// 		let reject = reason.find((item: any) => item.reason_id === approverMessageId)
	// 		return reject.short_text
	// 	} else {
	// 		return approverMessage
	// 	}
	// }

	// const downloadClicked = (proof_guid: string) => {
	// 	setDownloading(true)
	// 	dataController.fetchMedicalResultPDF(false, false, true, proof_guid).then(resp => {
	// 		setDownloading(false)
	// 		downloadFile(resp.value, `vaccine_proof_${moment().format('YYYY-MM-DD')}.pdf`, 'application/pdf')
	// 	})
	// }

	// const getStatusObj = (status: string | any) => {
	// 	let vaccineStatus: any = {
	// 		n: function() {
	// 			return { title: 'None', class: 'status-none', icon: 'shield', icon_type: 'light', icon_color: 'grey' }
	// 		},
	// 		p: function() {
	// 			return { title: 'Saved', class: 'status-pending', icon: 'shield-alt', icon_type: 'solid', icon_color: 'orange' }
	// 		},
	// 		a: function() {
	// 			return {
	// 				title: 'Done',
	// 				class: 'status-approved',
	// 				icon: 'shield-check',
	// 				icon_type: 'solid',
	// 				icon_color: 'green'
	// 			}
	// 		},
	// 		r: function() {
	// 			return { title: 'Error', class: 'status-rejected', icon: 'shield', icon_type: 'solid', icon_color: 'red' }
	// 		}
	// 	}
	// 	return vaccineStatus[status.toLowerCase()]()
	// }

	// const getVaccineReceived = (vaccineReceived: string | any) => {
	// 	let vaccineStatus: any = {
	// 		'no': function () { return 3 },
	// 		'partially': function () { return 2 },
	// 		'fully': function () { return 1 }
	// 	}
	// 	return vaccineStatus[vaccineReceived.toLowerCase()]();
	// }

	// const updatePrescreening = (received: string, reasonId: string, reason?: string) => {
	// 	let body: VaccinePrescreeingUpdate = {
	// 		received: getVaccineReceived(received),
	// 		not_eligible_reason: reason ? parseInt(reasonId) : 0
	// 	}
	// 	vaccineController.putPrescreening(body);

	// 	if (received === 'partially') {
	// 		setPreScreeningOptions('');
	// 		setVaccineReceived(received);
	// 		setPreScreeningOptions('yes');
	// 		setVaccineEnquiry('');
	// 		setTimeout(() => {
	// 			let uploadBtn = document.getElementById('upload-proof')
	// 			uploadBtn?.focus()
	// 		}, 0);
	// 	}

	// 	if (received === 'fully') {
	// 		setPreScreeningOptions('');
	// 		setVaccineReceived(received);
	// 		setPreScreeningOptions('yes');
	// 		setVaccineEnquiry('');
	// 		setTimeout(() => {
	// 			let uploadBtn = document.getElementById('upload-proof')
	// 			console.log('key::uploadBtn', uploadBtn)
	// 			uploadBtn?.focus()
	// 		}, 0);
	// 	}

	// 	if (received === 'no') {
	// 		console.log('key::reasonBtn', 'NO')
	// 		if (reason) setVaccineEnquiry(reason);
	// 		setVaccinationReason(true);
	// 		setTimeout(() => {
	// 			let reasonBtn: HTMLAnchorElement | null  = document.querySelector('#custom-btn')
	// 			reasonBtn?.focus()
	// 		}, 0);
	// 	}
	// }

	// const selectedVaccineProof = (proof_guid: string | any, shot_number: number) => {
	// 	if (proof_guid) {
	// 		const selectedProof = proofStatus.find((proof: any) => proof.proof_guid === proof_guid)
	// 		if (shot_number === 1) setVaccineReceived('partially');
	// 		if (shot_number === 2) setVaccineReceived('fully');

	// 		if (selectedProof) {
	// 			let vaccineStatus = 'N';
	// 			let approverMessage: any = '';
	// 			let approverReasonId: any = null;

	// 			for (let i = 0; i < selectedProof.shots.length; i++) {
	// 				if (selectedProof.shots[i].status === 'R') {
	// 					vaccineStatus = 'R'
	// 					approverMessage = selectedProof.shots[i].approver_message
	// 					approverReasonId = selectedProof.shots[i].approver_reason_id
	// 					break;
	// 				} else if (selectedProof.shots[i].status === 'P') {
	// 					vaccineStatus = 'P'
	// 					break;
	// 				} else {
	// 					vaccineStatus = 'A'
	// 				}
	// 			}

	// 			selectedProof.status = vaccineStatus;
	// 			selectedProof.approver_message = approverMessage;
	// 			selectedProof.approver_reason_id = approverReasonId;

	// 			let company = vaccineCompanies?.find((itm: any) => itm.medical_vaccine_company_guid == selectedProof.company)
	// 			setCurrentVaccineProof(selectedProof)
	// 			setManufacturer(company)
	// 			setActiveDrawer(ActiveDrawer.VaccineProofEdit)
	// 		}
	// 	}
	// }

	const formatDate = (date: string | undefined) => {
		if (date) {
			return moment(date)
				.utc()
				.format('LL')
			// return moment(date).format('MMM DD, YYYY')
		}
		return ''
	}

	const defaultListData: ToastContainerProps = {
		position: ToastPosition.TopCenter,
		toasts: [
			{
				icon: {
					type: 'light',
					icon: 'check',
					padded: false
				},
				title: '',
				subtext: '',
				message: 'Thank you - your response has been saved.',
				show: vaccinationReason,
				onClose: () => setVaccinationReason(false)
			},
			{
				icon: {
					type: 'light',
					icon: 'check',
					padded: false
				},
				title: '',
				subtext: '',
				message: 'Exemtion request has been submitted.',
				show: isExemptionReqSubmitted,
				onClose: () => setIsExemptionReqSubmitted(false)
			}
		]
	}

	useEffect(() => {
		// vaccineController.fetchNotEligibleReasons().then((response: any) => {
		// 	setNotEligibleReasons(() => response.data)
		// })
		// setOneDoseVaccine(
		// 	vaccineCompanies
		// 		?.filter((company: VaccineCompanyInterface) => company.number_of_shots === 1)
		// 		.map((company: VaccineCompanyInterface) => {
		// 			return company.company_name
		// 		})
		// )

		if (!vaccineStatus) {
			return
		}

		// if (vaccineStatus['pre_screening_received']) {
		// 	if (vaccineStatus.pre_screening_received === 1) {
		// 		setVaccineReceived('fully');
		// 		setPreScreeningOptions('yes');
		// 	}
		// 	if (vaccineStatus.pre_screening_received === 2) {
		// 		setVaccineReceived('partially');
		// 		setPreScreeningOptions('yes');
		// 	}
		// 	if (vaccineStatus.pre_screening_received === 3) {
		// 		setVaccineReceived('no');
		// 		setPreScreeningOptions('no');
		// 	}
		// }

		// vaccineController.fetchStandby().then((response: VaccineStandbyResponse) => {
		// 	if (response.standby && response.standby.length > 0) {
		// 		setStandbyStatus(response.standby[0].schedule)
		// 		setStandbyData(response.standby[response.standby.length - 1])
		// 	}
		// })

		if (vaccineStatus.vaccine_proofs.length === 0) {
			// setExternalVaccine(false)
			setIsLoading(false)
			setReloadVaccineData(false)
			setVaccineProofList(undefined)
			return
		}

		// setExternalVaccine(true)

		// let proofs = vaccineStatus.vaccine_proofs.map((proof: any) => {
		// 	// let vaccineStatus = 'None';

		// 	if (proof.shots.length > 0) {
		// 		let shots = proof.shots.map((shot: ProofShot) => {
		// 			return {
		// 				...shot,
		// 				...getStatusObj(shot.status)
		// 			}
		// 		})
		// 		let status = 'N'
		// 		let approver_message = ''
		// 		let approverReasonId: any = null

		// 		for (let i = 0; i < proof.shots.length; i++) {
		// 			if (proof.shots[i].status === 'R') {
		// 				status = 'R'
		// 				approver_message = proof.shots[i].approver_message
		// 				approverReasonId = proof.shots[i].approver_reason_id
		// 				break
		// 			} else if (proof.shots[i].status === 'P') {
		// 				status = 'P'
		// 				break
		// 			} else {
		// 				status = 'A'
		// 			}
		// 		}
		// 		proof.status = status
		// 		proof.approver_message = approver_message
		// 		proof.approver_reason_id = approverReasonId
		// 		proof.shots = shots
		// 	}

		// 	return proof
		// })

		// setProofStatus(proofs)
		setIsLoading(false)
		// setAppointment(vaccineStatus.appointments[0])

		// vaccineController.fetchProof().then((response: VaccineProofResponse) => {})
	}, [vaccineStatus, setIsLoading, setReloadVaccineData, setVaccineProofList])

	// useEffect(() => {
	// 	if (!vaccineStatus) {
	// 		return
	// 	}

	// 	if (vaccineStatus['pre_screening_not_eligible_reason'] && notEligibleReasons) {
	// 		const reasonResponse = notEligibleReasons.find(
	// 			(reason: any) => reason.reason_id === vaccineStatus.pre_screening_not_eligible_reason
	// 		)
	// 		if (reasonResponse) setVaccineEnquiry(reasonResponse?.reason)
	// 	}
	// }, [vaccineStatus, notEligibleReasons])

	// let lastVaccineItem: any = undefined
	// if (vaccineResultsList) {
	// 	lastVaccineItem = vaccineResultsList[0]
	// }

	const boosterPending = vaccineResultsList?.filter(item => item.result === 'VACCINE_BOOSTER_PENDING')
	const boosterRejected = vaccineResultsList?.filter(item => item.result === 'VACCINE_BOOSTER_REJECTED')
	const pendingProofs = vaccineResultsList?.filter(item => item.result === 'VACCINE_PROOF_PENDING')

	const exemptionItems = vaccineResultsList?.filter(item => ['EXEMPT_MORE_INFO', 'EXEMPT_REJECTED' ,'EXEMPT_PENDING' ,'EXEMPT_APPROVED'].includes(item.result))
	const hasExemptions = exemptionItems && exemptionItems.length > 0

	const exemptRejected = vaccineResultsList?.filter(item => item.result === 'EXEMPT_REJECTED')
	const exemptPending = vaccineResultsList?.filter(item => item.result === 'EXEMPT_PENDING')
	const exemptMoreInfo = vaccineResultsList?.filter(item => item.result === 'EXEMPT_MORE_INFO')

	const proofRejected = vaccineResultsList?.filter(item => item.result === 'VACCINE_PROOF_REJECTED')
	const partialProofs = vaccineResultsList?.filter(item => item.result === 'VACCINE_PROOF_PARTIAL')
	const vaccineMissing = vaccineResultsList?.filter(item => item.result === 'VACCINE_MISSING')
	const boosterMissing = vaccineResultsList?.filter(item => item.result === 'VACCINE_BOOSTER_MISSING')

	const getStatusBlockData = () => {
		if (boosterPending && boosterPending.length > 0) {
			return (
				<StatusTextBlock
					icon="clock"
					heading={appText[COVID_TEXTS['covid.vaccine.booster.pending.heading']]}
					iconColor="red"
					text={appText[COVID_TEXTS['covid.vaccine.booster.pending.text']]}
				/>
			)
		} else if (boosterRejected && boosterRejected.length > 0) {
			return (
				<StatusTextBlock
					icon="ban"
					heading={appText[COVID_TEXTS['covid.vaccine.booster.denied.heading']]}
					iconColor="red"
					text={appText[COVID_TEXTS['covid.vaccine.booster.denied.text']]}
				/>
			)
		} else if (pendingProofs && pendingProofs?.length > 0) {
			// } else if (pendingShots && pendingShots.length > 0) {
			return (
				<StatusTextBlock
					icon="clock"
					heading={appText[COVID_TEXTS['covid.vaccine.proof.pending.heading']]}
					iconColor="red"
					text={appText[COVID_TEXTS['covid.vaccine.proof.pending.text']]}
				/>
			)
		} else if (hasExemptions) {
			switch (exemptionItems[0].result) {
				case ('EXEMPT_REJECTED'):
					return (
						<StatusTextBlock
							icon="ban"
							heading={appText[COVID_TEXTS['covid.vaccine.exemption.denied.heading']]}
							iconColor="red"
							text={appText[COVID_TEXTS['covid.vaccine.exemption.denied.text']]}
						/>
					)
					case ('EXEMPT_PENDING'):
						return (
							<StatusTextBlock
								icon="clock"
								heading={appText[COVID_TEXTS['covid.vaccine.exemption.pending.heading']]}
								iconColor="red"
								text={appText[COVID_TEXTS['covid.vaccine.exemption.pending.text']]}
							/>
						)
					case ('EXEMPT_MORE_INFO'):
						return (
							<StatusTextBlock
								icon="question-circle"
								heading={appText[COVID_TEXTS['covid.vaccine.exemption.needs.info.heading']]}
								iconColor="red"
								text={appText[COVID_TEXTS['covid.vaccine.exemption.needs.info.text']]}
							/>
						)
				default:
					break;
			}
		}

		// else if (exemptRejected && exemptRejected.length > 0) {
		// 	return (
		// 		<StatusTextBlock
		// 			icon="ban"
		// 			heading={appText[COVID_TEXTS['covid.vaccine.exemption.denied.heading']]}
		// 			iconColor="red"
		// 			text={appText[COVID_TEXTS['covid.vaccine.exemption.denied.text']]}
		// 		/>
		// 	)
		// } else if (exemptPending && exemptPending.length > 0) {
		// 	// if (exemptPending && exemptPending.length > 0) {
		// 	return (
		// 		<StatusTextBlock
		// 			icon="clock"
		// 			heading={appText[COVID_TEXTS['covid.vaccine.exemption.pending.heading']]}
		// 			iconColor="red"
		// 			text={appText[COVID_TEXTS['covid.vaccine.exemption.pending.text']]}
		// 		/>
		// 	)
		// } else if (exemptMoreInfo && exemptMoreInfo.length > 0) {
		// 	// } else if (exemptMoreInfo && exemptMoreInfo.length > 0) {
		// 	return (
		// 		<StatusTextBlock
		// 			icon="question-circle"
		// 			heading={appText[COVID_TEXTS['covid.vaccine.exemption.needs.info.heading']]}
		// 			iconColor="red"
		// 			text={appText[COVID_TEXTS['covid.vaccine.exemption.needs.info.text']]}
		// 		/>
		// 	)
		// }
		else if (proofRejected && proofRejected.length > 0) {
			// has rejected shot
			// } else if (rejectedShots && rejectedShots.length > 0) { // has rejected shot
			return (
				<StatusTextBlock
					icon="ban"
					heading={appText[COVID_TEXTS['covid.vaccine.proof.denied.heading']]}
					iconColor="red"
					text={appText[COVID_TEXTS['covid.vaccine.proof.denied.text']]}
				/>
			)
		} else if (partialProofs && partialProofs.length > 0) {
			// } else if (hasPartialProofs) {
			return (
				<StatusTextBlock
					icon="clock"
					iconLight
					heading={appText[COVID_TEXTS['covid.vaccine.proof.partial.heading']]}
					iconColor="#339CA2"
					text={appText[COVID_TEXTS['covid.vaccine.proof.partial.text']]}
				/>
			)
		} else if (vaccineMissing && vaccineMissing.length > 0) {
			// } else if (vaccineMissing) {
			return (
				<StatusTextBlock
					icon="syringe"
					heading="You can upload proof of your vaccine shots"
					iconColor="#339CA2"
					text="Please upload proof of your Covid-19 vaccine shot. It only takes a minute."
				/>
			)
		} else if (boosterMissing && boosterMissing.length > 0) {
			console.log(appText[COVID_TEXTS['covid.vaccine.booster.heading']])
			return (
				<StatusTextBlock
					icon="syringe"
					// heading={appText[COVID_TEXTS['covid.vaccine.booster.due.heading']]}
					heading={appText[COVID_TEXTS['covid.vaccine.booster.heading']].replace('{date}', formatDate(vaccineStatus?.booster.due_date))}
					iconColor="#339CA2"
					// text={appText[COVID_TEXTS['covid.vaccine.booster.due.text']]}
					text={appText[COVID_TEXTS['covid.vaccine.booster.text']]}
					// text="Please upload proof of your COVID-19 vaccine booster shot, it only takes a minute."
				/>
			)
		}

		return null
	}

	return (
		<>
			<ToastContainer {...defaultListData} position={ToastPosition.TopCenter} />
			<Text type="h2" content={'Vaccine'} align="center" />
			<hr />
			<Spacer size="2" />
			{getStatusBlockData()}

			{/* HIDDEN SINCE IT WILL BE CHANGING */}
			{/* <div className="d-flex" style={{justifyContent: 'center', gridColumnGap: '0.5rem', marginTop: '0.5rem'}}>
					{features['vaccine.proof.enabled'] && vaccineStatus && vaccineStatus?.status !== 'none' && vaccineStatus?.course_progress.length < 1 && !externalVaccine && (
						<Button
							type={ButtonType.Primary}
							icon="plus"
							padded
							state={vaccineStatus && vaccineStatus?.course_progress.length >= 2 ? ButtonState.Disabled : ButtonState.Enabled}
							text={'Upload Proof of Vaccination'}
							onClick={() => {setVaccineReceived('partially'); setActiveDrawer(ActiveDrawer.VaccineManufacturer)}}
						/>
					)}
				</div> */}
			{/* </div> */}
		</>
	)
}

export default CovidSection

// {/* Provided Vaccine related info */}
// {externalVaccine && (

// 	<>
// 		{isLoading
// 		?	<div aria-busy={isLoading ?? false} className="category-cards skeleton-loader" style={{gridTemplateColumns: 'repeat(2, 1fr)'}}>
// 				<div className="category-card card"></div>
// 				<div className="category-card card"></div>
// 			</div>
// 		:	<>
// 			<Spacer size="3" />
// 			<div className="vaccine-proof-list">
// 				{proofStatus.map((proof: VaccineProofInterface) => {
// 					let proof_guid = proof.proof_guid;
// 					let shots = proof.shots.length;
// 					let company = vaccineCompanies?.find((itm: any) => itm.medical_vaccine_company_guid == proof.company)
// 					let oneDose = oneDoseVaccine?.find((item: any) => item === company?.company_name)
// 					return (
// 						<>
// 						<h3>I am Here</h3>
// 						<GridContainer >
// 							{proof.shots.map((shot: ProofShot) => {
// 								return (
// 									<>
// 										{/* Partially Vaccinated fisrt shot */}
// 										{shots <= 1 && shot.shot_number === 2 && !oneDose &&
// 											<LayoutColumn colSize={6} >
// 												<Container
// 													className={`dose-left status-none`}
// 													contents={
// 														<>
// 															<Text content={`Dose 1`} type={TextType.Heading5}/>
// 															<div className="status">
// 																<Icon icon={'shield'} type={'light'} color={'grey'} hideAriaLabel={true}/>
// 																<Text content={'NONE'} type={TextType.Heading2} bold />
// 															</div>
// 															<div className={`status-btn upload`} onClick={() => selectedVaccineProof(proof_guid, 1)} onKeyDown={(e: any) => { (e.key === 13 || e.keyCode === 13) && selectedVaccineProof(proof_guid, 2)}} tabIndex={0}>
// 																<Icon icon={'camera'} type={'solid'}/>
// 																<p aria-label={`Dose 1, Upload proof`}>Upload proof</p>
// 															</div>
// 														</>
// 													}
// 													alignment="center"
// 												/>
// 											</LayoutColumn>
// 										}
// 										<LayoutColumn colSize={6}>
// 											<Container
// 												className={
// 													!!oneDose
// 														? `${shot.class.toLowerCase()}`
// 														: shot.shot_number === 1
// 															? `dose-left ${shot.class.toLowerCase()}`
// 															: `dose-right ${shot.class.toLowerCase()}`}
// 												contents={
// 													<>
// 														{!oneDose && <Text content={`Dose ${shot.shot_number}`} type={TextType.Heading5} />}
// 														<div className="status">
// 															<Icon icon={shot.icon} type={shot.icon_type} color={shot.icon_color} hideAriaLabel={true}/>
// 															<Text content={shot.title.toUpperCase()} type={TextType.Heading2} bold />
// 														</div>
// 														{shot.status !== 'R'
// 														?	<div className="status-btn" onClick={() => selectedVaccineProof(proof_guid, shot.shot_number)} onKeyDown={(e: any) => {(e.key === 13 || e.keyCode === 13) && selectedVaccineProof(proof_guid, shot.shot_number)}} tabIndex={0}>
// 																<Icon icon={'eye'} type={'solid'} hideAriaLabel={true}/>
// 																<p aria-label={`Dose ${shot.shot_number}, View proof ${shot.received_on}`}>View proof <br/>{shot.received_on}</p>
// 															</div>
// 														: 	<div className={`status-btn rejected`} onClick={() => selectedVaccineProof(proof_guid, shot.shot_number)} onKeyDown={(e: any) => { (e.key === 13 || e.keyCode === 13) && selectedVaccineProof(proof_guid, shot.shot_number)}} tabIndex={0}>
// 																<Icon icon={'camera'} type={'solid'} hideAriaLabel={true}/>
// 																<p aria-label={`Dose ${shot.shot_number}, Update your submission`}>Update your <br/>submission</p>
// 															</div>
// 														}
// 													</>
// 												}
// 												alignment="center"
// 											/>
// 										</LayoutColumn>
// 										{/* Partially Vaccinated second shot */}
// 										{shots <= 1 && shot.shot_number === 1 && !oneDose &&
// 											<LayoutColumn colSize={6} >
// 												<Container
// 													className={`dose-right status-none`}
// 													contents={
// 														<>
// 															<Text content={`Dose 2`} type={TextType.Heading5} />
// 															<div className="status">
// 																<Icon icon={'shield'} type={'light'} color={'grey'} hideAriaLabel={true}/>
// 																<Text content={'NONE'} type={TextType.Heading2} bold />
// 															</div>
// 															<div className={`status-btn upload ${shot.status === 'R' && 'disabled'}`} onClick={() => shot.status !== 'R' && selectedVaccineProof(proof_guid, 2)} onKeyDown={(e: any) => { (e.key === 13 || e.keyCode === 13) && selectedVaccineProof(proof_guid, 2)}} tabIndex={0}>
// 																<Icon icon={'camera'} type={'solid'} hideAriaLabel={true}/>
// 																<p aria-label={`Dose 2, Upload proof`}>Upload proof</p>
// 															</div>
// 														</>
// 													}
// 													alignment="center"
// 												/>
// 											</LayoutColumn>
// 										}
// 									</>
// 								)
// 							})}
// 							{proof.status !== 'A' &&
// 								<LayoutColumn colSize={12} >
// 									<Spacer size="3" />
// 									<div className="processing">
// 										<Icon icon={getStatusObj(proof.status).icon} type={getStatusObj(proof.status).icon_type} color={getStatusObj(proof.status).icon_color} hideAriaLabel={true} />
// 										<Text content={
// 											getStatusObj(proof.status).title === 'Error'
// 											? `${appText[COVID_TEXTS['vaccine.pov.detail.rejected']] ?? 'Your submission to MIT medical has been rejected for the following reason:'} ${getApproverMessage(proof.approver_message, proof.approver_reason_id)}`
// 											: 'Thank you for submitting your proof of vaccination.'
// 											} type={TextType.Body} />
// 									</div>
// 								</LayoutColumn>
// 							}
// 							{proof.shots.length >= 1 && (proof.shots[0] && proof.shots[0].status === 'A' || proof.shots[1] && proof.shots[1].status === 'A') &&
// 								<>
// 									<LayoutColumn colSize={12} alignment="center" >
// 										<Spacer size="3" />
// 										<Button
// 											type={ButtonType.Primary}
// 											icon="cloud-download-alt"
// 											padded
// 											isBusy={isDownloading}
// 											state={isDownloading ? ButtonState.Disabled : ButtonState.Enabled}
// 											text={'Download Proof of Vaccination'}
// 											onClick={() => downloadClicked(proof.proof_guid)}
// 										/>
// 									</LayoutColumn>

// 								</>
// 							}
// 						</GridContainer>
// 						</>
// 					)
// 				})}
// 			</div>
// 		</>}
// 	</>
// )}
