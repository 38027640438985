import { AuthProvider } from '../AuthProvider'
import BaseController from './BaseController'

export interface VaccineWaiverInterface {
	ack_title: string
	text: string
	type: 'waiver' | 'analytics'
}

export interface VaccineWaiverResponse {
	waivers?: VaccineWaiverInterface[]
	error?: boolean
}

export interface PostWaiverResponse {
	success?: boolean
	error?: any
}

export interface VaccineAppointmentResponse {
	appointments?: VaccineAppointmentInterface[]
	error?: boolean
}

export interface VaccinePrescreeningResponse {
	items?: VaccinePrescreeningInterface
	error?: boolean
}

export interface VaccineScheduleDateResponse {
	schedules?: VaccineScheduleDateInterface[]
	error?: boolean
}

export interface VaccineScheduleDateInterface {
	date: string
	selected: boolean
	on_standby: boolean
}

export interface VaccineScheduleResponse {
	schedules?: VaccineScheduleInterface[]
	error?: boolean
}

export interface VaccineQuestionResponse {
	questions?: VaccineQuestion[]
	error?: boolean
}

export interface VaccineStandbyResponse {
	standby?: VaccineStandbyInterface[]
	error?: boolean
}

export interface VaccineScheduleInterface {
	schedule_guid: string
	start_datetime: string
	end_datetime: string
	capacity: number
	available_capacity: number
	active: boolean
	selected: boolean
}

export interface VaccineStandbyInterface {
	vaccine_standby_guid: string
	vaccine_course_guid: string
	standby_date: string
	schedule_guid: string
	accepted: number
	administered: boolean
	active: boolean
	schedule: VaccineScheduleInterface
}

export interface VaccineAppointmentInterface {
	vaccine_appointment_guid: string
	vaccine_course_guid: string
	vaccine_schedule: VaccineScheduleInterface
	appointment_number: number
	active: boolean
	can_change: boolean
	on_standby: boolean
}

export interface VaccinePrescreeningInterface {
	vaccine_prescreening_guid: string
	mit_id: string
	vaccine_interest_type: number
	vaccine_availability: number
	mit_medical_patient: boolean
	nr_comorbidities: number
	vaccine_location_id: number
	has_family_members: boolean
	person_guid: string
	received: number
	standby: boolean
	active: boolean
	created_by: string
	created_on: string
}

export interface VaccineQuestion {
	id: string
	text: string
	more_text: string
	page: number
	required: boolean
	disqualify: boolean
	style: string
	follow_up_questions: VaccineQuestion[]
	follow_up_trigger: string[]
	follow_up_multiple: boolean
}

export interface VaccineAnswerDetail {
	question_id: string
	question_answer: boolean
}

export interface VaccineAnswer {
	vaccine_course_guid: string
	answers: VaccineAnswerDetail[]
}

export interface VaccineSurveyInterface {
	questions: VaccineSurveyQuestion[]
	survey_guid: string
}

export interface VaccineSurveyQuestion {
	id: number
	options: VaccineSurveyQuestionsOptions[]
	page: number
	text: string
	selected_option: number
}

export interface VaccineSurveyQuestionsOptions {
	id: number
	text: string
}
export interface VaccineSurveyResponse {
	data?: VaccineSurveyInterface[]
	error?: any
}

export interface PostSurveyBody {
	survey_guid: string
	results: PostSurveyResults[]
}

export interface PostSurveyResults {
	question_id: number
	option_id: number
}

export interface VaccineTypeResponse {
	data?: TypeInterface[]
	error?: any
}
export interface ExemptionTypesResponse {
	data?: ExemptionTypes[]
	error?: any
}

export interface TypeInterface {
	type_id: number
	name: string
}

export interface VaccineProofResponse {
	data?: VaccineProofInterface[]
	error?: any
}
export interface ExemptionsResponse {
	data?: Exemption[]
	error?: any
}
export interface ExemptionUploadUrlResponse {
	data?: ExemptionUploadUrl
	error?: any
}

export interface VaccineProofInterface {
	vaccine_status?: string
	proof_guid: string
	krb_name: string
	company?: string
	type?: number
	image_url?: string
	status?: string
	approver_message?: string
	approver_reason_id?: number
	shots: VaccineProofShot[]
}

export interface VaccineProofShot {
	type?: number
	company?: string
	shot_number: number | any
	lot_number: string | any
	received_on: string | any
	location: string | any
	image_url?: any
	image_key?: any
	signed_url?: string
	status?: string
	approver_message?: string
	approver_reason_id?: number
	// is_booster: boolean
}

export interface VaccineProofDto {
	proof_guid: string
	vaccineType: string
	lotNumber: string
	productName: string
	doseNumber: string
	datereceived: string
	healthcareSite: string
}

export interface VaccineCompanyResponse {
	data?: VaccineCompanyInterface[]
	error?: any
}

export interface VaccineReasonsResponse {
	data?: VaccineReviewReasons[]
	error?:any
}
export interface VaccineCompanyInterface {
	medical_vaccine_company_guid: string
	company_name: string
	shot_days_interval: number
	number_of_shots: number
}

export interface VaccinePrescreeningFamilyInterface {
	mit_id: string
	vaccine_interest_type: number
	vaccine_availability: number
	mit_medical_patient: boolean
	nr_comorbidities: number
	has_family_members: boolean
	vaccine_location_id: number
	person_guid: string
}

export interface VaccinePrescreeningBody {
	vaccine_interest_type: number
	vaccine_availability: number
	mit_medical_patient: boolean
	nr_comorbidities: number
	has_family_members: boolean
	vaccine_location_id: number
	standby: boolean
	received: number
}

export interface FetchVaccinePrescreenFamilyResponse {
	data?: VaccinePrescreeingFamily
	error?: boolean
}

export interface VaccinePrescreeingFamily {
	vaccine_prescreening_guid: string
	mit_id: string
	vaccine_interest_type: number
	vaccine_availability: number
	mit_medical_patient: boolean
	nr_comorbidities: number
	has_family_members: boolean
	vaccine_location_id: number
	person_guid: string
	created_by: string
	created_on: string
}

export interface VaccinePrescreeingUpdate {
	received: number
	not_eligible_reason: number
}

export interface VaccineReviewReasons {
	proof_status: string
	reason_id: number
	reason_text: string
}

export interface VaccineReceived {
	state: 'none' | 'partiallly' | 'fully' | ''
}

export interface ExemptionTypes {
	id: number
	name: string
	seq: number
	icon: string
	description: string
	roleApprove: string
}

export interface Exemption {
	id: string
	krbName: string
	displayName: string
	phoneNumber: null
	email: string
	dateOfBirth: null
	reason: string
	type: number
	typeName: string
	status: string
	statusText: string
	imageKey: string
	feedback: null
	mitId: string
	submissionDate: Date
	imageUrl: string
	isExternalContractor: boolean
	fileName: string
	fileContentType: string
}

export interface ExemptionUploadUrl {
	imageKey: string
	imageUrl: string
}

export interface Booster {
	received: boolean,
	received_on: string,
	valid: boolean,
	message: string,
	due_date: string
}

class VaccineController extends BaseController {
	readonly url_waiver_text_all: string
	readonly url_vaccine_waiver: string
	readonly url_appointment: string
	readonly url_schedule: string
	readonly url_questions: string
	readonly url_reset: string
	readonly url_vaccine_standby: string
	readonly url_vaccine_survey: string
	readonly url_vaccine_prescreening: string
	readonly url_vaccine_proof: string
	readonly url_vaccine_proof_upload: string
	readonly url_vaccine_proof_reason: string
	readonly url_vaccine_proof_upload_url: string
	readonly url_vaccine_company: string
	readonly url_vaccine_type: string
	readonly url_not_eligible_reason: string
	readonly url_pdf_conversion: string
	readonly url_exemption_request: string
	readonly url_exemption_types: string
	readonly url_exemption: string
	readonly url_exemption_documentation_upload: string
	readonly url_get_person: string

	constructor() {
		super()

		this.url_waiver_text_all = `${this.apiHost}/${this.apiPathVaccine}/vaccine/waiver/text?layout=web`
		this.url_vaccine_waiver = `${this.apiHost}/${this.apiPathVaccine}/vaccine/waiver`
		this.url_vaccine_standby = `${this.apiHost}/${this.apiPathVaccine}/vaccine/standby`
		this.url_appointment = `${this.apiHost}/${this.apiPathVaccine}/vaccine/appointment`
		this.url_schedule = `${this.apiHost}/${this.apiPathVaccine}/vaccine/schedule`
		this.url_questions = `${this.apiHost}/${this.apiPathVaccine}/vaccine/questions`
		this.url_reset = `${this.apiHost}/${this.apiPathVaccine}/vaccine/reset`
		this.url_vaccine_prescreening = `${this.apiHost}/${this.apiPathVaccine}/vaccine/prescreening`
		this.url_vaccine_survey = `${this.apiHost}/${this.apiPathCovid}/survey`
		this.url_vaccine_proof = `${this.apiHost}/${this.apiPathVaccine}/vaccine/proof`
		this.url_vaccine_proof_upload = `${this.apiHost}/${this.apiPathVaccine}/vaccine/proof/upload`
		this.url_vaccine_proof_reason = `${this.apiHost}/${this.apiPathVaccine}/vaccine/proof/reason`
		this.url_vaccine_proof_upload_url = `${this.apiHost}/${this.apiPathVaccine}/vaccine/proof/upload/url`
		this.url_vaccine_company = `${this.apiHost}/${this.apiPathVaccine}/vaccine/company`
		this.url_vaccine_type = `${this.apiHost}/${this.apiPathVaccine}/vaccine/type`
		this.url_not_eligible_reason = `${this.apiHost}/${this.apiPathVaccine}/vaccine/prescreening/not_eligible_reason`
		this.url_pdf_conversion = `${this.apiHost}/${this.apiPathCovid}/pdf_conversion`
		this.url_exemption_request = `${this.apiHost}/${this.apiPathVaccine}/vaccine/exemption/request`
		this.url_exemption_types = `${this.apiHost}/${this.apiPathVaccine}/vaccine/exemption/types`
		this.url_exemption = `${this.apiHost}/${this.apiPathVaccine}/vaccine/exemption`
		this.url_exemption_documentation_upload = `${this.apiHost}/${this.apiPathVaccine}/vaccine/exemption/url`
		this.url_get_person = `${this.apiHost}/${this.apiPathVaccine}/person`
	}

	async fetchWaiverTextAll(): Promise<VaccineWaiverResponse> {
		let response = await this.useFetch('GET', 'fetchWaiverTextAll', this.url_waiver_text_all)

		if (response.status === 200) {
			let data: VaccineWaiverInterface[] = await response.json()
			return {
				waivers: data
			}
		} else {
			return {
				error: true
			}
		}
	}

	async fetchAppointments(): Promise<VaccineAppointmentResponse> {
		let response = await this.useFetch('GET', 'fetchAppointments', this.url_appointment)

		if (response.status === 200) {
			let data: VaccineAppointmentInterface[] = await response.json()
			return {
				appointments: data
			}
		} else {
			return {
				error: true
			}
		}
	}

	async fetchInterestType(): Promise<VaccineTypeResponse> {
		let response = await this.useFetch('GET', 'fetchInterestType', this.url_vaccine_prescreening + '/interest_type')

		if (response.status === 200) {
			let data: TypeInterface[] = await response.json()
			return {
				data: data
			}
		} else {
			return {
				error: true
			}
		}
	}

	async fetchReceivedType(): Promise<VaccineTypeResponse> {
		let response = await this.useFetch('GET', 'fetchReceivedType', this.url_vaccine_prescreening + '/received_type')

		if (response.status === 200) {
			let data: TypeInterface[] = await response.json()
			return {
				data: data
			}
		} else {
			return {
				error: true
			}
		}
	}

	async fetchComorbidities(): Promise<VaccineTypeResponse> {
		let response = await this.useFetch('GET', 'fetchComorbidities', this.url_vaccine_prescreening + '/comorbidity')

		if (response.status === 200) {
			let data: TypeInterface[] = await response.json()
			return {
				data: data
			}
		} else {
			return {
				error: true
			}
		}
	}

	async fetchLocationType(): Promise<VaccineTypeResponse> {
		let response = await this.useFetch('GET', 'fetchLocationType', this.url_vaccine_prescreening + '/location_type')

		if (response.status === 200) {
			let data: TypeInterface[] = await response.json()
			return {
				data: data
			}
		} else {
			return {
				error: true
			}
		}
	}

	async fetchAvailabilityType(): Promise<VaccineTypeResponse> {
		let response = await this.useFetch(
			'GET',
			'fetchavailabilityType',
			this.url_vaccine_prescreening + '/availability_type'
		)

		if (response.status === 200) {
			let data: TypeInterface[] = await response.json()
			return {
				data: data
			}
		} else {
			return {
				error: true
			}
		}
	}

	async fetchPrescreening(): Promise<VaccinePrescreeningResponse> {
		let response = await this.useFetch('GET', 'fetchPrescreening', this.url_vaccine_prescreening)

		if (response.status === 200) {
			let data: VaccinePrescreeningInterface = await response.json()
			return {
				items: data
			}
		} else {
			return {
				error: true
			}
		}
	}

	async putPrescreening(body: any): Promise<VaccinePrescreeningResponse> {
		let response = await this.useFetch('PUT', 'putPrescreening', this.url_vaccine_prescreening, body)

		if (response.status === 200) {
			let data = await response.json()
			return {
				items: data
			}
		} else {
			return {
				error: true
			}
		}
	}

	async fetchStandby(): Promise<VaccineStandbyResponse> {
		let response = await this.useFetch('GET', 'fetchStandby', this.url_vaccine_standby)

		if (response.status === 200) {
			let data: VaccineStandbyInterface[] = await response.json()
			return {
				standby: data
			}
		} else {
			return {
				error: true
			}
		}
	}

	async fetchScheduleDates(): Promise<VaccineScheduleDateResponse> {
		let response = await this.useFetch('GET', 'fetchScheduleDates', this.url_schedule + '/dates')

		if (response.status === 200) {
			let data: VaccineScheduleDateInterface[] = await response.json()
			return {
				schedules: data
			}
		} else {
			return {
				error: true
			}
		}
	}

	async fetchReset(): Promise<any> {
		let response = await this.useFetch('GET', 'fetchReset', this.url_reset)

		if (response.status === 200) {
			// let data: string[] = await response.json()

			return {}
		} else {
			return {
				error: true
			}
		}
	}

	async fetchSchedules(startDate: string, endDate: string): Promise<VaccineScheduleResponse> {
		let response = await this.useFetch(
			'GET',
			'fetchSchedules',
			`${this.url_schedule}?start=${startDate}&end=${endDate}`
		)

		if (response.status === 200) {
			let data: VaccineScheduleInterface[] = await response.json()
			return {
				schedules: data
			}
		} else {
			return {
				error: true
			}
		}
	}

	async fetchQuestions(): Promise<VaccineQuestionResponse> {
		let response = await this.useFetch('GET', 'fetchQuestions', `${this.url_questions}`)

		if (response.status === 200) {
			let data: VaccineQuestion[] = await response.json()
			return {
				questions: data
			}
		} else {
			return {
				error: true
			}
		}
	}

	async fetchVaccineSurvey(signal: AbortSignal): Promise<VaccineSurveyResponse> {
		let response = await this.useFetch('GET', 'fetchVaccineSurvey', `${this.url_vaccine_survey}`)

		if (response.status === 200) {
			return { data: await response.json() }
		} else {
			return {
				error: true
			}
		}
	}

	async fetchVaccinePrescreeningFamily(person_guid: string): Promise<FetchVaccinePrescreenFamilyResponse> {
		let response = await this.useFetch(
			'GET',
			'fetchFamily',
			`${this.url_vaccine_prescreening}/family?person_guid=${person_guid}`
		)

		if (response.status === 200) {
			return { data: await response.json() }
		} else {
			return {
				error: true
			}
		}
	}

	async fetchProof(): Promise<VaccineProofResponse> {
		let response = await this.useFetch('GET', 'proof', this.url_vaccine_proof)

		if (response.status === 200) {
			let data: VaccineProofInterface[] = await response.json()
			return {
				data: data
			}
		} else {
			return {
				error: true
			}
		}
	}

	async fetchProofReason(): Promise<VaccineReasonsResponse> {
		let response = await this.useFetch('GET', 'fetchProofReason', this.url_vaccine_proof_reason)

		if (response.status === 200) {
			let data = await response.json()
			return {
				data
			}
		} else {
			return {
				error: true
			}
		}
	}

	async fetchCompanies(): Promise<VaccineCompanyResponse> {
		let response = await this.useFetch('GET', 'vaccine-company', this.url_vaccine_company)

		if (response.status === 200) {
			let data: VaccineCompanyInterface[] = await response.json()
			return {
				data: data
			}
		} else {
			return {
				error: true
			}
		}
	}

	async fetchTypes(): Promise<VaccineTypeResponse> {
		let response = await this.useFetch('GET', 'vaccine-type', this.url_vaccine_type)

		if (response.status === 200) {
			let data: TypeInterface[] = await response.json()
			return {
				data: data
			}
		} else {
			return {
				error: true
			}
		}
	}

	async fetchExemptionTypes(): Promise<ExemptionTypesResponse> {
		let response = await this.useFetch('GET', 'exemption-type', this.url_exemption_types)

		if (response.ok) {
			let data: ExemptionTypes[] = await response.json()
			return {
				data: data
			}
		} else {
			return {
				error: true
			}
		}
	}

	async fetchExemptions(mitId: string): Promise<ExemptionsResponse> {
		let response = await this.useFetch('GET', 'exemptions', `${this.url_exemption}?mitId=${mitId}`)

		if (response.status === 200) {
			let data = await response.json()
			return {
				data: data
			}
		} else {
			return {
				error: true
			}
		}
	}

	postAppointment = async (appointment: VaccineAppointmentInterface): Promise<any> => {
		let token = await new AuthProvider().getToken()

		const headerInit: any = {
			Authorization: 'Bearer ' + token.token,
			'Content-Type': 'application/json'
		}

		const options: RequestInit = {
			method: 'POST',
			headers: new Headers(headerInit),
			body: JSON.stringify(appointment),
			mode: 'cors'
		}

		let response = await fetch(this.url_appointment, options)

		if (response.status >= 200 && response.status < 300) {
			// let data = await response.json()
			return {
				success: true
			}
		} else {
			return {
				error: true
			}
		}
	}

	postPresreening = async (prescreening: VaccinePrescreeningBody): Promise<any> => {
		let token = await new AuthProvider().getToken()

		const headerInit: any = {
			Authorization: 'Bearer ' + token.token,
			'Content-Type': 'application/json'
		}

		const options: RequestInit = {
			method: 'POST',
			headers: new Headers(headerInit),
			body: JSON.stringify(prescreening),
			mode: 'cors'
		}

		let response = await fetch(this.url_vaccine_prescreening, options)

		if (response.status >= 200 && response.status < 300) {
			// let data = await response.json()
			return {
				success: true
			}
		} else {
			return {
				error: true
			}
		}
	}

	postPresreeningFamily = async (family: VaccinePrescreeningFamilyInterface): Promise<any> => {
		let token = await new AuthProvider().getToken()

		const headerInit: any = {
			Authorization: 'Bearer ' + token.token,
			'Content-Type': 'application/json'
		}

		const options: RequestInit = {
			method: 'POST',
			headers: new Headers(headerInit),
			body: JSON.stringify(family),
			mode: 'cors'
		}

		let response = await fetch(`${this.url_vaccine_prescreening}/family`, options)

		if (response.status >= 200 && response.status < 300) {
			// let data = await response.json()
			return {
				success: true
			}
		} else {
			return {
				error: true
			}
		}
	}

	postVaccineWaiver = async (agreed: boolean): Promise<PostWaiverResponse> => {
		let token = await new AuthProvider().getToken()

		const headerInit: any = {
			Authorization: 'Bearer ' + token.token,
			'Content-Type': 'application/json'
		}

		const answer: string = agreed ? 'accept' : 'decline'

		const body = {
			answer: answer
		}

		const options: RequestInit = {
			method: 'POST',
			headers: new Headers(headerInit),
			body: JSON.stringify(body),
			mode: 'cors'
		}

		let response = await fetch(this.url_vaccine_waiver, options)

		if (response.status >= 200 && response.status < 300) {
			// let data = await response.json()
			return {
				success: true
			}
		} else {
			return {
				error: true
			}
		}
	}

	postQuestions = async (question: VaccineAnswer): Promise<any> => {
		let token = await new AuthProvider().getToken()

		const headerInit: any = {
			Authorization: 'Bearer ' + token.token,
			'Content-Type': 'application/json'
		}

		const options: RequestInit = {
			method: 'POST',
			headers: new Headers(headerInit),
			body: JSON.stringify(question),
			mode: 'cors'
		}

		let response = await fetch(this.url_questions, options)

		if (response.status >= 200 && response.status < 300) {
			// let data = await response.json()
			return {
				success: true
			}
		} else {
			return {
				error: true
			}
		}
	}

	postStandbyInvite = async (schedule_guid: string, accept: boolean): Promise<any> => {
		let token = await new AuthProvider().getToken()

		const headerInit: any = {
			Authorization: 'Bearer ' + token.token,
			'Content-Type': 'application/json'
		}

		const options: RequestInit = {
			method: 'POST',
			headers: new Headers(headerInit),
			body: JSON.stringify({ accept: accept }),
			mode: 'cors'
		}

		let response = await fetch(this.url_vaccine_standby + '/invite/' + schedule_guid, options)

		if (response.status >= 200 && response.status < 300) {
			// let data = await response.json()
			return {
				success: true
			}
		} else {
			return {
				error: true
			}
		}
	}

	postStandby = async (): Promise<any> => {
		let token = await new AuthProvider().getToken()

		const headerInit: any = {
			Authorization: 'Bearer ' + token.token,
			'Content-Type': 'application/json'
		}

		const options: RequestInit = {
			method: 'POST',
			headers: new Headers(headerInit),
			mode: 'cors'
		}

		let response = await fetch(this.url_vaccine_standby, options)

		if (response.status >= 200 && response.status < 300) {
			let data = await response.json()
			return {
				data: data,
				success: true
			}
		} else {
			return {
				error: true
			}
		}
	}

	postVaccineSurvey = async (body: PostSurveyBody): Promise<any> => {
		let token = await new AuthProvider().getToken()

		const headerInit: any = {
			Authorization: 'Bearer ' + token.token,
			'Content-Type': 'application/json'
		}

		const options: RequestInit = {
			method: 'POST',
			headers: new Headers(headerInit),
			body: JSON.stringify(body),
			mode: 'cors'
		}

		let response = await fetch(this.url_vaccine_survey, options)

		if (response.status >= 200 && response.status < 300) {
			return { data: await response.json() }
		} else {
			return {
				error: true
			}
		}
	}

	postVaccineProof = async (body: any): Promise<any> => {
		let token = await new AuthProvider().getToken()

		const headerInit: any = {
			Authorization: 'Bearer ' + token.token,
			'Content-Type': 'application/json'
		}

		let postData = body

		const options: RequestInit = {
			method: 'POST',
			headers: new Headers(headerInit),
			body: JSON.stringify(postData),
			mode: 'cors'
		}

		let response = await fetch(this.url_vaccine_proof, options)

		if (response.status >= 200 && response.status < 300) {
			return { data: await response.json() }
		} else {
			return {
				error: true
			}
		}
	}

	putVaccineProof = async (body: any): Promise<any> => {
		let token = await new AuthProvider().getToken()

		const headerInit: any = {
			Authorization: 'Bearer ' + token.token,
			'Content-Type': 'application/json'
		}

		let putData = body

		const options: RequestInit = {
			method: 'PUT',
			headers: new Headers(headerInit),
			body: JSON.stringify(putData),
			mode: 'cors'
		}

		let response = await fetch(this.url_vaccine_proof, options)

		if (response.status >= 200 && response.status < 300) {
			return { data: await response.json() }
		} else {
			return {
				error: true
			}
		}
	}
	postVaccineProofUpload = async (body: any): Promise<any> => {
		let token = await new AuthProvider().getToken()

		const headerInit: any = {
			Authorization: 'Bearer ' + token.token,
			'Content-Type': 'application/json'
		}

		let postData = body

		const options: RequestInit = {
			method: 'POST',
			headers: new Headers(headerInit),
			body: JSON.stringify(postData),
			mode: 'cors'
		}

		let response = await fetch(this.url_vaccine_proof_upload, options)

		if (response.status >= 200 && response.status < 300) {
			return { data: await response.json() }
		} else {
			return {
				error: true
			}
		}
	}

	putVaccineProofUpload = async (body: any): Promise<any> => {
		let token = await new AuthProvider().getToken()
		const headerInit: any = {
			Authorization: 'Bearer ' + token.token,
			'Content-Type': 'application/json'
		}

		let putData = body

		const options: RequestInit = {
			method: 'PUT',
			headers: new Headers(headerInit),
			body: JSON.stringify(putData),
			mode: 'cors'
		}

		let response = await fetch(this.url_vaccine_proof_upload, options)

		if (response.status >= 200 && response.status < 300) {
			return { data: await response.json() }
		} else {
			return {
				error: true
			}
		}
	}

	deleteVaccineProof = async (proof_guid: string, type: string, shot_number?: number): Promise<any> => {
		let token = await new AuthProvider().getToken()

		const headerInit: any = {
			Authorization: 'Bearer ' + token.token,
			'Content-Type': 'application/json'
		}

		const options: RequestInit = {
			method: 'DELETE',
			headers: new Headers(headerInit),
			mode: 'cors'
		}

		let response = await fetch(
			this.url_vaccine_proof_upload + `?proof_guid=${proof_guid}&type=${type}${shot_number ? `&shot_number=${shot_number}` : ''}`,
			options
		)

		if (response.status >= 200 && response.status < 300) {
			return { success: true }
		} else {
			return {
				error: true
			}
		}
	}

	async fetchNotEligibleReasons(): Promise<any> {
		let response = await this.useFetch('GET', 'fetchNotEligibleReasons', this.url_not_eligible_reason)

		if (response.status >= 200 && response.status < 300) {
			let data = await response.json()
			return { data }
		} else {
			return {
				error: true
			}
		}
	}

	async getPdfConversion(file: any): Promise<any> {
		let response = await this.useFetch('POST', 'getPdfConversion', this.url_pdf_conversion, file)

		if (response.status === 200) {
			let data = await response.blob()

			return data
		} else {
			return {
				error: true
			}
		}
	}

	async getUploadUrl(image_key?: string): Promise<any> {
		let url: string
		image_key
			? (url = `${this.url_vaccine_proof_upload_url}?image_key=${image_key}`)
			: (url = this.url_vaccine_proof_upload_url)

		let response = await this.useFetch('GET', 'getUploadUrl', url)

		if (response.status >= 200 && response.status < 300) {
			return { data: await response.json() }
		} else {
			return {
				error: true
			}
		}
	}
	async getExemptionDocumentUrl(image_key?: string, fileName?: string): Promise<ExemptionUploadUrlResponse> {
		let url: string
		image_key
			? (url = `${this.url_exemption_documentation_upload}?image_key=${image_key}&file_content_type=image/jpeg&file_name=${fileName}`)
			: (url = `${this.url_exemption_documentation_upload}?file_content_type=image/jpeg&file_name=${fileName}`)

		let response = await this.useFetch('GET', 'getExemptionUploadUrl', url)

		if (response.ok) {
			return { data: await response.json() }
		} else {
			return {
				error: true
			}
		}
	}

	async uploadVaccineProof(url: string | any, file: any, fileName?: any): Promise<any> {
		let response = await this.useFetch('PUT', 'put/upload', url, file, true)

		if (response.status >= 200 && response.status < 300) {
			return true
		} else {
			let text = await response.text()
			return Promise.reject(text)
		}
	}

	async uploadExemptionProof(url: string | any, file: any, fileName?: any): Promise<any> {
		let response = await this.useFetch('PUT', 'put/upload', url, file, true)

		if (response.status >= 200 && response.status < 300) {
			return true
		} else {
			let text = await response.text()
			return Promise.reject(text)
		}
	}

	async checkVaccineProofImage(url: string): Promise<any> {
		// let response = await this.useFetch('GET', 'get/getImage', url);
		let response = await fetch(url)

		if (this.checkStatusCode(response.status)) {
			return { success: true }
		} else if (response.status === 404) {
			return { success: false }
		} else {
			let text = await response.text()
			return Promise.reject(text)
		}
	}

	deleteAppointment = async (mit_id: string, body: any): Promise<any> => {
		let token = await new AuthProvider().getToken()

		const headerInit: any = {
			Authorization: 'Bearer ' + token.token,
			'Content-Type': 'application/json'
		}

		const options: RequestInit = {
			method: 'DELETE',
			headers: new Headers(headerInit),
			body: JSON.stringify(body),
			mode: 'cors'
		}

		let response = await fetch(this.url_appointment + `?mid_id=${mit_id}`, options)

		if (response.status === 200) {
			return {}
		} else {
			return {
				error: true
			}
		}
	}

	deleteStandby = async (guid: string): Promise<any> => {
		let token = await new AuthProvider().getToken()

		const headerInit: any = {
			Authorization: 'Bearer ' + token.token,
			'Content-Type': 'application/json'
		}

		const options: RequestInit = {
			method: 'DELETE',
			headers: new Headers(headerInit),
			body: JSON.stringify({ vaccine_standby_guid: guid }),
			mode: 'cors'
		}

		let response = await fetch(this.url_vaccine_standby, options)

		if (response.status === 200) {
			return {}
		} else {
			return {
				error: true
			}
		}
	}

	postExemptionRequest = async (body: any): Promise<any> => {
		let token = await new AuthProvider().getToken()

		const headerInit: any = {
			Authorization: 'Bearer ' + token.token,
			'Content-Type': 'application/json'
		}

		let postData = body

		const options: RequestInit = {
			method: 'POST',
			headers: new Headers(headerInit),
			body: JSON.stringify(postData),
			mode: 'cors'
		}

		let response = await fetch(this.url_exemption_request, options)
		console.log('response', response)

		if (response.ok) {
			const data = await response.json()
			return { data: data }
		} else {
			return {
				error: true
			}
		}
	}

	updateExemptionRequest = async (body: any): Promise<any> => {
		let token = await new AuthProvider().getToken()

		const headerInit: any = {
			Authorization: 'Bearer ' + token.token,
			'Content-Type': 'application/json'
		}

		let postData = body

		const options: RequestInit = {
			method: 'PUT',
			headers: new Headers(headerInit),
			body: JSON.stringify(postData),
			mode: 'cors'
		}

		let response = await fetch(this.url_exemption, options)

		if (response.ok) {
			const data = await response.json()
			return { data: data }
		} else {
			return {
				error: true
			}
		}
	}

	async fetchPerson(): Promise<any> {
		let response = await this.useFetch('GET', 'fetchPerson', this.url_get_person)

		if (response.status >= 200 && response.status < 300) {
			let data = await response.json()
			return { data }
		} else {
			return {
				error: true
			}
		}
	}

	checkStatusCode(status: number): boolean {
		return status >= 200 && status < 300
	}
}

const vaccineController = new VaccineController()
export default vaccineController
