import * as React from "react";
import { FC } from "react";
import { useStoreState } from '../store/hooks'

export enum COVID_TEXTS {
    "covid19.pass.medical.not_required" = "covid19.pass.medical.not_required",
		"covid19.medical.flushot.header" = "covid19.medical.flushot.header",
    "covid19.medical.flushot.incomplete.title" = "covid19.medical.flushot.incomplete.title",
    "covid19.medical.flushot.incomplete.text" = "covid19.medical.flushot.incomplete.text",
		"covid19.medical.flushot.not.required.text" = "covid19.medical.flushot.not.required.text",
    "covid19.medical.flushot.url" = "covid19.medical.flushot.url",
    "covid19.medical.flushot.action1" = "covid19.medical.flushot.action1",
    "covid19.medical.flushot.action2" = "covid19.medical.flushot.action2",
    "covid19.pass.action.medical" = "covid19.pass.action.medical",
    "covid19.pass.flushot.actionbox" = "covid19.pass.flushot.actionbox",
    "covid19.noaccess.text" = "covid19.noaccess.text",
    "covid19.pass.medical.complete" = "covid19.pass.medical.complete",
    "covid19.pass.medical.incomplete" = "covid19.pass.medical.incomplete",
		"covid19.medical.flushot.action3" = "covid19.medical.flushot.action3",
    "covid19.pass.building_access" = "covid19.pass.building_access",
    "covid19.pass.test.testComplete" = "covid19.pass.test.testComplete",
    "covid19.pass.link.appstore" = "covid19.pass.link.appstore",
    "covid19.pass.link.googleplay" = "covid19.pass.link.googleplay",
    "covid19.pass.medical.continue.training" = "covid19.pass.medical.continue.training",
    "covid19.pass.medical.continue.attestation" = "covid19.pass.medical.continue.attestation",
    "covid19.pass.analytics_question_on_behalf" = "covid19.pass.analytics_question_on_behalf",
    "covid19.pass.pdf.buildings.url" = "covid19.pass.pdf.buildings.url",
    "covid19.pass.pdf.buildings.text" = "covid19.pass.pdf.buildings.text",
    "covid19.pass.pdf.access.url" = "covid19.pass.pdf.access.url",
    "covid19.pass.campusDate" = "covid19.pass.campusDate",
    "covid19.pass.attestation.completed_text" = "covid19.pass.attestation.completed_text",
    "covidpass.questions.title" = "covidpass.questions.title",
    "covid19.training.web.completed" = "covid19.training.web.completed",
    "covid19.medical.completed.text" = "covid19.medical.completed.text",
    "covid19.pass.training.continue.attestation" = "covid19.pass.training.continue.attestation",
    "covid19.notraining.text.medical" = "covid19.notraining.text.medical",
    "covid19.pass.training.continue.medical" = "covid19.pass.training.continue.medical",
    "covid19.training.web.required" = "covid19.training.web.required",
    "covid19.pass.training.not_required" = "covid19.pass.training.not_required",
    "covid19.pass.analytics_descr" = "covid19.pass.analytics_descr",
    "covid19.pass.analytics_question" = "covid19.pass.analytics_question",
    "covid19.pass.no_buildings" = "covid19.pass.no_buildings",
    "covid19.pass.medical.overdue_grace.heading" = "covid19.pass.medical.overdue_grace.heading",
    "covid19.pass.medical.overdue_grace.detail" = "covid19.pass.medical.overdue_grace.detail",
    "covid19.pass.medical.due_soon.heading" = "covid19.pass.medical.due_soon.heading",
    "covid19.pass.medical.due_soon.detail" = "covid19.pass.medical.due_soon.detail",
    "covid19.pass.help_email" = "covid19.pass.help_email",
    "covid19.pass.medical.overdue_grace.notification" = "covid19.pass.medical.overdue_grace.notification",
    "covid19.pass.medical.due_soon.notification" = "covid19.pass.medical.due_soon.notification",
    "covid19.pass.medical.notification_button" = "covid19.pass.medical.notification_button",
    "covid19.pass.has_symptoms" = "covid19.pass.has_symptoms",
    "covid19.pass.disqualified" = "covid19.pass.disqualified",
    "covid19.pass.medical_overdue" = "covid19.pass.medical_overdue",
    "covid19.pass.must_test" = "covid19.pass.must_test",
    "covid19.queue.label" = "covid19.queue.label",
    "covid19.queue.sub_label" = "covid19.queue.sub_label",
    "covid19.pass.curent_q_lengths" = "covid19.pass.curent_q_lengths",
    "covid19.medical.flushot.text" = "covid19.medical.flushot.text",
    "covid19.onboard.alreadysigned.text" = "covid19.onboard.alreadysigned.text",
    "covid19.onboard.alreadysigned.action" = "covid19.onboard.alreadysigned.action",
    "covid19.medical.hold.awaiting_test_outcome.title" = "covid19.medical.hold.awaiting_test_outcome.title",
    "covid19.medical.hold.awaiting_test_outcome.text" = "covid19.medical.hold.awaiting_test_outcome.text",
    "covid19.medical.hold.expiry" = "covid19.medical.hold.expiry",
    "covid19.medical.hold.residential.title" = "covid19.medical.hold.residential.title",
    "covid19.medical.hold.residential.text" = "covid19.medical.hold.residential.text",

    /* Incomplete */
    "covid19.pass.noaccess.sub" = "covid19.pass.noaccess.sub",
    "covid19.pass.noaccess.actionbox" = "covid19.pass.noaccess.actionbox",
    /* No access */
    "covid19.noaccess.title" = "covid19.noaccess.title",
    "covid19.noaccess.text.web" = "covid19.noaccess.text.web",
    "covid19.noaccess.instructions" = "covid19.noaccess.instructions",
    /* Medical Positive */
    "covid19.notsafe.results.title" = "covid19.notsafe.results.title",
    "covid19.notsafe.results.subtitle" = "covid19.notsafe.results.subtitle",
    /* Medical Symptoms */
    "covid19.notsafe.title" = "covid19.notsafe.title",
    "covid19.notsafe.subtitle" = "covid19.notsafe.subtitle",
    /* Medical Hold */
    "covid19.medical.hold.title" = "covid19.medical.hold.title",
    /* Waiver Declined */
    "covid19.noagreement.title" = "covid19.noagreement.title",
    "covid19.noagreement.action" = "covid19.noagreement.action",

    "covid19.pass.sub" = "covid19.pass.sub",
    "covid19.pass.info1" = "covid19.pass.info1",

    "covid19.dashboard.incomplete.attestation.action" = "covid19.dashboard.incomplete.attestation.action",
    "covid19.dashboard.incomplete.training.action" = "covid19.dashboard.incomplete.training.action",
    "covid19.dashboard.incomplete.medical.action" = "covid19.dashboard.incomplete.medical.action",
    "covid19.dashboard.incomplete.waiver.action" = "covid19.dashboard.incomplete.waiver.action",

    "covid19.resident.confirm.title" = "covid19.resident.confirm.title",
    "covid19.resident.confirm.body" = "covid19.resident.confirm.body",
    "covid19.resident.confirm.actionbar" = "covid19.resident.confirm.actionbar",
    "covid19.resident.confirm.action.no" = "covid19.resident.confirm.action.no",
    "covid19.resident.confirm.action.yes" = "covid19.resident.confirm.action.yes",

    //Travel hold
    "covid19.travel.hold.title" = "covid19.travel.hold.title",
    "covid19.travel.hold.text" = "covid19.travel.hold.text",
    "covid19.travel.hold.action" = "covid19.travel.hold.action",

    //Pin
    "pin.title" = "pin.title",
    "pin.action.create" = "pin.title",
    "pin.action.reset" = "pin.title",
    "pin.text" = "pin.title",

    //Testing
    "covid19.noaccess.wtesting.title" = "covid19.noaccess.wtesting.title",
    "covid19.noaccess.wtesting.text" = "covid19.noaccess.wtesting.text",
    "covid19.noaccess.wtesting.waiver" = "covid19.noaccess.wtesting.waiver",

    //Contact tracing
    "covid19.contactt.title" = "covid19.contactt.title",
    "covid19.contactt.info" = "covid19.contactt.info",
    "covid19.contactt.action" = "covid19.contactt.action",
    "covid19.contactt.medicaltitle" = "covid19.contactt.medicaltitle",
    "covid19.contactt.fullname" = "covid19.contactt.fullname",
    "covid19.contactt.phone" = "covid19.contactt.phone",
    "covid19.contactt.email" = "covid19.contactt.email",
    "covid19.contactt.date" = "covid19.contactt.date",

    //Vaccine
    "covid19.medical.vaccine.title" = "covid19.medical.vaccine.title",
    "covid19.medical.vaccine.action" = "covid19.medical.vaccine.action",
    "covid19.vaccine.not_eligible.action" = "covid19.vaccine.not_eligible.action",
    "covid19.medical.vaccine.complete.badge" = "covid19.medical.vaccine.complete.badge",
    "covid19.vaccine.eligibility.thankyou" = "covid19.vaccine.eligibility.thankyou",

    "covid19.medical.header.menu.covid" = "covid19.medical.header.menu.covid",
    "covid19.medical.header.menu.flushot" = "covid19.medical.header.menu.flushot",
    "covid19.medical.header.menu.vaccine" = "covid19.medical.header.menu.vaccine",

    //Vaccine Eligible Page:
    "covid19.vaccine.eligible.title" = "covid19.vaccine.eligible.title",
    "covid19.vaccine.eligible.text" = "covid19.vaccine.eligible.text",

    //Vaccine InProgressPage:
    "covid19.vaccine.inprogress.title" = "covid19.vaccine.inprogress.title",
    "covid19.vaccine.inprogress.text" = "covid19.vaccine.inprogress.text",

    //Vaccine Completed Page
    "covid19.vaccine.completed.title" = "covid19.vaccine.completed.title",
    "covid19.vaccine.completed.text" = "covid19.vaccine.completed.text",

    "covid19.vaccine.completed.linktext" = "covid19.vaccine.completed.linktext",
	"covid19.vaccine.completed.action" = "covid19.vaccine.completed.action",
	"covid19.vaccine.completed.url" = "covid19.vaccine.completed.url",

	//Appointment
	"covid19.vaccine.appointment.text_date" = "covid19.vaccine.appointment.text_date",
	"covid19.vaccine.appointment.text_time" = "covid19.vaccine.appointment.text_time",
	"covid19.vaccine.appointment.text" = "covid19.vaccine.appointment.text",
	"covid19.vaccine.appointment.title" = "covid19.vaccine.appointment.title",
	"covid19.vaccine.appointment.action" = "covid19.vaccine.appointment.action",
	"covid19.vaccine.appointment.action_change" = "covid19.vaccine.appointment.action_change",
	"covid19.vaccine.appointment.info" = "covid19.vaccine.appointment.info",

	"covid19.vaccine.consent.action_acknowledge" = "covid19.vaccine.consent.action_acknowledge",
	"covid19.vaccine.consent.text" = "covid19.vaccine.consent.text",
	"covid19.vaccine.consent.title" = "covid19.vaccine.consent.title",

	"covid19.vaccine.question1.title" = "covid19.vaccine.question1.title",
	"covid19.vaccine.question2.title" = "covid19.vaccine.question2.title",
	"covid19.vaccine.question1.text" = "covid19.vaccine.question1.text",
	"covid19.vaccine.question2.text" = "covid19.vaccine.question2.text",

	"covid19.vaccine.no_appointment.action" = "covid19.vaccine.no_appointment.action",
	"covid19.vaccine.no_appointment.text" = "covid19.vaccine.no_appointment.text",
	"covid19.vaccine.no_appointment.title" = "covid19.vaccine.no_appointment.title",

	"covid19.vaccine.missed_appointment.action" = "covid19.vaccine.missed_appointment.action",
	"covid19.vaccine.missed_appointment.title" = "covid19.vaccine.missed_appointment.title",
	"covid19.vaccine.missed_appointment.text" = "covid19.vaccine.missed_appointment.text",

	"covid19.vaccine.appointment_second.title" = "covid19.vaccine.appointment_second.title",
	"covid19.vaccine.appointment_second.text" = "covid19.vaccine.appointment_second.text",
	"covid19.vaccine.question1.popup" = "covid19.vaccine.question1.popup",
    "covid19.vaccine.question2.popup" = "covid19.vaccine.question2.popup",
    "covid19.vaccine.question2.popup.no" = "covid19.vaccine.question2.popup.no",
    "covid19.vaccine.question2.popup.yes" = "covid19.vaccine.question2.popup.yes",

	"covid19.vaccine.appointment.cant_change.text" = "covid19.vaccine.appointment.cant_change.text",

	//Questions
	"covid19.vaccine.appointment.questions.action" = "covid19.vaccine.appointment.questions.action",

	//Invite
	"covid19.vaccine.invite.action_accept" = "covid19.vaccine.invite.action_accept",
	"covid19.vaccine.invite.action_reject" = "covid19.vaccine.invite.action_reject",
	"covid19.vaccine.invite.title" = "covid19.vaccine.invite.title",
	"covid19.vaccine.standby.text" = "covid19.vaccine.standby.text",

	//Survey
	"covid19.vaccine.not_eligible.title" = "covid19.vaccine.not_eligible.title",
	"covid19.vaccine.survey.title" = "covid19.vaccine.survey.title",
	"covid19.vaccine.survey.text" = "covid19.vaccine.survey.text",
	"covid19.vaccine.survey.info" = "covid19.vaccine.survey.info",
	"covid19.vaccine.survey.action" = "covid19.vaccine.survey.action",

    //Proof of Vaccine (pov)
    "vaccine.pov.detail.approved" = "vaccine.pov.detail.approved",
    "vaccine.pov.detail.company" = "vaccine.pov.detail.company",
    "vaccine.pov.detail.dose" = "vaccine.pov.detail.dose",
    "vaccine.pov.detail.header" = "vaccine.pov.detail.header",
    "vaccine.pov.detail.lot" = "vaccine.pov.detail.lot",
    "vaccine.pov.detail.photo.caption" = "vaccine.pov.detail.photo.caption",
    "vaccine.pov.detail.photo.title" = "vaccine.pov.detail.photo.title",
    "vaccine.pov.detail.received" = "vaccine.pov.detail.received",
    "vaccine.pov.detail.rejected" = "vaccine.pov.detail.rejected",
    "vaccine.pov.detail.resubmit" = "vaccine.pov.detail.resubmit",
    "vaccine.pov.detail.review" = "vaccine.pov.detail.review",
    "vaccine.pov.detail.site" = "vaccine.pov.detail.site",
    "vaccine.pov.detail.type" = "vaccine.pov.detail.type",
    "vaccine.pov.list.action_rejected" = "vaccine.pov.list.action_rejected",
    "vaccine.pov.list.approved" = "vaccine.pov.list.approved",
    "vaccine.pov.list.header" = "vaccine.pov.list.header",
    "vaccine.pov.list.pending" = "vaccine.pov.list.pending",
    "vaccine.pov.list.rejected" = "vaccine.pov.list.rejected",
    "vaccine.pov.no-record.none.give-reason" = "vaccine.pov.no-record.none.give-reason",
    "vaccine.pov.no-record.title" = "vaccine.pov.no-record.title",

		"vaccine.exemption.agreement" = "vaccine.exemption.agreement",
		"vaccine.exemption.extcontractor" = "vaccine.exemption.extcontractor",

		// STATUS BLOCK TEXTS
		"covid.vaccine.general.noflu.novaccine.heading" = "covid.vaccine.general.noflu.novaccine.heading",
		"covid.vaccine.general.noflu.novaccine.text" = "covid.vaccine.general.noflu.novaccine.text",

		"covid.vaccine.general.flu.vaccine.heading" = "covid.vaccine.general.flu.vaccine.heading",
		"covid.vaccine.general.flu.vaccine.text" = "covid.vaccine.general.flu.vaccine.text",

		"covid.vaccine.general.flu.novaccine.heading" = "resource-heading=covid.vaccine.general.flu.novaccine.heading",
		"covid.vaccine.general.flu.novaccine.text" = "resource-text=covid.vaccine.general.flu.novaccine.text",

		"covid.vaccine.proof.pending.heading" = "covid.vaccine.proof.pending.heading",
		"covid.vaccine.proof.pending.text" = "covid.vaccine.proof.pending.text",

		'covid.vaccine.booster.due.heading' = 'covid.vaccine.booster.due.heading',
		'covid.vaccine.booster.due.text' = 'covid.vaccine.booster.due.text',

		'covid.vaccine.booster.pending.heading' = 'covid.vaccine.booster.pending.heading',
		'covid.vaccine.booster.pending.text' = 'covid.vaccine.booster.pending.text',

		'covid.vaccine.booster.denied.heading' = 'covid.vaccine.booster.denied.heading',
		'covid.vaccine.booster.denied.text' = 'covid.vaccine.booster.denied.text',

		'covid.vaccine.proof.partial.heading' = 'covid.vaccine.proof.partial.heading',
		'covid.vaccine.proof.partial.text' = 'covid.vaccine.proof.partial.text',

		'covid.vaccine.exemption.denied.heading' = 'covid.vaccine.exemption.denied.heading',
		'covid.vaccine.exemption.denied.text' = 'covid.vaccine.exemption.denied.text',

		'covid.vaccine.exemption.pending.heading' = 'covid.vaccine.exemption.pending.heading',
		'covid.vaccine.exemption.pending.text' = 'covid.vaccine.exemption.pending.text',

		'covid.vaccine.exemption.needs.info.heading' = 'covid.vaccine.exemption.needs.info.heading',
		'covid.vaccine.exemption.needs.info.text' = 'covid.vaccine.exemption.needs.info.text',

		'covid.vaccine.proof.denied.heading' = 'covid.vaccine.proof.denied.heading',
		'covid.vaccine.proof.denied.text' = 'covid.vaccine.proof.denied.text',

		'covid19.medical.vaccine.vaccine_title' = 'covid19.medical.vaccine.vaccine_title',
		'covid19.medical.vaccine.partial_vaccine_title' = 'covid19.medical.vaccine.partial_vaccine_title',
		'covid19.medical.vaccine.booster_title' = 'covid19.medical.vaccine.booster_title',
		'covid19.medical.vaccine.exemption_title' = 'covid19.medical.vaccine.exemption_title',
		// 'covid19.medical.vaccine.exemption_more_info_title' = 'covid19.medical.vaccine.exemption_more_info_title',
		'covid19.medical.vaccine.flu_shot_title' = 'covid19.medical.vaccine.flu_shot_title',

		// Optional
		// Missing
		// Information
		// Pending
		// Denied
		'covid19.medical.vaccine.secondary_text.optional' = 'covid19.medical.vaccine.secondary_text.optional',
		'covid19.medical.vaccine.secondary_text.missing' = 'covid19.medical.vaccine.secondary_text.missing',
		'covid19.medical.vaccine.secondary_text.information' = 'covid19.medical.vaccine.secondary_text.information',
		'covid19.medical.vaccine.secondary_text.pending' = 'covid19.medical.vaccine.secondary_text.pending',
		'covid19.medical.vaccine.secondary_text.denied' = 'covid19.medical.vaccine.secondary_text.denied',

		"vaccine.exemption.religious.disclaimer" = "vaccine.exemption.religious.disclaimer",
		'vaccine.exemption.religious.agreement' = 'vaccine.exemption.religious.agreement',
		'vaccine.exemption.medical-support-upload.title' = 'vaccine.exemption.medical-support-upload.title',

		'covid.vaccine.booster.heading' = 'covid.vaccine.booster.heading',
		'covid.vaccine.booster.text' = 'covid.vaccine.booster.text'

}

interface CovidTextProps {
    textId: COVID_TEXTS | string;
    className?: string;
}

const CovidTextComponent: FC<CovidTextProps> = ({ textId, className }) => {
	const { appText } = useStoreState(store => store.globalModel)
	return <p dangerouslySetInnerHTML={{ __html: appText[textId] }} className={className ? className : ""} />
}

const CovidText = CovidTextComponent
export default CovidText
