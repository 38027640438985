import React from 'react'
import appConfig from '../../app-config'
import { NavBar, NavBarTypes, NavItemState } from '@mit/hui'
import { useStoreState } from '../../store/hooks'

interface Props {
	placeholder?: any
}

export const MainNavBar: React.FC<Props> = () => {

	const { features } = useStoreState(store => store.globalModel)

	let tabs: any = [
		{
			icon: 'heart',
			iconOnly: false,
			text: 'Pass',
			state: NavItemState.None,
			onClick: () => (window.location.href = appConfig.covidPassUrl)
		},
		{
			icon: 'plane',
			iconOnly: false,
			text: 'Travel',
			state: features["travel.enabled"] ? NavItemState.None : NavItemState.Invisible,
			onClick: () => (window.location.href = appConfig.covidTravelUrl)
		},
		{
			icon: 'vial',
			iconOnly: false,
			text: 'Vaccine',
			state: NavItemState.Active,
		}
	]

	return <NavBar type={NavBarTypes.IconText} tabs={tabs} />
}
