import { CounterModel, counterModel } from './counter'
import { GlobalModel, globalModel } from './global'

export interface Model {
	counterModel: CounterModel
	globalModel: GlobalModel
}

export const model: Model = {
	counterModel,
	globalModel
}
