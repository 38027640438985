export enum RequirementIds {
	acknowledge = 'acknowledge',
	training = 'training',
	medical = 'medical',
	attestation = 'attestation'
}

export enum RequirementStatus {
	complete = 'complete',
	incomplete = 'incomplete',
	flushot_incomplete = 'flushot_incomplete',
	pending = 'pending',
	due_soon = 'due_soon',
	overdue_grace = 'overdue_grace',
	retest_due_soon = 'retest_due_soon'
}

export enum PassStatus {
	access_granted = "access_granted",
	requirements_incomplete = "requirements_incomplete",
	medical_block = "medical_block",
	no_access = "no_access",
	medical_symptoms = "medical_symptoms",
	medical_positive = "medical_positive",
	medical_overdue = "medical_overdue",
	waiver_declined = "waiver_declined",
	hold = "hold",
	no_access_with_testing = "no_access_with_testing"
}

export enum PrimaryAffiliatons {
	student = "student",
	staff = "staff",
	affiliate = "affiliate"
}

export enum HoldReason {
	none = 'none',
	awaiting_test_outcome = 'awaiting_test_outcome',
	residential = 'residential',
	travel = 'travel'
}

export interface CovidPassRequirement {
	id: RequirementIds;
	required: boolean;
	visible: boolean;
	status: RequirementStatus;
	title_web: string;
	last_completion?: string;
	next_completion?: string;
	prerequisites?: string[];
	medical_awaiting_test_outcome: boolean;
	flu_shot_required: boolean;
	flu_shot_completed: boolean;
	flu_shot_visible: boolean;
}

export interface PassStatusModel {
	status: PassStatus;
	vaccine_status: string;
	hold_reason: HoldReason;
	hold_expiry: string;
	assignment_type: "research";
	primary_affiliation: PrimaryAffiliatons;
	requirements: CovidPassRequirement[];
	evalRequirement(id: RequirementIds, value: RequirementStatus): boolean;
	getRequirement(id: RequirementIds): CovidPassRequirement | undefined;
	isFlushotRequired(): boolean;
}

export class StatusHelper {

	static getStepperItems(stepperItemCount: number, stepperSelectedIndex: number): any[] {

		const selectedItems: any[] = [];

		if (stepperItemCount === 0)
			return selectedItems;

		for (var a = 0; a < stepperItemCount; a++) {
			selectedItems.push({ selected: false });
		}

		selectedItems[stepperSelectedIndex].selected = true;

		return selectedItems;
	}

	static evalRequirement(status: PassStatusModel | undefined, id: RequirementIds, value: RequirementStatus): boolean {

		if (this.getRequirement) {
			let result = this.getRequirement(status, id);

			if (result) {
				return result!.status === value;
			}
		}

		return false;
	}

	static getRequirement(status: PassStatusModel | undefined, id: RequirementIds): CovidPassRequirement | undefined {
		if (!status) {
			return undefined;
		}

		return status.requirements.find((r: CovidPassRequirement) => r.id === id)
	}

	static isFlushotRequired(status: PassStatusModel | undefined): boolean {

		const medicalRequirement = this.getRequirement(status, RequirementIds.medical);

		if (medicalRequirement
			&& medicalRequirement.status !== RequirementStatus.incomplete
			&& medicalRequirement.flu_shot_required
			&& !medicalRequirement.flu_shot_completed) {

			return true;
		}

		return false;
	}
}

export class PreScreeningHelper {

	static getStepperItems(stepperItemCount: number, stepperSelectedIndex: number): any[] {

		const selectedItems: any[] = [];

		if (stepperItemCount === 0)
			return selectedItems;

		for (var a = 0; a < stepperItemCount; a++) {
			selectedItems.push({ selected: false });
		}

		selectedItems[stepperSelectedIndex].selected = true;

		return selectedItems;
	}
}
