import * as React from 'react'
import { FC } from 'react'
import VaccineWaiver from './VaccineWaiver'
import { Spacer } from '@mit/hui'
import { StoreProvider } from 'easy-peasy'
import { store } from '../../store'

interface VaccineWaiverLandingProps {}

const VaccineWaiverLandingComponent: FC<VaccineWaiverLandingProps> = () => {
// const VaccineWaiverLandingComponent: FC<VaccineWaiverLandingProps> = ({}) => {
	return (
		<div className={`submitter-view`}>
			<div className={'nav-container'}>
				<div className={`main-content`}>
					<div className={`page-content text-align-center`}>
						<StoreProvider store={store}>
							<VaccineWaiver />
						</StoreProvider>
						<Spacer size="5" />
					</div>
				</div>
			</div>
		</div>
	)
}

const VaccineWaiverLanding = VaccineWaiverLandingComponent

export default VaccineWaiverLanding
