import * as React from 'react'
import { useState, useEffect } from 'react'
import {
	Alert,
	Container,
	ButtonType,
	// ButtonBarType,
	ButtonBar,
	Text,
	TextType,
	TextArea,
	Spacer,
	Button,
	SkeletonLoader,
	ImageUpload,
	TemplateModal,
	TemplateModalAlignment,
	TemplateModalSize,
	Checkbox,
	Icon,
	// FormField,
	Label
} from '@mit/hui'
import { useStoreActions, useStoreState } from '../../store/hooks'
import /* CovidText,  */{ COVID_TEXTS } from '../../common/Texts'
import vaccineController, { ExemptionTypes } from '../../api/controller/VaccineController'
// import fluShotController from '../../api/controller/FluShotController'
import { ActiveDrawer } from '../../store/models/global'
import Resizer from 'react-image-file-resizer'
import * as pdfjsLib from 'pdfjs-dist/es5/build/pdf'
import pdfjsWorker from 'pdfjs-dist/es5/build/pdf.worker.entry'
import { ImageCrop } from '../../common/ImageCrop'
import { ButtonState } from '@mit/hui'

const ExemptionView = () => {
	const [exemptionType, setExemptionType] = useState<number>()
	const [exemptionReason, setExemptionReason] = useState<string>('')
	const [initialReason, setInitialReason] = useState<string>('')
	const [exemptionDocUrl, setExemptionDocUrl] = useState<any>({ image_key: '', signed_url: '' })
	const { appText/* , isExemptionReqSubmitted */, currentPendingExemption, person, reqExemptBtnClicked } = useStoreState(
		store => store.globalModel
	)
	const {
		setActiveDrawer,
		// setIsExemptionReqSubmitted,
		setReloadVaccineData,
		setCurrentPendingExemption
	} = useStoreActions(store => store.globalModel)
	const [busy, setBusy] = useState(false)
	// All the following states have to do with image uploading and cropping
	const [isUploading, setUploading] = useState(false)
	// const [isImagePortrait, setIsImagePortrait] = useState(false)
	const [fileChanged, setFileChanged] = useState<boolean>(false)
	const [hasImage, setHasImage] = useState<boolean>(false)
	const [displayData, setDisplayData] = useState<any>({})
	const [showPreview, setShowPreview] = useState<boolean>(false)
	const [showCropModal, setShowCropModal] = useState<boolean>(false)
	const [file, setFile] = useState<any>()
	const [fileName, setFileName] = useState('')
	const [fileContentType, setFileContentType] = useState('')
	const [religiousTicked, setReligiousTicked] = useState(false)
	const [agreementTicked, setAgreementTicked] = useState(false)
	const [extContractorTicked, setExtContractorTicked] = useState(false)
	const [exemptionTypes, setExemptionTypes] = useState<ExemptionTypes[] | undefined>()
	const [errorAlerts, setErrorAlerts] = useState<string[]>([])
	const [isApprovedOrRejected, setIsapprovedOrRejected] = useState(false)
	const [isRejected, setIsRejected] = useState(false)

	const religiousExemptText =
		'To be eligible for a religious exemption you must demonstrate that your refusal to be vaccinated is based on a sincere belief that is religious in nature. A refusal to be vaccinated does not qualify for a religious exemption if it is based on personal preference, concerns about the possible effects of the vaccine, or political opinions. Any intentional misrepresentation to MIT may result in denial of this request and further disciplinary action.'
	const religiousExemptLabel = 'This vaccination is inconsistent with my sincerely held religious beliefs'
	const medicalExemptionText = 'You may upload medical documentation from your health care provider here or your provider can securely send documentation directly to Human Resources. For directions on submitting information to HR, employees or their providers should contact: hr-dsmlo@mit.edu for Campus employees, or covidvaxexemption@ll.mit.edu for Lincoln Laboratory employees.'

	useEffect(() => {
		vaccineController.fetchExemptions(person.mitId).then(resp => {
			if (resp.error) {
				console.log('Error fetching pending exemption requests')
			} else if (resp.data && resp.data.length > 0) {
				if (!(resp.data[0].status === 'R' && reqExemptBtnClicked)) {
					setCurrentPendingExemption(resp.data[0])
					const apprOrRej = ['A', 'R'].includes(resp.data[0].status)
					const isRej = resp.data[0].status === 'R'
					setIsRejected(isRej)
					setIsapprovedOrRejected(apprOrRej)
				}
			}
		})
	}, [person.mitId, reqExemptBtnClicked, setCurrentPendingExemption])

	useEffect(() => {
		if (currentPendingExemption) {
			console.log('currentPendingExemption', currentPendingExemption)
			const {
				type,
				reason,
				imageUrl,
				imageKey,
				status,
				isExternalContractor,
				fileContentType,
				fileName
			} = currentPendingExemption
			setExemptionType(type)
			setFileName(fileName)
			setFileContentType(fileContentType)
			setExemptionReason(status === 'M' ? '' : reason)
			setInitialReason(status === 'M' ? reason + '\n\n' : '')
			setAgreementTicked(true)
			if (type === 2 /* Religious */) {
				setReligiousTicked(true)
			}
			if (isExternalContractor) {
				setExtContractorTicked(true)
			}
			if (imageUrl) {
				setDisplayData({
					image_url: imageUrl
				})
				setExemptionDocUrl({
					image_key: imageKey,
					signed_url: imageUrl
				})
				setFile([{ preview: imageUrl }])
				setHasImage(true)
				// checkImage(imageUrl).then(resp => {
				// 	setDisplayData({
				// 		image_url: resp
				// 	})
				// 	setExemptionDocUrl({
				// 		image_key: imageKey, signed_url: imageUrl
				// 	})
				// 	setFile([{ preview: resp }])
				// 	setHasImage(true)
				// })
			} else {
				setDisplayData({
					image_url: ''
				})
				setFile([{ preview: '' }])
			}
		}
	}, [currentPendingExemption])

	useEffect(() => {
		setBusy(true)
		vaccineController.fetchExemptionTypes().then(res => {
			if (res.error) {
				console.log(res)
			} else {
				setExemptionTypes(res.data)
				setBusy(false)
			}
		})
	}, [])

	// const checkImage = async (image_url: string | any) => {
	// 	const hasImage = await vaccineController.checkVaccineProofImage(image_url)
	// 	if (hasImage.success) return image_url
	// 	else return ''
	// }

	const exemptionsBtnsData = exemptionTypes?.map((exemption, index) => {
		const isActive = exemptionType === exemption.id
		return (
			<span key={`exemptBtn-${exemption.id}`}>
				<button
					className="btn btn-primary elevation-0"
					id={isActive ? 'exemption-btn-active' : 'exemption-btn'}
					aria-label={exemption.name}
					onClick={() => setExemptionType(exemption.id)}
					disabled={isApprovedOrRejected}
				>
					<Icon
						type="light"
						icon={exemption.id === 1 ? 'clock' : exemption.icon}
						// padded?= boolean
						// color?= string
						// onClick?= () => void
						// tabIndex?= number
						// hideAriaLabel?= boolean
					/>
					<span style={{ fontWeight: 'bold', fontSize: '1em' }}>{exemption.name}</span>
					<br />
					<span style={{ fontSize: '0.8em' }}>{exemption.description}</span>
				</button>
			</span>
		)
	})
	// const exemptionsBtnsData = exemptionTypes?.map((exemption, index) => {
	// 	const isActive = exemptionType === exemption.id
	// 		return {
	// 			id: isActive ? 'exemption-btn-active' : 'exemption-btn',
	// 			text: `${exemption.name === 'Remote'? `${exemption.name} Work`: exemption.name} Exemption`,
	// 			type: ButtonType.Primary,
	// 			icon: exemption.icon,
	// 			onClick: () => setExemptionType(exemption.id),
	// 			customAriaLabel: isActive ? `${exemption.name}, selected` : exemption.name
	// 		}
	// })

	const resizeFile = (file: any) => {
		return new Promise(resolve => {
			Resizer.imageFileResizer(
				file, // Is the file of the image which will resized
				800, // Is the maxWidth of the resized new image.
				1000, // Is the maxHeight of the resized new image.
				'JPEG', // Is the compressFormat of the resized new image.
				100, // Is the quality of the resized new image.
				0, // Is the degree of clockwise rotation to apply to uploaded image.
				uri => {
					// Is the callBack function of the resized new image URI.
					resolve(uri)
				},
				'base64' // Is the output type of the resized new image.
			)
		})
	}

	// const getImgDimension = (src: any) => {
	// 	var i = new Image()
	// 	i.src = src
	// 	return {
	// 		naturalWidth: i.width,
	// 		naturalHeight: i.height
	// 	}
	// }

	// useEffect(() => {
	// 	console.log('fileName', fileName)
	// }, [fileName])

	const imageUpload = async (fileData: any) => {
		if (fileData.length >= 1 && fileData[0].type !== 'application/pdf') {
			setUploading(true)
			const file = fileData[0]
			const image = await resizeFile(file)
			// Check if image is portrait
			// const { naturalWidth, naturalHeight } = getImgDimension(image)
			// if (naturalWidth < naturalHeight) {
			// 	setIsImagePortrait(true)
			// } else {
			// 	setIsImagePortrait(false)
			// }
			// end check
			setFileChanged(true)
			setHasImage(true)
			setFileName(fileData[0].name)
			setDisplayData({
				image_url: image
			})
			// setExemptionDocUrl({
			// 	image_key: '',
			// 	signed_url: image
			// })
			setShowPreview(true)
		} else {
			setUploading(true)
			const file = fileData[0]
			var fileReader = new FileReader()
			pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker

			fileReader.onload = async function(e) {
				var typedarray = new Uint8Array(e.target!.result as ArrayBuffer)

				const loadingTask = pdfjsLib.getDocument(typedarray)
				const pdfDoc = await loadingTask.promise

				const page = await pdfDoc.getPage(1)
				const viewport = page.getViewport({ scale: 1.0 })

				var canvas = document.createElement('canvas'),
					ctx = canvas.getContext('2d')
				var renderContext = {
					canvasContext: ctx as object,
					viewport: viewport
				}

				canvas.height = viewport.height
				canvas.width = viewport.width

				const renderTask = page.render(renderContext)
				await renderTask.promise

				const image = canvas.toDataURL('image/jpeg')
				// Check if image is portrait
				// const { naturalWidth, naturalHeight } = getImgDimension(image)
				// if (naturalWidth < naturalHeight) {
				// 	setIsImagePortrait(true)
				// } else {
				// 	setIsImagePortrait(false)
				// }
				// end check
				setFileChanged(true)
				setHasImage(true)
				setFileName(`${fileData[0].name.slice(0, fileData[0].name.indexOf('.'))}.jpg`)
				setDisplayData({
					image_url: image
				})
				setShowPreview(true)
			}

			fileReader.readAsArrayBuffer(file)
		}
	}

	const onSaveImage = (): void => {
		const blob = b64toBlob(displayData.image_url)
		setFile(blob)
		setShowPreview(false)
		setUploading(false)
		saveImage()
	}

	function b64toBlob(dataURI: any) {
		const byteString = atob(dataURI.split(',')[1])
		const ab = new ArrayBuffer(byteString.length)
		const ia = new Uint8Array(ab)

		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i)
		}
		return new Blob([ab], { type: 'image/jpeg' })
	}

	const saveImage = (): void => {
		console.log('exemptionDocUrl', exemptionDocUrl)
		vaccineController
			.getExemptionDocumentUrl(exemptionDocUrl.image_key !== '' ? exemptionDocUrl.image_key : undefined, fileName)
			.then(response => {
				// let fluShot: FluShotProof = {
				// 	externalLocation: fluShotData ? fluShotData.externalLocation : '',
				// 	dateReceived: fluShotData ? fluShotData.dateReceived : '',
				// 	imageKey: response.data.imageKey,
				// 	imageUrl: response.data.imageUrl,
				// 	// id: fluShotData ? fluShotData.id : '',
				// 	name: fluShotData ? fluShotData.name : '',
				// 	provider: fluShotData ? fluShotData.provider : ''
				// }
				// setFluShotData(fluShot)
				console.log(response)
				setExemptionDocUrl({
					image_key: response.data?.imageKey,
					signed_url: response.data?.imageUrl
				})
				// console.log('exemptionDocUrl', exemptionDocUrl)
			})
			.catch(err => console.log(err))
	}

	// useEffect(() => {
	// 	console.log('exemptionDocUrl', exemptionDocUrl)
	// }, [exemptionDocUrl])
	// useEffect(() => {
	// 	console.log('file', file)
	// }, [exemptionDocUrl])
	// useEffect(() => {
	// 	console.log('fileChanged', fileChanged)
	// }, [exemptionDocUrl])

	const closeImageCropped = (): void => {
		setShowCropModal(false)
		setUploading(false)
		setFileChanged(false)
		setFile('')
		setDisplayData({
			image_url: ''
		})
	}

	const handleImageCropped = (blob: Blob, previewUrl: string): void => {
		setFile(blob)
		setDisplayData({
			image_url: previewUrl
		})
		setShowCropModal(false)
		setUploading(false)
		saveImage()
	}

	useEffect(() => {
		const reqBody = {
			type: exemptionType,
			reason:
				currentPendingExemption && currentPendingExemption.status === 'M'
					? initialReason + exemptionReason
					: exemptionReason,
			// imageUrl: exemptionDocUrl.signed_url,
			imageKey: exemptionDocUrl.image_key,
			isExternalContractor: extContractorTicked,
			fileName: fileName,
			fileContentType: 'image/jpeg'
		}
		console.log('reqBody', reqBody)
	}, [exemptionType, exemptionReason, exemptionDocUrl.image_key, extContractorTicked, fileName, currentPendingExemption, initialReason])

	const submitExemption = async () => {
		const errorsArr: string[] = []
		if ((exemptionType === 3 && person.type !== "EMPLOYEE") && !hasImage) {
			// medical exemption, requires supporting docs if not an employee
			let drawerBody = document.getElementsByClassName('drawer')[0]
			drawerBody.scroll(0, 0)
			errorsArr.push('Please select a valid image')
			setErrorAlerts(errorsArr)
			return
		}
		setBusy(true)
		let reqBody = {}
		if (/* exemptionType === 3 &&  */ exemptionDocUrl.image_key) {
			// if supporting docs
			reqBody = {
				type: exemptionType,
				reason:
					currentPendingExemption && currentPendingExemption.status === 'M'
						? initialReason + exemptionReason
						: exemptionReason,
				// imageUrl: exemptionDocUrl.signed_url,
				imageKey: exemptionDocUrl.image_key,
				isExternalContractor: extContractorTicked,
				fileName: fileName,
				fileContentType: 'image/jpeg'
			}
		} else {
			// no supporting docs
			reqBody = {
				type: exemptionType,
				reason:
					currentPendingExemption && currentPendingExemption.status === 'M'
						? initialReason + exemptionReason
						: exemptionReason,
				isExternalContractor: extContractorTicked
			}
		}
		// console.log('reqBody', reqBody)

		const haveUploadedDoc = async () => {
			if (file && fileChanged) {
				return await vaccineController.uploadExemptionProof(exemptionDocUrl.signed_url, file, fileName)
			}
			return true
		}
		console.log('currentPendingExemption', currentPendingExemption)
		if (currentPendingExemption) {
			// Edit existing exemption request PUT
			// Update current pending exemption
			const updateExemptionReq = async () =>
				await vaccineController.updateExemptionRequest({ ...reqBody, id: currentPendingExemption.id })
			// if (exemptionType === 3 /* Medical */) {
			if (hasImage) {
				// has supporting documentation
				if (await haveUploadedDoc()) {
					updateExemptionReq().then((resp) => {
						if (resp.error) {
							errorsArr.push('An error occured while uploading the request.')
							setBusy(false)
							setErrorAlerts(errorsArr)
							return
						} else {
							setReloadVaccineData(true)
							// setIsExemptionReqSubmitted(true)
							setActiveDrawer(ActiveDrawer.None)
							setBusy(false)
						}
					})
				}
			} else {
				// dont have supporting documentation
				updateExemptionReq().then((resp) => {
					if (resp.error) {
						errorsArr.push('An error occured while uploading the request.')
						setBusy(false)
						setErrorAlerts(errorsArr)
						return
					} else {
						setReloadVaccineData(true)
						// setIsExemptionReqSubmitted(true)
						setActiveDrawer(ActiveDrawer.None)
						setBusy(false)
					}
				})
			}

			// vaccineController.uploadExemptionProof(exemptionDocUrl.signed_url, file, '').then(resp => {
			// 	if (resp) {
			// 		vaccineController.updateExemptionRequest({...reqBody, id: currentPendingExemption.id})
			// 		.then(data => {
			// 			setReloadVaccineData(true)
			// 			// setIsExemptionReqSubmitted(true)
			// 			setActiveDrawer(ActiveDrawer.None)
			// 			setBusy(false)
			// 		})
			// 		.catch(err => {
			// 			console.log(err)
			// 			setBusy(false)
			// 		})
			// 	}
			// })
		} else {
			// New exemption request POST
			// Submit a new exemption request
			const postExemptionReq = async () => await vaccineController.postExemptionRequest(reqBody)
			// if (exemptionType === 3 /* Medical */) {
			if (hasImage) {
				// has supporting documentation
				if (await haveUploadedDoc()) {
					postExemptionReq().then((resp) => {
						if (resp.error) {
							errorsArr.push('An error occured while uploading the request.')
							setBusy(false)
							setErrorAlerts(errorsArr)
							return
						} else {
							setReloadVaccineData(true)
							// setIsExemptionReqSubmitted(true)
							setActiveDrawer(ActiveDrawer.None)
							setBusy(false)
						}
					})
				}
			} else {
				// dont have supporting documentation
				postExemptionReq().then((resp) => {
					if (resp.error) {
						errorsArr.push('An error occured while uploading the request.')
						setBusy(false)
						setErrorAlerts(errorsArr)
						return
					} else {
						setReloadVaccineData(true)
						// setIsExemptionReqSubmitted(true)
						setActiveDrawer(ActiveDrawer.None)
						setBusy(false)
					}
				})
			}

			// vaccineController.uploadExemptionProof(exemptionDocUrl.signed_url, file, '').then(resp => {
			// 	if (resp) {
			// 		vaccineController.postExemptionRequest(reqBody).then(data => {
			// 		setReloadVaccineData(true)
			// 		// setIsExemptionReqSubmitted(true)
			// 		setActiveDrawer(ActiveDrawer.None)
			// 		setBusy(false)
			// 	})
			// 	.catch(err => {
			// 		console.log(err)
			// 		setBusy(false)
			// 	})
			// 	}
			// })
		}
	}

	const ImageUploadComponents = () => {
		if (isRejected && ! exemptionDocUrl.image_key) {
			return null
		}
		return (
		<>
			<ImageUpload
				// onChange={file => null}
				onChange={file => {
					imageUpload(file)
				}}
				text={appText['vaccine.exemption.support-upload.title']}
				label={'Exemption supporting documentation'}
				noClear={false}
				imageUrl={displayData.image_url}
				// imageUrl={exemptionDocUrl.signed_url}
				isLoading={isUploading}
				editButtonAriaLabel={'Edit Uploaded image.'}
				displayButtons={!isApprovedOrRejected}
			/>
			<TemplateModal
				name="imagePreview"
				show={showPreview}
				autoHeight
				// // onClose={() => onCloseCropModal()}
				bodyAlignment={TemplateModalAlignment.Center}
				size={TemplateModalSize.Large}
				header={<Text content={'Image preview'} type={TextType.Heading2} />}
				body={
					<>
						<img src={displayData.image_url} style={{ maxWidth: '100%', maxHeight: '65vh' }} alt="" />
					</>
				}
				footer={
					<>
						<ButtonBar
							buttons={[
								{
									padded: true,
									icon: 'crop',
									type: ButtonType.Ghost | ButtonType.Secondary,
									text: 'Crop Image',
									onClick: () => {
										setShowCropModal(true)
										setShowPreview(false)
									},
									customAriaLabel: 'Crop Image.'
								},
								{
									padded: true,
									icon: 'save',
									text: 'Save Image',
									onClick: () => onSaveImage(),
									customAriaLabel: 'Save Image.'
								}
							]}
						/>
						{/* <div  className="mobile-only" style={{paddingBottom: '3rem', width: '100%'}} /> */}
						{/* {mobile && <div style={{ paddingBottom: '3rem', width: '100%' }} />} */}
					</>
				}
			/>
			<ImageCrop
				showCropModal={showCropModal}
				uploadImage={displayData.image_url}
				onCloseCropModal={closeImageCropped}
				handleCropImage={handleImageCropped}
			/>
		</>
	)
}

	return (
		<>
			{/* {console.log('busy', busy, 'imageUrl', displayData.image_url)} */}
			{!busy && (
				<>
					{currentPendingExemption && (
						<Alert
							text={
								!currentPendingExemption.feedback ? (
									currentPendingExemption.statusText === 'Rejected' ? (
										'Exemption Denied'
									) : (
										`Exemption ${currentPendingExemption.statusText}`
									)
								) : (
									<span>
										{currentPendingExemption.statusText === 'Rejected'
											? 'Exemption Denied'
											: `Exemption ${currentPendingExemption.statusText}`}
										<hr />
										{currentPendingExemption.feedback}
									</span>
								)
							}
							type={
								currentPendingExemption.status === 'R'
									? 'error'
									: currentPendingExemption.status === 'A'
									? 'success'
									: 'error'
							}
							icon={
								currentPendingExemption.status === 'R'
									? 'ban'
									: currentPendingExemption.status === 'A'
									? 'check-circle'
									: 'info-circle'
							}
						/>
					)}
					<div className="btn-padded">{exemptionsBtnsData}</div>
					{errorAlerts &&
						errorAlerts.length > 0 &&
						errorAlerts.map((er, index) => (
							<div key={`er-${index}`}>
								<Alert text={er} type="error" />
							</div>
						))}
					{isApprovedOrRejected ? (
						<>
							<h5>Reason for request:</h5>
							<p style={{ fontStyle: 'italic' }}>{exemptionReason}</p>
						</>
					) : (
						<>
							{/* <Spacer /> */}
							<hr />
							{/* <Spacer size='3'/> */}
							{initialReason && (
								<>
									<Text
										// type='h5'
										content="Your Previous Request:"
									/>
									<Container
										contents={<span style={{ fontStyle: 'italic' }}>{initialReason}</span>}
										raised={false}
										padding="2"
									/>
									<Spacer size="3" />
								</>
							)}
							<Label target="exemptionReason" text="Explain Your Request" />
							<TextArea
								// readOnly={isApprovedOrRejected}
								name="exemptionReason"
								placeholderText={exemptionReason}
								// placeholderText={exemptionReason || 'Explain your request'}
								properties={{
									'aria-label': 'Explain your request',
									value: exemptionReason,
									id: 'exemptionReason'
								}}
								rows={5}
								// value={exemptionReason}
								onChange={(e: any) => setExemptionReason(e.target.value)}
							/>
						</>
					)}
					{exemptionType && (
						<>
							<hr />
							{console.log('file', file)}
							{console.log('fileContentType', fileContentType)}
							{console.log('fileName', fileName)}
							<Spacer/>
							<Text
								type='h5'
								content='Supporting Documentation'
								align='center'
							/>
							<Spacer size='2'/>
							{person.type === 'EMPLOYEE' && exemptionType === 3 && (
								<Text align="left" content={appText[COVID_TEXTS['vaccine.exemption.medical-support-upload.title']] || medicalExemptionText} italic padded />
							)}
							{['image/jpeg', ''].includes(fileContentType) /* && !isApprovedOrRejected  */&& <ImageUploadComponents />}
							{/* {currentPendingExemption && currentPendingExemption.imageUrl && ( */}
							{fileName && fileContentType && displayData && (
								<div className="text-center">
									<Button
										type={ButtonType.Primary}
										text={`Download supporting docs`}
										icon={'cloud-download'}
										// url={currentPendingExemption?.imageUrl}
										onClick={() => window.open(currentPendingExemption?.imageUrl)}
									/>&nbsp;
									{/* <Spacer/> */}
									{console.log(fileContentType)}
									{fileContentType !== 'image/jpeg' &&
									<Button
										type={ButtonType.Primary}
										text={`Upload new file`}
										icon={'cloud-upload'}
										// url={currentPendingExemption.imageUrl}
										onClick={() => {
											setFileName('')
											setFileContentType('')
											setDisplayData({})
										}}
									/>}
								</div>
							)}
							{/* {fileContentType === 'image/jpeg'
								? <ImageUploadComponents />
								: <FileUpload
										acceptedMimeTypes={['image/jpeg', 'application/pdf']}
										clearFilesAfterConfirm
										initialValue={[{name: fileName, type: fileContentType}]}
										isLoading={false}
										maxSize={200000000}
										multiple={false}
										onConfirm={file => console.log('CONFIRM')}
										text="Drop your file(s) here"
									/>
							} */}
							<div style={{ maxWidth: 750, margin: '20px auto 0px' }}>
								<Spacer />
								{!isApprovedOrRejected && (
									<>
										<hr />
										{!['Current Employee', 'Current Student'].includes(person.personType) && (
											<>
												<Spacer />
												<Checkbox
													label={appText[COVID_TEXTS['vaccine.exemption.extcontractor']]}
													name="Exemption Agreement"
													onClick={() => setExtContractorTicked(extContractorTicked => !extContractorTicked)}
													checked={extContractorTicked}
													properties={{
														'aria-label': 'Exemption Agreement'
													}}
												/>
												<hr />
											</>
										)}
										{exemptionType === 2 && (
											<>
												<Spacer />
												<Text align="left" content={appText[COVID_TEXTS["vaccine.exemption.religious.disclaimer"]] || religiousExemptText} italic padded />
												<Checkbox
													label={appText[COVID_TEXTS['vaccine.exemption.religious.agreement']] || religiousExemptLabel}
													name="Religious Agreement"
													onClick={() => setReligiousTicked(religiousTicked => !religiousTicked)}
													checked={religiousTicked}
													properties={{
														'aria-label': 'Religious Agreement'
													}}
												/>
												<Spacer />
												<hr />
											</>
										)}
										<Spacer />
										<Checkbox
											label={appText[COVID_TEXTS['vaccine.exemption.agreement']]}
											name="Exemption Agreement"
											onClick={() => setAgreementTicked(agreementTicked => !agreementTicked)}
											checked={agreementTicked}
											properties={{
												'aria-label': 'Exemption Agreement'
											}}
										/>
										<hr />
										{/* <Text
									align="left"
									content={appText[COVID_TEXTS['vaccine.exemption.agreement']]}
									// content={'I agree that in the event of an outbreak of COVID-19 (SARS-CoV-2) I will either leave campus or receive an immunization and follow the recommendations of the local board of public health. For those with approved medical or religious exemption from the vaccine requirement, MIT encourages you to practice COVID-related precautions, including social distancing and masking in order to protect yourself and the greater MIT community.'}
								/> */}
										<Spacer size="3" />
										<Button
											type={ButtonType.Primary}
											text={currentPendingExemption ? 'Update' : 'Submit'}
											onClick={submitExemption}
											state={
												agreementTicked
													? exemptionType === 2
														? religiousTicked
															? ButtonState.Enabled
															: ButtonState.Disabled
														: ButtonState.Enabled
													: ButtonState.Disabled
											}
										/>
									</>
								)}
							</div>
						</>
					)}
				</>
			)}
			{busy && <SkeletonLoader type="list" />}
		</>
		// <Container
		// 		color='white'
		// 		padding={5}
		// 		alignment='center'
		// 		contents={
		// 			<>
		// 				<div className='mb-3'><Text type='h3' content={'Exemption type'} /></div>
		// 				<ButtonBar
		// 					type={ButtonBarType.Padded}
		// 					buttons={exemptionsBtnsData}
		// 				/>
		// 			{exemptionType !== '' && (
		// 				<div style={{maxWidth: 750, margin: '20px auto'}}>
		// 				<TextArea
		// 					name=""
		// 					// onActionClick={function actionHandler() {var channel = _storybook_addons__WEBPACK_IMPORTED_MODULE_2__[/* addons */ "c"].getChannel();var id = uuid_browser_v4__WEBPACK_IMPORTED_MODULE_1___default()();var minDepth = 5; // anything less is really just storybook internalsfor (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {args[_key] = arguments[_key];}var normalizedArgs = args.length > 1 ? args : args[0];var actionDisplayToEmit = {id: id,count: 0,data: {name: name,args: normalizedArgs},options: Object.assign({}, actionOptions, {depth: minDepth + (actionOptions.depth || 3),allowFunction: actionOptions.allowFunction || false})};channel.emit(_constants__WEBPACK_IMPORTED_MODULE_3__[/* EVENT_ID */ "a"], actionDisplayToEmit);}}
		// 					placeholderText="Explain your request"
		// 					properties={{
		// 						'aria-label': 'Explain your request'
		// 					}}
		// 					rows={5}
		// 					value={exemptionReason}
		// 					onChange={(e: any) => setExemptionReason(e.target.value)}
		// 				/>
		// 				<Spacer size='3' />
		// 				<Text
		// 					align='left'
		// 					content={'I agree that Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'}
		// 				/>
		// 				<Spacer size='3' />
		// 				<Button
		// 					type={ButtonType.Primary}
		// 					text='Submit'
		// 				/>
		// 				</div>
		// 			)}
		// 			</>
		// 		}
		// />
	)
}

export default ExemptionView
