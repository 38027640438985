import { AuthProvider } from '../AuthProvider'
import BaseController from './BaseController'

// Define Interfaces here...


export interface FluShotProof{
	// id?: string
	name: string
	provider: string
	externalLocation: string //should this be number?
	dateReceived: string
	imageKey?: string
	imageUrl?: string
}

// FluShotProof{
// 	id: string;
// 	imageKey: string;
// 	dateReceived: string;
// 	name: string;
// 	provider: string;
// 	imageUrl: string;
// 	externalLocation: string;
// }

class FluShotController extends BaseController {
	readonly url_flu_shot_upload_url: string
	readonly url_flu_shot_proof_upload: string


	constructor() {
		super()

		this.url_flu_shot_upload_url = `${this.apiHost}/${this.apiPathVaccine}/flushot/proof/upload/url`
		this.url_flu_shot_proof_upload = `${this.apiHost}/${this.apiPathVaccine}/flushot/proof/upload`
	}

	async getUploadUrl(image_key?: string): Promise<any> {
		let url: string;
		image_key ? url = `${this.url_flu_shot_upload_url}?image_key=${image_key}` : url = this.url_flu_shot_upload_url

		let response = await this.useFetch('GET', 'getUploadUrl', url)

		if (response.ok) {
			return { data: await response.json() }
		} else {
			return {
				error: true
			}
		}
	}

	async uploadFluShotProof(url: string | any, file: any, fileName?: any): Promise<any> {
		let response = await this.useFetch('PUT', 'put/upload', url, file, true)

		if (response.ok) {
			return true
		} else {
			let text = await response.text()
			return Promise.reject(text)
		}
	}

	postFluShotProofUpload = async (body: any): Promise<any> => {
		let token = await new AuthProvider().getToken()

		const headerInit: any = {
			Authorization: 'Bearer ' + token.token,
			'Content-Type': 'application/json'
		}

		let postData = body

		const options: RequestInit = {
			method: 'POST',
			headers: new Headers(headerInit),
			body: JSON.stringify(postData),
			mode: 'cors'
		}

		let response = await fetch(this.url_flu_shot_proof_upload, options)

		if (response.status >= 200 && response.status < 300) {
			return { data: await response.json() }
		} else {
			return {
				error: true
			}
		}
	}

	checkStatusCode(status: number): boolean {
		return status >= 200 && status < 300
	}
}

const vaccineController = new FluShotController()
export default vaccineController
