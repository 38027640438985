import * as React from 'react'
import {
	Icon
} from '@mit/hui'

export interface StatusTextBlockProps {
	icon: string
	iconLight?: boolean
	heading: string
	text: string
	iconColor: string
}

const StatusTextBlock = ({icon, iconLight, heading, text, iconColor}: StatusTextBlockProps) => {
	return (
		<div className="row justify-content-center">
			<div className="col-md-8">
				<div className={'p-4'}>
					<div className='text-center mb-3' style={{fontSize: '3em'}}>
						<span style={{marginLeft: '-0.5em'}}>
							<Icon type={iconLight? "light": "regular"} icon={icon} color={iconColor}/>
						</span>
					</div>
					<h3 className="text-center mb-4">{heading}</h3>
					<p className="text-center">{text}</p>
				</div>
			</div>
		</div>
	)
}

export default StatusTextBlock
