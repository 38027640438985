import React, { Fragment, useEffect, useState } from 'react'

import { Switch, Route } from 'react-router-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import {
	AtlasPage,
	BasicLayout,
	Drawer,
	DrawerLayout,
	DrawerSize,
	DrawerType,
	Loader,
	NavItemProps,
	PagingContainer,
	ReleaseNoteItemProps,
	ReleaseNotes,
	TemplateModal,
	TemplateModalAlignment,
	Text,
	TextType,
	Error,
	ErrorContext,
	ErrorType,
	TemplateModalSize,
	// Icon,
	// NavBar,
	// NavBarTypes,
	// NavItemState,
	MobilePromo
} from '@mit/hui'

import { LoggedInUser, MainNavBar } from './components/nav'
import { getEnv } from './common'

import { useStoreActions, useStoreState } from './store/hooks'
import Vaccine from './components/vaccine/Vaccine'
import dataController from './api/controller/DataController'
import vaccineController from './api/controller/VaccineController'
import profileController from './api/controller/ProfileController'
import { /* RequirementIds,  */StatusHelper } from './types/CovidStatusModel'
import { ActiveDrawer } from './store/models/global'
import VaccineWaiverLanding from './components/vaccine_waiver/VaccineWaiverLanding'
import Appointment from './components/appointment/Appointment'
import { StoreProvider } from 'easy-peasy'
import { store } from './store'
import Questions from './components/question/Questions'
import appConfig from './app-config'
import PreScreeningQuestions from './components/question/PreScreeningQuestions'
import personController from './api/controller/PersonController'
// import VaccineProofList from './components/vaccine_proof/VaccineProofList'
// import VaccineProof from './components/vaccine_proof/VaccineProof'
import VaccineProofEdit from './components/vaccine_proof/VaccineProofEdit'
import VaccineType from './components/vaccine_proof/VaccineType'
// import { COVID_TEXTS } from './common/Texts'
import BrowserDetector, { detectMobile } from './common/BrowserDetector'
import FlushotProofEdit from './components/flushot/FlushotProofEdit'
import ExemptionView from './components/covid/ExemptionView'
import FullHistory from './components/vaccine/FullHistory'
// import ExemptionMoreInfoView from './components/covid/ExemptionMoreInfoView'
interface PreScreenStepper {
	selected: boolean
	index: number
}

const App: React.FC = () => {
	const { isLoading, tabState, stepperItemCount, stepperSelectedIndex, activeDrawer, appText, vaccineReceived, preScreeningStepperSelectedIndex } = useStoreState(
		store => store.globalModel
	)
	const {
		setIsLoading,
		setAppText,
		setFeatures,
		setStatusModel,
		setActiveDrawer,
		setTabState,
		setVaccineStatus,
		setRelationships,
		setReceivedTypes,
		setInterests,
		setAvailabilities,
		setLocations,
		setJobTypes,
		setComorbidities,
		setVaccineCompanies,
		setReasons,
		// setReloadVaccineData,
		setMobile,
		setPerson,
		setVaccineResultsList,
		setFluShotResultsList,
		setVaccineReceived,
		setReqExemptBtnClicked,
		setCurrentPendingExemption
	} = useStoreActions(actions => actions.globalModel)
	const [utilNavItems, setUtilNavItems] = useState<NavItemProps[]>([
		{ icon: 'bell', text: "What's new!", iconOnly: false, onClick: () => setShowWhatsNew(true) },
		{ icon: 'question-circle', text: 'Help', iconOnly: false, properties: { href: 'mailto:covidapps-help@mit.edu' } }
	])

	const [showWhatsNew, setShowWhatsNew] = useState<boolean>(false)
	const [error, setError] = useState<boolean>(false)
	const [preScreeningStepper, setPreScreeningStepper] = useState<PreScreenStepper[]>([
		{ selected: true, index: 1 },
		{ selected: false, index: 2 }
	])

	const reset = () => {
		vaccineController.fetchReset().then(() => {
			window.location.reload()
		})
	}
	// let navigator = window.navigator
	// const abortController = new AbortController()

	useEffect(() => {
		setIsLoading(true)
		setMobile(detectMobile())

		var textPromise = dataController.fetchArray('texts')
		var featurePromise = profileController.fetchFeatures()
		var statusPromise = dataController.fetchPassStatus()
		var vaccinePromise = dataController.fetchVaccineStatus()
		var relationshipsPromise = personController.fetchRelationships()
		var receivedPromise = vaccineController.fetchReceivedType()
		var interestPromise = vaccineController.fetchInterestType()
		var locationPromise = vaccineController.fetchLocationType()
		var availabilityPromise = vaccineController.fetchAvailabilityType()
		var jobsPromise = personController.fetchJobType()
		var comorbiditiesPromise = vaccineController.fetchComorbidities()
		var vaccineCompanies = vaccineController.fetchCompanies()
		var proofReason = vaccineController.fetchProofReason()
		var personPromise = vaccineController.fetchPerson()
		var vaccineResultsListPromise = dataController.fetchVaccines()
		var fluShotResultsListPromise = dataController.fetchFluShots()

		Promise.all([
			textPromise,
			statusPromise,
			featurePromise,
			vaccinePromise,
			relationshipsPromise,
			receivedPromise,
			interestPromise,
			locationPromise,
			availabilityPromise,
			jobsPromise,
			comorbiditiesPromise,
			vaccineCompanies,
			proofReason,
			personPromise,
			vaccineResultsListPromise,
			fluShotResultsListPromise
		])
			.then(
				([
					textResponse,
					statusResponse,
					featureResponse,
					vaccineResponse,
					relationshipsResonse,
					receivedResponse,
					interestResponse,
					locationResponse,
					availabilityResponse,
					jobResponse,
					comorbiditiesResponse,
					vaccineCompaniesResponse,
					proofReasonResponse,
					personResponse,
					vaccineResultsListResponse,
					fluShotResultsListResponse
				]) => {
					setAppText(textResponse)
					setFeatures(featureResponse)

					let permissions = featureResponse.reduce((obj: any, curr: any) => {
						return { ...obj, [curr.id]: curr.value }
					}, {})

					if (!permissions['vaccine.enabled']) setTabState(1)

					setStatusModel(statusResponse)
					setVaccineStatus(vaccineResponse.status)

					setRelationships(relationshipsResonse.items)
					setReceivedTypes(receivedResponse.data)
					setInterests(interestResponse.data)
					setAvailabilities(availabilityResponse.data)
					setLocations(locationResponse.data)
					setJobTypes(jobResponse.data)
					setComorbidities(comorbiditiesResponse.data)
					setVaccineCompanies(vaccineCompaniesResponse.data)
					setReasons(proofReasonResponse.data)
					setPerson(personResponse.data)
					setVaccineResultsList(vaccineResultsListResponse.items)
					setFluShotResultsList(fluShotResultsListResponse.items)

					setIsLoading(false)
				}
			)
			.catch(() => {
				setFeatures([])
				setRelationships([])
				setAppText([])
				setIsLoading(false)
				setError(true)
			})

		if (window.location.pathname.includes('acknowledgement')) {
			setTabState(0)
			setActiveDrawer(ActiveDrawer.VaccineAcknowledgement)
		} else if (window.location.pathname.includes('questions')) {
			setTabState(0)
			setActiveDrawer(ActiveDrawer.VaccineQuestions)
		}

		if (appConfig.stage !== 'master') {
			utilNavItems.unshift({ icon: 'power-off', text: 'Reset Vaccine Status', iconOnly: false, onClick: () => reset() })

			setUtilNavItems(utilNavItems)
		}

		//eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (tabState === 0) {
			document.body.classList.add('vaccine')
		}

		return function cleanup() {
			document.body.classList.remove('vaccine')
		}
	}, [tabState])

	if (isLoading)
		return <Loader contactEmail="covidapps-help@mit.edu" hasAccess={true} theme="medical" name="Covid Vaccine" />

	const releaseNotes: ReleaseNoteItemProps[] = [
		{
			date: '1/18/2021',
			text: 'First release of COVD Vaccine...',
			items: []
		}
	]

	const vaccineAcknowledgementClose = () => {
		setPreScreeningStepper([
			{ selected: true, index: 1 },
			{ selected: false, index: 2 }
		])
		setActiveDrawer(ActiveDrawer.None)
	}

	const vaccineProofClose = () => {
		// if (activeDrawer === ActiveDrawer.VaccineProofEdit) setActiveDrawer(ActiveDrawer.VaccineProofList)
		// else setActiveDrawer(ActiveDrawer.None)
		setActiveDrawer(ActiveDrawer.None)
	}

	const vaccineManufacturerClose = () => {
		// if (activeDrawer === ActiveDrawer.VaccineShotNumber) setActiveDrawer(ActiveDrawer.VaccineManufacturer)
		// else
		setActiveDrawer(ActiveDrawer.None)
	}

	function capitalizeFirstLetter(string: string) {
		return string.charAt(0).toUpperCase() + string.slice(1)
	}

	// let medicalTabItems: NavItemProps[] = [] // remove tabs ?
	// let medicalRequirement = StatusHelper.getRequirement(statusModel, RequirementIds.medical)

	// if (features['vaccine.enabled']) medicalTabItems.push({ // remove tabs ? remove enabled check ?
	// 	icon: 'shield-check',
	// 	iconStyle: 'light',
	// 	text: 'Vaccination',
	// 	iconOnly: false,
	// 	onClick: () => {
	// 		setTabState(0)
	// 	},
	// 	state: tabState === 0 ? NavItemState.Active : NavItemState.None
	// },)

	// medicalTabItems.push({ // remove tabs ?
	// 	icon: 'syringe',
	// 	iconStyle: 'light',
	// 	text: 'Flu shot',
	// 	iconOnly: false,
	// 	onClick: () => {
	// 		setTabState(1)
	// 	},
	// 	state: tabState === 1 ? NavItemState.Active : NavItemState.None // remove tabs ?
	// })
	// if (!!medicalRequirement) medicalTabItems.push({
	// 	icon: 'syringe',
	// 	iconStyle: 'light',
	// 	text: 'Flu shot',
	// 	iconOnly: false,
	// 	onClick: () => {
	// 		setTabState(1)
	// 	},
	// 	state: tabState === 1 ? NavItemState.Active : NavItemState.None
	// })

	// if (!features['vaccine.enabled'] && !medicalRequirement) // remove enabled check ?
	// 	return (
	// 		<div className="medical">
	// 			<Error
	// 				context={ErrorContext.Page}
	// 				type={ErrorType.AccessDenied}
	// 				message={'You do not have access'}
	// 			/>
	// 		</div>
	// 	)

	if (error)
		return (
			<div className="medical">
				<Error
					context={ErrorContext.Page}
					type={ErrorType.AccessDenied}
					message={'This application is not available at this time.'}
				/>
			</div>
		)

	return (
		<Router>
			<BrowserDetector />
			<AtlasPage
				contentAriaLabel={`Covid Vaccine Web Application`}
				gaId={'G-0SHSBVRYGD'}
				theme="medical-dark"
				utilityNavigation={utilNavItems}
				environment={getEnv()}
				name={'Vaccine'}
				navigation={<MainNavBar />}
				profile={<LoggedInUser />}
				content={
					<>
						<Switch>
							<Route path={'/:page?'}>
								<BasicLayout
									contentTitle=""
									startState={true}
									/* navigation={<NavBar itemAlignment={1} type={NavBarTypes.IconText} tabs={medicalTabItems} />} */ content={
										<Vaccine />
									}
								/>
								<MobilePromo
									title="Get it on mobile"
									iosLink="itms-apps://apps.apple.com/us/app/mit-atlas/id1498222646"
									androidLink="https://play.google.com/store/apps/details?id=edu.mit.atlas"
								/>
							</Route>
						</Switch>
					</>
				}
			/>

			<Drawer
				contentAriaLabel="What's New Release Notes"
				show={showWhatsNew}
				onClose={() => setShowWhatsNew(false)}
				type={DrawerType.Right}
				size={DrawerSize.Medium}
				layout={DrawerLayout.Hero}
				themedColor={'medical-dark'}
				header={<Text content="What's New!" type={TextType.Heading4} icon="bell" />}
				contents={
					<Fragment>
						<ReleaseNotes items={releaseNotes} />
					</Fragment>
				}
				footer={[]}
			/>

			<Drawer
				contentAriaLabel={`Consent for Vaccine`}
				layout={DrawerLayout.Hero}
				size={DrawerSize.Medium}
				header={<Text type={TextType.Heading3} content={'Consent for Vaccine'} />}
				headerContent={
					<React.Fragment>
						{StatusHelper.getStepperItems(stepperItemCount, stepperSelectedIndex) && (
							<PagingContainer items={StatusHelper.getStepperItems(stepperItemCount, stepperSelectedIndex)} />
						)}
					</React.Fragment>
				}
				type={DrawerType.Right}
				show={activeDrawer === ActiveDrawer.VaccineAcknowledgement}
				contents={
					<Router>
						<VaccineWaiverLanding />
					</Router>
				}
				footer={[]}
				onClose={() => vaccineAcknowledgementClose()}
			/>

			<Drawer
				contentAriaLabel={`Vaccine Appointment`}
				layout={DrawerLayout.Hero}
				size={DrawerSize.Medium}
				themedColor="medical"
				header={<Text type={TextType.Heading3} content={'Vaccine Appointment'} />}
				type={DrawerType.Right}
				show={activeDrawer === ActiveDrawer.VaccineAppointment}
				contents={
					<Router>
						<StoreProvider store={store}>
							<Appointment />
						</StoreProvider>
					</Router>
				}
				footer={[]}
				onClose={() => vaccineAcknowledgementClose()}
			/>

			<Drawer
				contentAriaLabel={`Vaccine Questions`}
				layout={DrawerLayout.Hero}
				size={DrawerSize.Medium}
				themedColor={'medical'}
				header={<Text type={TextType.Heading3} content={'Vaccine Questions'} />}
				type={DrawerType.Right}
				show={activeDrawer === ActiveDrawer.VaccineQuestions}
				contents={
					<Router>
						<StoreProvider store={store}>
							<Questions />
						</StoreProvider>
					</Router>
				}
				footer={[]}
				onClose={() => vaccineAcknowledgementClose()}
			/>

			<Drawer
				contentAriaLabel={vaccineReceived === 'booster'? 'Which booster shot are you getting?':`${capitalizeFirstLetter(vaccineReceived)} vaccinated, Which vaccination are you getting?`}
				layout={DrawerLayout.Hero}
				size={DrawerSize.Small}
				// header={<Text type={TextType.Heading3} content={`${capitalizeFirstLetter(vaccineReceived)} vaccinated`} />}
				header={<Text type={TextType.Heading3} content={vaccineReceived === 'booster'? 'Which booster shot are you getting?':`${capitalizeFirstLetter(vaccineReceived)} vaccinated`} />}
				type={DrawerType.Bottom}
				show={activeDrawer === ActiveDrawer.VaccineManufacturer || activeDrawer === ActiveDrawer.VaccineShotNumber}
				contents={
					<Router>
						<StoreProvider store={store}>
							<VaccineType />
						</StoreProvider>
					</Router>
				}
				footer={[]}
				onClose={() => {
					vaccineManufacturerClose()
					setVaccineReceived('')
				}}
			/>
			{/* Will change VaccineProofList drawer is being deprecated */}
			<Drawer
				// contentAriaLabel={`${appText[COVID_TEXTS['vaccine.pov.detail.header']] ?? `Proof of Vaccination`}, Upload your proof of Vaccination.`}
				contentAriaLabel={`Upload your proof of Vaccination.`}
				layout={DrawerLayout.Hero}
				size={DrawerSize.Medium}
				themedColor={'medical'}
				header={<Text type={TextType.Heading3} content={'Proof of Vaccination'} />}
				// header={<Text type={TextType.Heading3} content={appText[COVID_TEXTS['vaccine.pov.detail.header']] ?? 'Proof of Vaccination'} />}
				type={DrawerType.Right}
				show={activeDrawer === ActiveDrawer.VaccineProofEdit}
				contents={
					<Router>
						<StoreProvider store={store}>
							<VaccineProofEdit />
						</StoreProvider>
					</Router>
				}
				footer={[]}
				onClose={() => {
					vaccineProofClose()
					setVaccineReceived('')
				}}
			/>
			{/* <Drawer
				// contentAriaLabel={`${appText[COVID_TEXTS['vaccine.pov.detail.header']] ?? `Proof of Vaccination`}, Upload your proof of Vaccination.`}
				contentAriaLabel={`Upload your proof of Covid-19 Booster Shot`}
				layout={DrawerLayout.Hero}
				size={DrawerSize.Medium}
				themedColor={'medical'}
				header={<Text type={TextType.Heading3} content={'Proof of Covid-19 Booster Shot'} />}
				// header={<Text type={TextType.Heading3} content={appText[COVID_TEXTS['vaccine.pov.detail.header']] ?? 'Proof of Vaccination'} />}
				type={DrawerType.Right}
				show={activeDrawer === ActiveDrawer.VaccineBoosterEdit}
				contents={
					<Router>
						<StoreProvider store={store}>
							Booster Upload Form here...
						</StoreProvider>
					</Router>
				}
				footer={[]}
				onClose={() => {
					setActiveDrawer(ActiveDrawer.None)
					// setVaccineReceived('')
				}}
			/> */}
			<Drawer
				// New Text API?
				contentAriaLabel={`Flu shot `}
				layout={DrawerLayout.Hero}
				size={DrawerSize.Medium}
				themedColor={'medical'}
				// New Text API?
				header={<Text type={TextType.Heading3} content={'Flu shot'} />}
				type={DrawerType.Right}
				show={activeDrawer === ActiveDrawer.FlushotProofEdit}
				contents={
					<Router>
						<StoreProvider store={store}>
							{/* Temp - New flushot upload component here */}
							<FlushotProofEdit />
						</StoreProvider>
					</Router>
				}
				footer={[]}
				onClose={() => setActiveDrawer(ActiveDrawer.None)}
			/>

			<Drawer
				contentAriaLabel={`Vaccine Eligibility`}
				layout={DrawerLayout.Hero}
				size={DrawerSize.Medium}
				header={
					<Text
						type={TextType.Heading3}
						content={appText['covid19.vaccine.eligform.title'] ?? 'Vaccine Eligibility Form'}
					/>
				}
				headerContent={
					<React.Fragment>
						<PagingContainer
							items={
								preScreeningStepperSelectedIndex === 2
									? [
											{ selected: false, index: 1 },
											{ selected: true, index: 2 }
									  ]
									: preScreeningStepper
							}
						/>
					</React.Fragment>
				}
				type={DrawerType.Right}
				show={activeDrawer === ActiveDrawer.VaccinePreScreeningQuestions}
				contents={
					<Router>
						<StoreProvider store={store}>
							<PreScreeningQuestions
								stepIndex={
									preScreeningStepperSelectedIndex === 2 ? 2 : preScreeningStepper.filter(itm => itm.selected)[0].index
								}
								onBack={() => {
									setPreScreeningStepper([
										{ selected: true, index: 1 },
										{ selected: false, index: 2 }
									])
								}}
								onSubmit={data => {
									if (data && data.has_family_members) {
										setPreScreeningStepper([
											{ selected: false, index: 1 },
											{ selected: true, index: 2 }
										])
									} else {
										setPreScreeningStepper([
											{ selected: true, index: 1 },
											{ selected: false, index: 2 }
										])
										setActiveDrawer(ActiveDrawer.FamilyMemberAdded)
									}
								}}
							/>
						</StoreProvider>
					</Router>
				}
				footer={[]}
				onClose={() => vaccineAcknowledgementClose()}
			/>
			<Drawer
				contentAriaLabel={`Request Exemption `}
				layout={DrawerLayout.Hero}
				size={DrawerSize.Medium}
				themedColor={'medical'}
				header={<Text type={TextType.Heading3} content={"Exemption Request"} />}
				type={DrawerType.Right}
				show={activeDrawer === ActiveDrawer.ReqExemption}
				contents={
					<StoreProvider store={store}>
						<ExemptionView />
					</StoreProvider>
				}
				footer={[]}
				onClose={() => {
					setActiveDrawer(ActiveDrawer.None)
					setReqExemptBtnClicked(false)
					setCurrentPendingExemption(undefined)
				}}
			/>
			{/* Moved Full History Drawer to Vaccine Component */}
			<TemplateModal
				body={
					<div className="text-center">
						<p>
							Thank you for submitting your proof of vaccination. Please remember to upload your updated proof of
							vaccination after your second dose.
						</p>
					</div>
				}
				bodyAlignment={TemplateModalAlignment.Left}
				show={activeDrawer === ActiveDrawer.PartialUpload}
				onClose={() => setActiveDrawer(ActiveDrawer.None)}
				header={<Text content={'Thank you'} type={TextType.Heading3} />}
				size={TemplateModalSize.Default}
				imageUrl=""
				footer={<div></div>}
				name="partiallyVaccinated"
			/>
			<TemplateModal
				body={
					<div className="text-center">
						<p>
							{appText['covid19.vaccine.eligform.popup.message'] ??
								'Thank you for submitting the vaccine eligibility form'}
						</p>
					</div>
				}
				bodyAlignment={TemplateModalAlignment.Left}
				show={activeDrawer === ActiveDrawer.FamilyMemberAdded}
				onClose={() => setActiveDrawer(ActiveDrawer.None)}
				header={
					<Text content={appText['covid19.vaccine.eligform.popup.title'] ?? 'Thank you'} type={TextType.Heading3} />
				}
				size={TemplateModalSize.Default}
				imageUrl=""
				footer={<div></div>}
				name="familyMemberAddedPopup"
			/>
			<TemplateModal
				body={
					<div className="text-center">
						<div className="bigicon">
							<img src="MIT.svg" alt='' className="mb-4" />
						</div>
						<p>
							{appText['covid19.vaccine.question.success'] ??
								'Thank you for completing your questionnaire, please go to MIT Medical at your scheduled time and inform the clinician of your allergies'}
						</p>
					</div>
				}
				bodyAlignment={TemplateModalAlignment.Left}
				show={activeDrawer === ActiveDrawer.SuccessPopup}
				onClose={() => setActiveDrawer(ActiveDrawer.None)}
				header={<Text content={'Thank you'} type={TextType.Heading3} />}
				size={TemplateModalSize.Default}
				imageUrl=""
				footer={<div></div>}
				name="successPopup"
			/>
		</Router>
	)
}

export default App
