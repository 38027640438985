import * as React from 'react'
import { useState } from 'react'
// import moment, { now } from 'moment'
// import { withRouter } from 'react-router'
// import PersonTests from './PersonTests'
import { useStoreActions, useStoreState } from '../../store/hooks'
import dataController /* , { VaccineStatusResponse } */ from '../../api/controller/DataController'
import moment /* , { now } */ from 'moment'
import { ActionList, Container, Button, ButtonType, Spacer } from '@mit/hui'
import { ActiveDrawer } from '../../store/models/global'
import { COVID_TEXTS } from '../../common/Texts'

type CompactHistoryProps = {
	selectedVaccineProof: (proof_guid: string | any, shot_number: number) => void
	setShowFullOrPartialModal: React.Dispatch<React.SetStateAction<boolean>>
	uploadBtnFunction: 0 | 1 | 2 | 3
}

const CompactHistory = ({
	selectedVaccineProof,
	setShowFullOrPartialModal,
	uploadBtnFunction
}: CompactHistoryProps) => {
	const { appText,  vaccineStatus, vaccineResultsList, fluShotResultsList, person } = useStoreState(
		store => store.globalModel
	)
	const { setActiveDrawer } = useStoreActions(store => store.globalModel)
	const [isDownloading, setDownloading] = useState(false)
	const isStudent = person?.personType === 'Current Student'

	const downloadFile = (data: any, filename: any, mime: any) => {
		// It is necessary to create a new blob object with mime-type explicitly set
		// otherwise only Chrome works like it should
		const blob = new Blob([data], { type: mime || 'application/octet-stream' })
		//@ts-ignore
		if (typeof window.navigator.msSaveBlob !== 'undefined') {
			// IE doesn't allow using a blob object directly as link href.
			// Workaround for "HTML7007: One or more blob URLs were
			// revoked by closing the blob for which they were created.
			// These URLs will no longer resolve as the data backing
			// the URL has been freed."
			//@ts-ignore
			window.navigator.msSaveBlob(blob, filename)
			return
		}
		// Other browsers
		// Create a link pointing to the ObjectURL containing the blob
		const blobURL = window.URL.createObjectURL(blob)
		const tempLink = document.createElement('a')
		tempLink.style.display = 'none'
		tempLink.href = blobURL
		tempLink.setAttribute('download', filename)
		// Safari thinks _blank anchor are pop ups. We only want to set _blank
		// target if the browser does not support the HTML5 download attribute.
		// This allows you to download files in desktop safari if pop up blocking
		// is enabled.
		if (typeof tempLink.download === 'undefined') {
			tempLink.setAttribute('target', '_blank')
		}
		document.body.appendChild(tempLink)
		tempLink.click()
		document.body.removeChild(tempLink)
		setTimeout(() => {
			// For Firefox it is necessary to delay revoking the ObjectURL
			window.URL.revokeObjectURL(blobURL)
		}, 100)
	}

	const downloadClicked = (proof_guid: string, flushot: boolean, vaccine?: boolean) => {
		setDownloading(true)
		dataController.fetchMedicalResultPDF(flushot, false, true, proof_guid).then(resp => {
			setDownloading(false)
			downloadFile(resp.value, `vaccine_proof_${moment().format('YYYY-MM-DD')}.pdf`, 'application/pdf')
		})
	}
	// const downloadClicked = (proof_guid: string, flushot: boolean) => {
	// 	setDownloading(true)
	// 	dataController.fetchMedicalResultPDF(flushot, false, true, proof_guid).then(resp => {
	// 		setDownloading(false)
	// 		downloadFile(resp.value, `vaccine_proof_${moment().format('YYYY-MM-DD')}.pdf`, 'application/pdf')
	// 	})
	// }

	const formatDate = (date: string | undefined) => {
		if (date) {
			return moment(date)
				.utc()
				.format('LL')
			// return moment(date).format('MMM DD, YYYY')
		}
		return ''
	}

	const pendingProofs = vaccineResultsList?.filter((item, idx) => {
		return item.result === 'VACCINE_PROOF_PENDING'
	})

	const exemptionItems = vaccineResultsList?.filter(item => ['EXEMPT_MORE_INFO', 'EXEMPT_REJECTED' ,'EXEMPT_PENDING' ,'EXEMPT_APPROVED'].includes(item.result))
	const hasExemptions = exemptionItems && exemptionItems.length > 0

	const compactHistoryListData: any[] = []

	let lastVaccineItem: any = undefined
	if (vaccineResultsList) {
		lastVaccineItem = vaccineResultsList[0]
	}

	const fluShotProofs = fluShotResultsList?.filter((item, idx) => {
		return item.result === 'FLU_SHOT'
	})

	const fluShotMissing = fluShotResultsList?.filter((item, idx) => {
		return item.result === 'FLU_SHOT_MISSING'
	})

	// boosterPending
	// boosterRejected
	// pendingProofs
	// exemptRejected
	// exemptPending
	// exemptMoreInfo
	// partialProofs
	// proofRejected
	// vaccineMissing
	// boosterMissing

	// Covid
	if (lastVaccineItem && lastVaccineItem.result === 'VACCINE_BOOSTER_PENDING') {
		compactHistoryListData.push({
			icon: 'clock',
			onClick: () => selectedVaccineProof(lastVaccineItem.test_guid, 3), // should take to booster upload page to edit
			secondaryText: appText[COVID_TEXTS['covid19.medical.vaccine.secondary_text.pending']] || 'Pending',
			// text: `COVID-19 Booster`,
			text: appText[COVID_TEXTS['covid19.medical.vaccine.booster_title']] || 'COVID-19 Booster',
			className: 'vaccine-list-item grey',
			singleLine: true,
			actions: [
				{
					icon: 'chevron-right',
					onClick: () => selectedVaccineProof(lastVaccineItem.test_guid, 3), // should take to booster upload page to edit
					state: 0,
					text: '',
					type: 32
				}
			]
		})
	} else if (lastVaccineItem && lastVaccineItem.result === 'VACCINE_BOOSTER_REJECTED') {
		// const lastItem = vaccineRejected[0]
		compactHistoryListData.push({
			icon: 'ban',
			// onClick: () => selectedVaccineProof(lastVaccineItem.test_guid, uploadBtnFunction),
			onClick: () => selectedVaccineProof(lastVaccineItem.test_guid, 3), // should take to booster upload page to edit
			secondaryText: appText[COVID_TEXTS['covid19.medical.vaccine.secondary_text.denied']] || `Denied`,
			text: appText[COVID_TEXTS['covid19.medical.vaccine.booster_title']] || `COVID-19 Booster`,
			// text: `COVID-19 Booster`,
			className: 'vaccine-list-item red',
			singleLine: true,
			actions: [
				{
					icon: 'chevron-right',
					// onClick: () => selectedVaccineProof(lastVaccineItem.test_guid, uploadBtnFunction),
					onClick: () => selectedVaccineProof(lastVaccineItem.test_guid, 3), // should take to booster upload page to edit
					state: 0,
					text: '',
					type: 32
				}
			]
		})
	} else if (pendingProofs && pendingProofs.length > 0) {
		const lastItem = pendingProofs[0]
		compactHistoryListData.push({
			icon: 'clock',
			onClick: () => {
				let shotNumber = 1
				const proofList = vaccineStatus?.vaccine_proofs
				if (proofList[0].shots.length > 1) {
					shotNumber = 2
				}
				selectedVaccineProof(lastItem.test_guid, shotNumber)
				// selectedVaccineProof(lastVaccineItem.test_guid, shotNumber)
			},
			secondaryText: appText[COVID_TEXTS['covid19.medical.vaccine.secondary_text.pending']] || 'Pending',
			text: appText[COVID_TEXTS['covid19.medical.vaccine.vaccine_title']] || `Covid Vaccine`,
			// text: `Vaccine`,
			className: 'vaccine-list-item grey',
			singleLine: true,
			actions: [
				{
					icon: 'chevron-right',
					onClick: () => {
						let shotNumber = 1
						const proofList = vaccineStatus?.vaccine_proofs
						if (proofList[0].shots.length > 1) {
							shotNumber = 2
						}
						selectedVaccineProof(lastItem.test_guid, shotNumber)
						// selectedVaccineProof(lastVaccineItem.test_guid, shotNumber)
					},
					state: 0,
					text: '',
					type: 32
				}
			]
		})
	} else if (lastVaccineItem && lastVaccineItem.result === 'VACCINE_PROOF') {
		// const lastItem = vaccineProofs[0]
		compactHistoryListData.push({
			icon: 'shield-check',
			onClick: () => downloadClicked(lastVaccineItem.test_guid, false, true /* vaccine */),
			secondaryText: lastVaccineItem.test_date ? formatDate(lastVaccineItem.test_date) : null,
			// text: `Covid Vaccine`,
			text: appText[COVID_TEXTS['covid19.medical.vaccine.vaccine_title']] || `Covid Vaccine`,
			className: 'vaccine-list-item green',
			singleLine: true,
			actions: [
				{
					icon: 'cloud-download-alt',
					onClick: () => downloadClicked(lastVaccineItem.test_guid, false, true /* vaccine */),
					state: 0,
					text: '',
					type: 32
				}
			]
		})
	} else if (hasExemptions) {
		// switch (exemptionItems[0].result)
		// console.log('exemptionItems[0]', exemptionItems[0])
		if ((exemptionItems[0].result) === 'EXEMPT_MORE_INFO') {
			compactHistoryListData.push({
				icon: 'question-circle',
				onClick: () => setActiveDrawer(ActiveDrawer.ReqExemption),
				secondaryText: appText[COVID_TEXTS['covid19.medical.vaccine.secondary_text.information']] || `Information`,
				// secondaryText: 'Need More Info',
				// text: `Covid Exempt`,
				text: appText[COVID_TEXTS['covid19.medical.vaccine.exemption_title']] || `Covid Exempt`,
				className: 'vaccine-list-item red',
				singleLine: true,
				actions: [
					{
						icon: 'chevron-right',
						onClick: () => setActiveDrawer(ActiveDrawer.ReqExemption),
						state: 0,
						text: '',
						type: 32
					}
				]
			})
		} else if ((exemptionItems[0].result) === 'EXEMPT_REJECTED') {
			compactHistoryListData.push({
				icon: 'ban',
				onClick: () => setActiveDrawer(ActiveDrawer.ReqExemption),
				secondaryText: appText[COVID_TEXTS['covid19.medical.vaccine.secondary_text.denied']] || `Denied`,
				text: appText[COVID_TEXTS['covid19.medical.vaccine.exemption_title']] || `Covid Exempt`,
				// text: `Covid Exempt`,
				className: 'vaccine-list-item red',
				singleLine: true,
				actions: [
					{
						icon: 'chevron-right',
						onClick: () => setActiveDrawer(ActiveDrawer.ReqExemption),
						state: 0,
						text: '',
						type: 32
					}
				]
			})

		} else if ((exemptionItems[0].result) === 'EXEMPT_PENDING') {
			compactHistoryListData.push({
				icon: 'clock',
				onClick: () => setActiveDrawer(ActiveDrawer.ReqExemption),
				secondaryText: appText[COVID_TEXTS['covid19.medical.vaccine.secondary_text.pending']] || 'Pending',
				text: appText[COVID_TEXTS['covid19.medical.vaccine.exemption_title']] || `Covid Exempt`,
				// text: `Covid Exempt`,
				className: 'vaccine-list-item grey',
				singleLine: true,
				actions: [
					{
						icon: 'chevron-right',
						onClick: () => setActiveDrawer(ActiveDrawer.ReqExemption),
						state: 0,
						text: '',
						type: 32
					}
				]
			})
		} else if ((exemptionItems[0].result) === 'EXEMPT_APPROVED') {
			console.log('exemptionItems[0]', exemptionItems[0])
			console.log('case: EXEMPT_APPROVED')
			compactHistoryListData.push({
				icon: 'chevron-circle-right',
				onClick: () => setActiveDrawer(ActiveDrawer.ReqExemption),
				secondaryText: exemptionItems[0].test_date ? formatDate(exemptionItems[0].test_date) : null,
				text: appText[COVID_TEXTS['covid19.medical.vaccine.exemption_title']] || `Covid Exempt`,
				// text: `Covid Exempt`,
				className: 'vaccine-list-item green',
				singleLine: true,
				actions: [
					{
						icon: 'chevron-right',
						onClick: () => setActiveDrawer(ActiveDrawer.ReqExemption),
						state: 0,
						text: '',
						type: 32
					}
				]
			})
		}
	} else if (lastVaccineItem && lastVaccineItem.result === 'VACCINE_PROOF_PARTIAL') {
		compactHistoryListData.push({
			icon: 'shield',
			onClick: () => downloadClicked(lastVaccineItem.test_guid, false, true /* vaccine */),
			secondaryText: lastVaccineItem.test_date ? formatDate(lastVaccineItem.test_date) : null,
			// tertiaryText: ' ',
			// quaternaryText: shot.received_on,
			// text: `Partial Vaccine`,
			text: appText[COVID_TEXTS['covid19.medical.vaccine.partial_vaccine_title']] || 'Partial Vaccine',
			className: 'vaccine-list-item green',
			singleLine: true,
			actions: [
				{
					icon: 'cloud-download-alt',
					onClick: () => downloadClicked(lastVaccineItem.test_guid, false, true /* vaccine */),
					state: 0,
					text: '',
					type: 32
				}
			]
		})
	} else if (lastVaccineItem && lastVaccineItem.result === 'VACCINE_PROOF_REJECTED') {
		// const lastItem = vaccineRejected[0]
		compactHistoryListData.push({
			icon: 'ban',
			onClick: () => selectedVaccineProof(lastVaccineItem.test_guid, uploadBtnFunction),
			secondaryText: appText[COVID_TEXTS['covid19.medical.vaccine.secondary_text.denied']] || `Denied`,
			text: appText[COVID_TEXTS['covid19.medical.vaccine.vaccine_title']] || `Covid Vaccine`,
			// text: `Covid Vaccine`,
			className: 'vaccine-list-item red',
			singleLine: true,
			actions: [
				{
					icon: 'chevron-right',
					onClick: () => selectedVaccineProof(lastVaccineItem.test_guid, uploadBtnFunction),
					state: 0,
					text: '',
					type: 32
				}
			]
		})
	} else if (lastVaccineItem && lastVaccineItem.result === 'VACCINE_BOOSTER_APPROVED') {
		// const lastItem = vaccineProofs[0]
		compactHistoryListData.push({
			icon: 'shield-check',
			onClick: () => downloadClicked(lastVaccineItem.test_guid, false, true /* vaccine */),
			secondaryText: lastVaccineItem.test_date ? formatDate(lastVaccineItem.test_date) : null,
			text: appText[COVID_TEXTS['covid19.medical.vaccine.booster_title']] || `COVID-19 Booster`,
			// text: `COVID-19 Booster`,
			className: 'vaccine-list-item green',
			singleLine: true,
			actions: [
				{
					icon: 'cloud-download-alt',
					onClick: () => downloadClicked(lastVaccineItem.test_guid, false, true /* vaccine */),
					state: 0,
					text: '',
					type: 32
				}
			]
		})
	} else if (lastVaccineItem && lastVaccineItem.result === 'VACCINE_MISSING') {
		compactHistoryListData.push({
			icon: 'calendar-day',
			onClick: () => setShowFullOrPartialModal(true), // should take to upload proof page
			secondaryText: 'Missing',
			text: appText[COVID_TEXTS['covid19.medical.vaccine.vaccine_title']] || `Covid Vaccine`,
			// text: `Covid Vaccine`,
			className: 'vaccine-list-item red',
			singleLine: true,
			actions: [
				{
					icon: 'chevron-right',
					onClick: () => setShowFullOrPartialModal(true), // should take to upload proof page
					state: 0,
					text: '',
					type: 32
				}
			]
		})
		// } else if (lastVaccineItem && lastVaccineItem.result === "VACCINE_PROOF_PENDING") {
	} else if (lastVaccineItem && lastVaccineItem.result === 'VACCINE_BOOSTER_MISSING') {
		compactHistoryListData.push({
			// icon: 'check-circle',
			icon: 'calendar-day',
			// onClick: () => setActiveDrawer(ActiveDrawer.VaccineBoosterEdit), // should take to booster upload page
			onClick: () => selectedVaccineProof(undefined, 3), // should take to booster upload page
			// secondaryText: 'Optional',
			// secondaryText: appText[COVID_TEXTS['covid19.medical.vaccine.secondary_text.optional']] || `Optional`,
			// secondaryText: `Due ${lastVaccineItem.test_date ? formatDate(lastVaccineItem.test_date) : null}`,
			secondaryText: `Due ${formatDate(vaccineStatus?.booster.due_date)}`,
			text: appText[COVID_TEXTS['covid19.medical.vaccine.booster_title']] || `COVID-19 Booster`,
			// text: `COVID-19 Booster`,
			className: 'vaccine-list-item grey',
			singleLine: true,
			actions: [
				{
					icon: 'chevron-right',
					onClick: () => selectedVaccineProof(undefined, 3), // should take to booster upload page
					state: 0,
					text: '',
					type: 32
				}
			]
		})
	}

	// Flu Shot
	if (fluShotProofs && fluShotProofs.length > 0) {
		const lastItem = fluShotProofs[0]
		compactHistoryListData.push({
			icon: 'shield-check',
			onClick: () => downloadClicked(lastItem.test_guid, true),
			secondaryText: lastItem.test_date ? formatDate(lastItem.test_date) : null,
			// tertiaryText: ' ',
			// quaternaryText: shot.received_on,
			text: appText[COVID_TEXTS['covid19.medical.vaccine.flu_shot_title']] || 'Flu Shot',
			// text: `Flu Shot`,
			className: 'vaccine-list-item green',
			singleLine: true,
			actions: [
				{
					icon: 'cloud-download-alt',
					onClick: () => downloadClicked(lastItem.test_guid, true),
					state: 0,
					text: '',
					type: 32
				}
			]
		})
	}
	if (fluShotMissing && fluShotMissing.length > 0) {
		// const lastItem = fluShotMissing[0]
		compactHistoryListData.push({
			icon: isStudent ? 'calendar-day' : 'check-circle',
			onClick: () => setActiveDrawer(ActiveDrawer.FlushotProofEdit), // should take to flushot upload proof page
			// secondaryText: isStudent? lastItem.test_date ? formatDate(lastItem.test_date) : null: null,
			secondaryText: /* isStudent? ' ':  */ 'Missing',
			// quaternaryText: shot.received_on,
			// text: 'Flu Shot',
			text: appText[COVID_TEXTS['covid19.medical.vaccine.flu_shot_title']] || 'Flu Shot',
			className: `vaccine-list-item ${isStudent ? 'red' : 'grey'}`,
			singleLine: true,
			actions: [
				{
					icon: 'chevron-right',
					onClick: () => setActiveDrawer(ActiveDrawer.FlushotProofEdit), // should take to flushot upload proof page
					state: 0,
					text: '',
					type: 32
				}
			]
		})
	}

	// push completed covid shots to list
	// if (vaccineStatus && vaccineStatus.status === 'full') {
	// 	if (vaccineStatus.vaccine_proofs && vaccineStatus.vaccine_proofs.length > 0) {
	// 		vaccineStatus.vaccine_proofs.forEach((proof: any) => {
	// 			if (proof.shots && proof.shots.length > 0) {
	// 				let dateOfLastShot = proof.shots[proof.shots.length - 1].submission_date
	// 				compactHistoryListData.push({
	// 					icon: 'check-circle',
	// 					onClick: () => {},
	// 					secondaryText: dateOfLastShot,
	// 					tertiaryText: ' ',
	// 					// quaternaryText: shot.received_on,
	// 					text: `Covid-19`,
	// 					className: 'vaccine-list-item green',
	// 					singleLine: true,
	// 					actions: [
	// 						{
	// 							icon: 'cloud-download-alt',
	// 							onClick: () => downloadClicked(proof.proof_guid),
	// 							state: 0,
	// 							text: '',
	// 							type: 32
	// 						}
	// 					]
	// 				})
	// 			}
	// 		})
	// 	}
	// } else if (vaccineStatus && vaccineStatus.status === 'exempt') {
	// 	compactHistoryListData.push({
	// 		icon: 'chevron-circle-right',
	// 		onClick: () => {},
	// 		secondaryText: ' ',
	// 		tertiaryText: ' ',
	// 		// quaternaryText: shot.received_on,
	// 		text: `Covid-19`,
	// 		className: 'vaccine-list-item red',
	// 		singleLine: true,
	// 		actions: [
	// 			{
	// 				icon: 'chevron-right',
	// 				onClick: () => {},
	// 				state: 0,
	// 				text: '',
	// 				type: 32
	// 			}
	// 		]
	// 	})
	// } else {
	// 	// vaccineStatus.status !== 'full'
	// 	compactHistoryListData.push({
	// 		icon: 'calendar-day',
	// 		onClick: () => {},
	// 		secondaryText: ' ',
	// 		tertiaryText: ' ',
	// 		// quaternaryText: shot.received_on,
	// 		text: `Covid-19`,
	// 		className: 'vaccine-list-item red',
	// 		singleLine: true,
	// 		actions: [
	// 			{
	// 				icon: 'chevron-right',
	// 				onClick: () => {},
	// 				state: 0,
	// 				text: '',
	// 				type: 32
	// 			}
	// 		]
	// 	})
	// }

	return (
		<div className="row justify-content-center">
			<div className="col-md-8" style={{ textAlign: 'center' }}>
				<p className="pl-3 text-left">Vaccines</p>
				<Container
					alignment="left"
					className=""
					padding={isDownloading ? '5' : null}
					contents={
						isDownloading ? (
							<span>
								<i
									// aria-hidden={!onClick}
									aria-label={`spinner`}
									className={`far-circle-notch fa-spin`}
									role={'img'}
								/>
								{` Please wait while downloading...`}
							</span>
						) : (
							<ActionList type="flush" /* selectable={false} */ items={compactHistoryListData} />
						)
					}
					// padding={5}
					raised={false}
				/>
				<Spacer />
				<Button
					type={ButtonType.TextNaked}
					text={`Show Full History`}
					// icon={'cloud-upload'}
					onClick={() => setActiveDrawer(ActiveDrawer.FullHistory)} // Exemption flow Drawer
					// onClick={() => setShowNoVaccineView(true)} // Temp. To be replaced by Exemption flow Drawer
				/>
			</div>
		</div>
	)
}

export default CompactHistory
