import { Action, action } from 'easy-peasy'
import { /* FetchArrayResponse,  */FetchPassStatusResponse, RelationshipInterface, TextItem } from '../../api/controller/DataController'
import { TypeInterface, VaccineCompanyInterface, VaccineProofInterface, VaccineReviewReasons, VaccineProofShot, /* VaccineReceived,  */Exemption, VaccineProofResponse } from '../../api/controller/VaccineController'
import { Profile } from '../../types'
import { /* CovidPassRequirement,  */PassStatusModel/* , RequirementIds, RequirementStatus */ } from '../../types/CovidStatusModel'
import { VaccineStatusModel } from '../../types/VaccineStatusModel'


export enum ActiveDrawer {
	None,
	VaccineAcknowledgement,
	VaccineAppointment,
	VaccineQuestions,
	VaccineProofList,
	VaccineProofEdit,
	FlushotProofEdit,
	VaccineBoosterEdit,
	VaccinePreScreeningQuestions,
	SuccessPopup,
	FamilyMemberAdded,
	VaccineType,
	VaccineManufacturer,
	VaccineShotNumber,
	PartialUpload,
	ReqExemption,
	FullHistory,
	ExemptMoreInfo,
}


export interface State {
	auth: object;
	isLoading: boolean;
	appText: any;
	statusModel: PassStatusModel | undefined;
	features: any;
	profile: Profile | undefined;
	profilePicture: string | undefined;
	stepperSelectedIndex: number;
	stepperItemCount: number;
	preScreeningStepperSelectedIndex: number;
	preScreeningStepperItemCount: number;
	activeDrawer: ActiveDrawer;
	tabState: number;
	vaccineStatus: VaccineStatusModel | undefined;
	relationships: RelationshipInterface[] | undefined;
	interests: TypeInterface[] | undefined;
	receivedTypes: TypeInterface[] | undefined;
	locations: TypeInterface[] | undefined;
	availabilities: TypeInterface[] | undefined;
	jobTypes: TypeInterface[] | undefined;
	comorbidities: TypeInterface[] | undefined;
	vaccineCompanies: VaccineCompanyInterface[] | undefined;
	vaccineTypes: TypeInterface[] | undefined;
	vaccineProofList: VaccineProofShot[] | undefined;
	currentVaccineProof: VaccineProofInterface | undefined;

	reason: VaccineReviewReasons[] | any;
	// reason: VaccineProofResponse | any;
	manufacturer: VaccineCompanyInterface | any;
	vaccineReceived: string | any;
	reloadVaccineData: boolean;
	reloadTestResults: boolean;
	mobile: boolean;
	isFluShotProofUploaded: boolean;
	isExemptionReqSubmitted: boolean;
	vaccineResultsList: any[] | undefined;
	fluShotResultsList: any[] | undefined;
	person: any | undefined;
	vaccineShots: any[] | undefined;
	currentPendingExemption: Exemption | undefined
	reqExemptBtnClicked: boolean
}

export interface Actions {
	setAuth: Action<this, object>
	setIsLoading: Action<this, boolean>
	setAppText: Action<this, any>
	setFeatures: Action<this, any>
	setStatusModel: Action<this, FetchPassStatusResponse>
	setProfile: Action<this, Profile>
	setProfilePicture: Action<this, string>
	setStepperIndex: Action<this, number>
	setStepperCount: Action<this, number>
	setPreScreeningStepperIndex: Action<this, number>
	setPreScreeningStepperCount: Action<this, number>
	setActiveDrawer: Action<this, ActiveDrawer>
	setTabState: Action<this, number>,
	setVaccineStatus: Action<this, VaccineStatusModel | undefined>,
	setRelationships: Action<this, RelationshipInterface[] | undefined>,
	setReceivedTypes: Action<this, TypeInterface[] | undefined>
	setInterests: Action<this, TypeInterface[] | undefined>
	setLocations: Action<this, TypeInterface[] | undefined>
	setAvailabilities: Action<this, TypeInterface[] | undefined>
	setJobTypes: Action<this, TypeInterface[] | undefined>
	setComorbidities: Action<this, TypeInterface[] | undefined>
	setVaccineCompanies: Action<this, VaccineCompanyInterface[] | undefined>
	setVaccineTypes: Action<this, TypeInterface[] | undefined>
	setVaccineProofList: Action<this, VaccineProofShot[] | undefined>
	setCurrentVaccineProof: Action<this, VaccineProofInterface | undefined>
	// setReasons: Action<this, VaccineProofResponse | undefined>
	setReasons: Action<this, VaccineReviewReasons[] | undefined>
	setManufacturer: Action<this, VaccineCompanyInterface | undefined>
	setVaccineReceived: Action<this, string | any>
	setReloadVaccineData: Action<this, boolean>
	setReloadTestResults: Action<this, boolean>
	setMobile: Action<this, boolean>
	setFluShotProofUploaded: Action<this, boolean>
	setIsExemptionReqSubmitted: Action<this, boolean>
	setVaccineResultsList: Action<this, any[] | undefined>
	setFluShotResultsList: Action<this, any[] | undefined>
	setPerson: Action<this, any | undefined>
	setVaccineShots: Action<this, any[] | undefined>
	setCurrentPendingExemption: Action<this, Exemption | undefined>
	setReqExemptBtnClicked: Action<this, boolean>
}

export interface Thunks {
}

export interface GlobalModel extends State, Actions, Thunks {}

export const globalModel: GlobalModel = {
	auth: {},
	isLoading: true,
	appText: undefined,
	statusModel: undefined,
	features: [],
	profile: undefined,
	profilePicture: undefined,
	stepperItemCount : 0,
	stepperSelectedIndex: 0,
	preScreeningStepperItemCount: 0,
	preScreeningStepperSelectedIndex: 0,
	relationships: [],
	activeDrawer: ActiveDrawer.None,
	tabState: 0,
	vaccineStatus: undefined,
	locations: [],
	receivedTypes: [],
	interests: [],
	availabilities: [],
	jobTypes: [],
	comorbidities: [],
	vaccineCompanies: [],
	vaccineTypes: [],
	vaccineProofList: [],
	currentVaccineProof: undefined,
	reason: undefined,
	manufacturer: undefined,
	vaccineReceived: '',
	reloadVaccineData: false,
	reloadTestResults: false,
	mobile: false,
	isFluShotProofUploaded: false,
	isExemptionReqSubmitted: false,

	vaccineResultsList: [],
	fluShotResultsList: [],
	person: undefined,
	vaccineShots: [],
	currentPendingExemption: undefined,
	reqExemptBtnClicked: false,


	// Actions
	setAuth: action((state, payload) => {
		state.auth = payload
	}),

	setTabState: action((state, payload) => {
		state.tabState = payload
	}),

	setIsLoading: action((state, payload) => {
		state.isLoading = payload
	}),

	setProfile: action((state, payload) => {
		state.profile = payload;
	}),

	setProfilePicture: action((state, payload) => {
		state.profilePicture = payload;
	}),

	setVaccineStatus: action((state, payload) => {
		state.vaccineStatus = payload;
	}),

	setStepperIndex: action((state, payload) => {
		state.stepperSelectedIndex = payload;
	}),

	setStepperCount: action((state, payload) => {
		state.stepperItemCount = payload;
	}),

	setPreScreeningStepperIndex: action((state, payload) => {
		state.preScreeningStepperSelectedIndex = payload;
	}),

	setPreScreeningStepperCount: action((state, payload) => {
		state.preScreeningStepperItemCount = payload;
	}),

	setRelationships: action((state, payload) => {
		state.relationships = payload;
	}),

	setAvailabilities: action((state, payload) => {
		state.availabilities = payload;
	}),

	setReceivedTypes: action((state, payload) => {
		state.receivedTypes = payload;
	}),

	setInterests: action((state, payload) => {
		state.interests = payload;
	}),

	setComorbidities: action((state,payload) => {
		state.comorbidities = payload;
	}),

	setVaccineCompanies: action((state, payload) => {
		state.vaccineCompanies = payload;
	}),

	setVaccineProofList: action((state, payload) => {
		state.vaccineProofList = payload;
	}),

	setCurrentVaccineProof: action((state, payload) => {
		state.currentVaccineProof = payload;
	}),

	setVaccineTypes: action((state, payload) => {
		state.vaccineTypes = payload;
	}),

	setLocations: action((state, payload) => {
		state.locations = payload;
	}),

	setJobTypes: action((state, payload) => {
		state.jobTypes = payload;
	}),

	setAppText: action((state, payload) => {

		if (!payload || !payload.items) {
			return;
		}

		let texts = payload.items.reduce((all: any, current: TextItem) => {
			return {
				...all,
				[current.id]: current.text
			}
		}, {})

		state.appText = texts
	}),

	setFeatures: action((state, payload) => {

		let permissions = payload.reduce((obj: any, curr: any) => {
			return { ...obj, [curr.id]: curr.value }
		}, {})

		state.features = permissions
	}),

	setStatusModel: action((state, payload) => {
		state.statusModel = payload.status_model;
	}),

	setActiveDrawer: action((state, payload) => {
		state.activeDrawer = payload;
	}),

	setReasons: action((state, payload) => {
		state.reason = payload;
	}),

	setManufacturer: action((state, payload) => {
		state.manufacturer = payload;
	}),

	setVaccineReceived: action((state, payload) => {
		state.vaccineReceived = payload;
	}),

	setReloadVaccineData: action((state, payload) => {
		state.reloadVaccineData = payload;
	}),

	setReloadTestResults: action((state, payload) => {
		state.reloadTestResults = payload;
	}),

	setMobile: action((state, payload) => {
		state.mobile = payload;
	}),

	setFluShotProofUploaded: action((state, payload) => {
		state.isFluShotProofUploaded = payload
	}),

	setIsExemptionReqSubmitted: action((state, payload) => {
		state.isExemptionReqSubmitted = payload
	}),

	setVaccineResultsList: action((state, payload) => {
		state.vaccineResultsList = payload
	}),

	setFluShotResultsList: action((state, payload) => {
		state.fluShotResultsList = payload
	}),

	setPerson: action((state, payload) => {
		state.person = payload
	}),

	setVaccineShots: action((state, payload) => {
		state.vaccineShots = payload
	}),

	setCurrentPendingExemption: action((state, payload) => {
		state.currentPendingExemption = payload
	}),

	setReqExemptBtnClicked: action((state, payload) => {
		state.reqExemptBtnClicked = payload
	})
}
