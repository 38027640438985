import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../store/hooks'
import { withRouter } from 'react-router'
import vaccineController, { VaccineCompanyResponse, VaccineTypeResponse } from '../../api/controller/VaccineController'
import { ActiveDrawer } from '../../store/models/global'
import VaccineManufacturer from './VaccineManufacturer'
// import VaccineShot from './VaccineShot'
import { ActionList, ActionListType } from '@mit/hui'

interface VaccineTypeProps {}

// const VaccineTypeComponent: FC<VaccineTypeProps> = ({}) => {
const VaccineTypeComponent: FC<VaccineTypeProps> = () => {
	const [isLoading, setIsLoading] = useState(false)
	const { activeDrawer } = useStoreState(store => store.globalModel)
	const { setVaccineCompanies, setVaccineTypes } = useStoreActions(store => store.globalModel)

	useEffect(() => {
		setIsLoading(true)

		vaccineController.fetchCompanies().then((response: VaccineCompanyResponse) => {
			if (response.data && response.data.length > 0) {
				setVaccineCompanies(response.data)
				setIsLoading(false)
			}
		})

		vaccineController.fetchTypes().then((response: VaccineTypeResponse) => {
			if (response.data && response.data.length > 0) {
				setVaccineTypes(response.data)
				setIsLoading(false)
			}
		})
	// }, [])
	}, [setVaccineCompanies, setVaccineTypes])

	return (
		<>
			{isLoading ? (
				<ActionList items={[]} isLoading compact type={ActionListType.Flush} />
			) : (
				<>
					{activeDrawer === ActiveDrawer.VaccineManufacturer && <VaccineManufacturer />}
					{/* {activeDrawer == ActiveDrawer.VaccineShotNumber && <VaccineShot />} */}
				</>
			)}
		</>
	)
}

const VaccineType = withRouter(VaccineTypeComponent)
export default VaccineType
