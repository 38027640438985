import React, {FC, useEffect, useState, useMemo} from 'react'
import {
    Text,
    TextType
} from '@mit/hui'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { toast } from "react-toastify";

interface Props {
    browsers?: SupportedBrowsers
}

interface SupportedBrowsers {
    Chrome: number
    Firefox: number
    IE: number
    Edge: number
    Opera: number
    Safari: number
}

export const detectMobile = (): boolean => {
    let ua = navigator.userAgent
    let Match = ua.match(/Mobile/i) || [];
    return Match[0] === 'Mobile'
}

const BrowserDetector: FC<Props> = () => {
    const [browser, setBrowser] = useState<any>();
    const supportedBrowsers: any =
			{
				Chrome: 87,
				Firefox: 87,
				IE: 10,
				Edge: 89,
				Opera: 75,
				Safari: 13
			}

    const detectBrowser = () => {
        let ua = navigator.userAgent
        let tem
        let M = ua.match(/(opera|chrome|safari|firefox(?=\/))\/?\s*(\d+)/i) || [];

        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return { name: "IE", version: tem[1] || "" };
        }

        if(M[1] === 'Chrome'){
            tem = ua.match(/\bOPR|Edge\/(\d+)/)
            console.log(tem)
            if(tem != null)	{return { name: 'Opera', version: tem[1] };}
        }

        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];

        if((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1,1,tem[1]); }

        return {
            name: M[0],
            version: M[1]
        };
    }

    const isSupported = useMemo(() => {
			if (browser && supportedBrowsers.hasOwnProperty(browser.name)) {
					if (parseInt(browser.version) < supportedBrowsers[browser.name]) {
							return true;
					}
			}
			return false;
	}, [browser, supportedBrowsers])

    const Msg = () => (
        <div className="update-toast d-flex" style={{flexDirection: 'column'}}>
            <Text content={'Outdated browser detected!'} type={TextType.Heading4} bold/>
            <Text content={'Some functionality will not work properly. Consider upgrading or switching to a different one'} type={TextType.Body}/>
            {/* <Button text={'Update'} onClick={() => console.log('SEND TO BROWSER SITE')}/> */}
        </div>
      )

    useEffect(() => {
        setBrowser(detectBrowser())
    }, [])

    useEffect(() => {
        if (isSupported) {
            toast.error(<Msg />, {
                position: "top-center",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }
    }, [isSupported])

    if (isSupported) {
        return (
            <ToastContainer
				position="top-right"
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
        )
    }

    return null

}
export default BrowserDetector;
